
function keyDownFunction(e: any) {
  if (e.keyCode === 38 || e.keyCode === 40 || e.code === "Space") {
    e.preventDefault();
  }
}

function getTokenData() {
  // @ts-ignore
  try {
    let localData = localStorage.getItem("sg_token");
    const token = localData ? JSON.parse(localStorage.getItem("sg_token") || "") : '';
    if (token !== null) {
      return token;

    } else {
      return null;
    }
  } catch (error) {
    alert("Please enable the cookies in your browser setting.")
  }
}

const Helpers = {
  keyDownFunction,
  getTokenData
}

export default Helpers;