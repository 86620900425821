import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA8Uoo34dIgJun0EUX3GZu9VEP_vlRoZOo",
  authDomain: "soft911-cfb5c.firebaseapp.com",
  projectId: "soft911-cfb5c",
  storageBucket: "soft911-cfb5c.appspot.com",
  messagingSenderId: "875161656136",
  appId: "1:875161656136:web:0532f835e5cb6ed1b3317d",
  measurementId: "G-P99CKSJD7B"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);