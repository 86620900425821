import { Navigate, Outlet, useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom";
import Footer from "../components/footer";
import { useEffect, useState } from "react";
import Helpers from "../services/helpers";

const Layout = () => {
    const [pageClass, setPageClass] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    let pageTitle = splitLocation[1];


    const clearCacheData = () => {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        //alert('Complete Cache Cleared')
      };

    useEffect(() => {  
        //clearCacheData();

        if (pageTitle === '') {
            setPageClass('loginBackground');
        }
        else if (pageTitle === 'register') {
            setPageClass('registerBackground');
        }
        else if (pageTitle === 'forgot-password') {
            setPageClass('forgotBackground');
        }
        else if (pageTitle === 'otp') {
            setPageClass('otpBackground');
        }
        else if (pageTitle === 'password') {
            setPageClass('passwordBackground');
        }     
   
        const Token = Helpers.getTokenData();         
        if (Token?.access_token) {
            navigate('/home', { state:{ name: 'Home' }, replace:true });  
        }
           
    }, [pageTitle])

    
    
    

    return (
        <>
            <section className={pageClass}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="loginForm text-center">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Layout;