import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from "../services/auth.service";
import Helpers from "../services/helpers";
import Loader from "../components/loader";
import { Modal } from "react-bootstrap";
import Success from "../pages/success";
import Failed from "../pages/failed";
import Pending from "../pages/pending";
import Processing from "../pages/processing";

function GatewaysDetails(props: any) {
    const location = useLocation();
    let fileInputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [isPageloading, setIsPageLoading] = useState(true);
    const [isWaiting, setIsWaiting] = useState(false);
    const [message, setMessage] = useState("");
    const [amount] = useState(location.state?.amount);
    const [bankId] = useState(location.state?.bankId);
    const [previewImage, setPreviewImage] = useState<string>("");
    const [isTxnStatus, setIsTxnStatus] = useState<any>();
    const [txnStatusRes, setTxnStatusRes] = useState<any>();
    const [txnData, setTxnData] = useState({ id: '', time: '' });
    const [imageFile, setImageFile] = useState<File>();
    const [waitingTime, setWaitingTime] = useState<number>(-1);
    const [maxWaitingTime, setMaxWaitingTime] = useState<number>(0);
    const [bankMessage, setBankMessage] = useState("");
    const [gatewayDetails, setGatewaysDetails] = useState<any>();
    const [isCopied, setIsCopied] = useState<any>({});
    const [isShow, invokeModal] = useState(false);
    const [isQrShow, setIsQrShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!location.state?.amount) {
            navigate("/wallet", { replace: true, state: "" });
        }

        if (!location.state?.bankId) {
            navigate("/gateways", { replace: true, state: { amount: amount } });
        }

        // eslint-disable-next-line
    }, [location.state]);

    useEffect(() => {
        setImageFile(undefined);
        setPreviewImage("");
        setMessage("");
        setIsPageLoading(true);
        setBankMessage("");
        setGatewaysDetails("");
        const formData = {
            gateway: bankId,
            amount: amount
        }
        AuthService.gatewayDetails(formData)
            .then(
                (response) => {

                    if (response.status === true) {
                        // console.log(response.payment_gateways)
                        setGatewaysDetails(response);
                        setIsQrShow(true);
                        // initModal();
                    } else {
                        if (response.code === 401) {
                            localStorage.removeItem("sg_token");
                            navigate('/', { state: { message: response?.msg } });
                        }
                        setBankMessage(response.msg);
                    }

                }
            ).catch((error) => {
                const message =
                    (error.response &&
                        error.response?.data?.msg);
                setBankMessage(message);

            }).finally(() => {
                setIsPageLoading(false);
            });
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (loading) {
            document.body.style.overflow = 'hidden'; // Disable body scroll
        } else {
            document.body.style.overflow = 'auto'; // Enable body scroll
        }
    }, [loading]);


    const initModal = () => {
        return invokeModal(!isShow);
    }

    const handleCancel = () => {
        invokeModal(!isShow);
        navigate(-1);
    }


    const handleSetImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files as FileList;
        if (selectedFile?.[0]) {
            setImageFile(selectedFile?.[0]);
            setPreviewImage(URL.createObjectURL(selectedFile?.[0]));
            setMessage('');
        }
    }

    const removeImage = () => {
        setImageFile(undefined);
        setPreviewImage("");

        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Clearing the value
        }
    };

    const handleTransactionSubmit = (e: any) => {
        e.preventDefault();
        setMessage('');
        setLoading(true);
        setIsWaiting(true);
        const token = Helpers.getTokenData();
        const form = document.getElementById('txn_form') as HTMLFormElement;
        const formData = new FormData(form);

        if ( !imageFile) {
            setMessage('Please upload payment proof screenshot');
            setLoading(false);
            return false;
        }

        formData.append('userId', token?.uid);
        formData.append('amount', amount);
        formData.append('p_gateway', gatewayDetails?.gateway);
        formData.append('gateway_name', gatewayDetails?.name);
        formData.append('admin_id', gatewayDetails?.subadmin);
        formData.append('qr_image', gatewayDetails?.qr_image ? gatewayDetails?.qr_image : ''); 
       
        AuthService.addMoney(formData).then(
            (response) => {
                if (response.status === true) {
                    setMessage(response.msg);
                    setIsTxnStatus(true);
                    setValues(response.id, response.time);
                    setMaxWaitingTime(response.time);

                } else {
                    setMessage(response.msg);
                }
            }
        ).catch((error) => {
            const message =
                (error.response &&
                    error.response?.data?.msg);
            setMessage(message);

            if (error.response?.data?.code === 401) {
                localStorage.removeItem("sg_token");
                navigate('/', { state: { message: message } });
            }
        }).finally(() => {
            setLoading(false);
        });

    };

    function setValues(id = '', time = '') {
        setTxnData({
            ...txnData,
            [`id`]: id,
            [`time`]: time
        });
    }
    useEffect(() => {
        if (txnData.id) {
            getAddMoneyStatus();
        }
        // eslint-disable-next-line
    }, [txnData]);

    async function getAddMoneyStatus() {

        await AuthService.addMoneyStatus(txnData.id).then(
            (response) => {

                if (response.status === false) {
                    setMessage(response.msg);
                    setIsWaiting(false)
                }
                setWaitingTime(response.mins);
                setTxnStatusRes(response);
            }).catch((error: any) => {
                const message =
                    (error.response &&
                        error.response?.data?.msg);
                setMessage(message);
            });
    }


    useEffect(() => {
        if (txnData.time !== '') {
            if (waitingTime <= Number(txnData.time) && txnStatusRes.reqStatus === 0) {
                const timeoutId = setTimeout(() => {
                    getAddMoneyStatus();
                }, 20000);

                return () => clearTimeout(timeoutId);
            } else {
                setIsWaiting(false);
            }

        }
        // eslint-disable-next-line
    }, [waitingTime]);


    const myFunction = async (input: any) => {
        const inputValue = document.getElementById(input) as HTMLInputElement;

        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(inputValue.value);
        } else {
            inputValue.select();
            inputValue.setSelectionRange(0, 99999);
            document.execCommand("copy");
        }
        setIsCopied({ [input]: 'copied' });
        setTimeout(() => {
            setIsCopied({});
        }, 5000)
    };

    return (

        <div className="container">
            {isPageloading && (<Loader />)}
            <div className="row px-2 ">
                {!isTxnStatus ?
                    (
                        <>

                            <div style={{height:'100vh'}} className="position-relative">

                                {gatewayDetails && isQrShow &&
                                    <>
                                        <div className="paymetModel">
                                            {gatewayDetails && (gatewayDetails?.gateway_type_p === 'Image') ?
                                                (
                                                    <div className="col-12">
                                                        <p className="header">
                                                            {gatewayDetails?.name}
                                                        </p>
                                                        <div className="screenShortImg text-center">
                                                            <div className="PhonePeqr">
                                                                <img src={gatewayDetails?.qr_image} alt="" style={{ width: '80%' }} />

                                                            </div>
                                                            {/* <h5>Scan To Pay </h5> */}
                                                            {gatewayDetails && gatewayDetails?.upper_line && <p dangerouslySetInnerHTML={{ __html: gatewayDetails?.upper_line }}></p>}
                                                            {gatewayDetails && gatewayDetails?.lower_line && <h6>Alert : <span dangerouslySetInnerHTML={{ __html: gatewayDetails?.lower_line }}></span></h6>}
                                                        </div>
                                                    </div>
                                                ) :
                                                (
                                                    gatewayDetails?.gateway_type_p === 'Text' && gatewayDetails?.slug === 'bank_acc') ?
                                                    (
                                                        <div className="col-12 mt-1">
                                                            <p className="header">
                                                                {gatewayDetails?.name}
                                                            </p>
                                                            <div className="addMoneyContainer screenShortImg mb-4">
                                                                <label className="castomLabel">Bank Name </label>
                                                                <div className="bankaccountInput">
                                                                    <div className="betingInputBox">
                                                                        <button onClick={(e) => myFunction('bank')} className="bankpaymentCopy">
                                                                            <input type="text" readOnly id="bank" value={gatewayDetails?.bank_details?.Bank} />
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" className={isCopied?.bank ? 'copeyCheck' : ''}>
                                                                                <path d="M4.48182 3.73194V1.92175C4.48182 1.58851 4.75197 1.31836 5.08521 1.31836H12.326C12.6592 1.31836 12.9294 1.58851 12.9294 1.92175V10.3693C12.9294 10.7025 12.6592 10.9727 12.326 10.9727H10.5158V12.7823C10.5158 13.1159 10.2443 13.3863 9.90827 13.3863H2.67577C2.34024 13.3863 2.06836 13.118 2.06836 12.7823L2.06993 4.33586C2.06999 4.00233 2.34148 3.73194 2.67744 3.73194H4.48182ZM3.27661 4.93873L3.27526 12.1795H9.30898V4.93873H3.27661ZM5.68861 3.73194H10.5158V9.76589H11.7226V2.52515H5.68861V3.73194Z" fill="white" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <label className="castomLabel">Account Holder Name</label>
                                                                <div className="bankaccountInput">
                                                                    <div className="betingInputBox">
                                                                        <button onClick={(e) => myFunction('name')} className="bankpaymentCopy">
                                                                            <input type="text" readOnly id="name" value={gatewayDetails?.bank_details?.Name} />
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" className={isCopied?.name ? 'copeyCheck' : ''}>
                                                                                <path d="M4.48182 3.73194V1.92175C4.48182 1.58851 4.75197 1.31836 5.08521 1.31836H12.326C12.6592 1.31836 12.9294 1.58851 12.9294 1.92175V10.3693C12.9294 10.7025 12.6592 10.9727 12.326 10.9727H10.5158V12.7823C10.5158 13.1159 10.2443 13.3863 9.90827 13.3863H2.67577C2.34024 13.3863 2.06836 13.118 2.06836 12.7823L2.06993 4.33586C2.06999 4.00233 2.34148 3.73194 2.67744 3.73194H4.48182ZM3.27661 4.93873L3.27526 12.1795H9.30898V4.93873H3.27661ZM5.68861 3.73194H10.5158V9.76589H11.7226V2.52515H5.68861V3.73194Z" fill="white" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <label className="castomLabel">Account Number</label>
                                                                <div className="bankaccountInput">
                                                                    <div className="betingInputBox">
                                                                        <button onClick={(e) => myFunction('acc')} className="bankpaymentCopy">
                                                                            <input type="text" readOnly id="acc" value={gatewayDetails?.bank_details?.Acc} />
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" className={isCopied?.acc ? 'copeyCheck' : ''}>
                                                                                <path d="M4.48182 3.73194V1.92175C4.48182 1.58851 4.75197 1.31836 5.08521 1.31836H12.326C12.6592 1.31836 12.9294 1.58851 12.9294 1.92175V10.3693C12.9294 10.7025 12.6592 10.9727 12.326 10.9727H10.5158V12.7823C10.5158 13.1159 10.2443 13.3863 9.90827 13.3863H2.67577C2.34024 13.3863 2.06836 13.118 2.06836 12.7823L2.06993 4.33586C2.06999 4.00233 2.34148 3.73194 2.67744 3.73194H4.48182ZM3.27661 4.93873L3.27526 12.1795H9.30898V4.93873H3.27661ZM5.68861 3.73194H10.5158V9.76589H11.7226V2.52515H5.68861V3.73194Z" fill="white" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <label className="castomLabel">IFSC Code</label>
                                                                <div className="bankaccountInput">
                                                                    <div className="betingInputBox">
                                                                        <button onClick={(e) => myFunction('ifsc')} className="bankpaymentCopy">
                                                                            <input type="text" readOnly id="ifsc" value={gatewayDetails?.bank_details?.Ifsc} />
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" className={isCopied?.ifsc ? 'copeyCheck' : ''}>
                                                                                <path d="M4.48182 3.73194V1.92175C4.48182 1.58851 4.75197 1.31836 5.08521 1.31836H12.326C12.6592 1.31836 12.9294 1.58851 12.9294 1.92175V10.3693C12.9294 10.7025 12.6592 10.9727 12.326 10.9727H10.5158V12.7823C10.5158 13.1159 10.2443 13.3863 9.90827 13.3863H2.67577C2.34024 13.3863 2.06836 13.118 2.06836 12.7823L2.06993 4.33586C2.06999 4.00233 2.34148 3.73194 2.67744 3.73194H4.48182ZM3.27661 4.93873L3.27526 12.1795H9.30898V4.93873H3.27661ZM5.68861 3.73194H10.5158V9.76589H11.7226V2.52515H5.68861V3.73194Z" fill="white" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {gatewayDetails && gatewayDetails?.upper_line && <p dangerouslySetInnerHTML={{ __html: gatewayDetails?.upper_line }}></p>}
                                                                {gatewayDetails && gatewayDetails?.lower_line && <h6>Alert : <span dangerouslySetInnerHTML={{ __html: gatewayDetails?.lower_line }}></span></h6>}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="col-12 mt-1">
                                                            <p className="header">
                                                                {gatewayDetails?.name} (Pay with any UPI app)
                                                            </p>
                                                            {gatewayDetails?.upi_name &&
                                                                <div className="addMoneyContainer screenShortImg">
                                                                    <label className="castomLabel">Name</label>
                                                                    <div className="bankaccountInput mb-1">
                                                                        <div className="betingInputBox">
                                                                            <button className="bankpaymentCopy">
                                                                                <input type="text" readOnly value={gatewayDetails?.upi_name || ""} />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="addMoneyContainer screenShortImg mb-4">
                                                                <label className="castomLabel">UPI Address</label>
                                                                <div className="bankaccountInput mb-1">
                                                                    <div className="betingInputBox">
                                                                        <button onClick={(e) => myFunction('upi')} className="bankpaymentCopy">
                                                                            <input type="text" readOnly id="upi" value={gatewayDetails?.details || ""} />
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" className={isCopied?.upi ? 'copeyCheck' : ''}>
                                                                                <path d="M4.48182 3.73194V1.92175C4.48182 1.58851 4.75197 1.31836 5.08521 1.31836H12.326C12.6592 1.31836 12.9294 1.58851 12.9294 1.92175V10.3693C12.9294 10.7025 12.6592 10.9727 12.326 10.9727H10.5158V12.7823C10.5158 13.1159 10.2443 13.3863 9.90827 13.3863H2.67577C2.34024 13.3863 2.06836 13.118 2.06836 12.7823L2.06993 4.33586C2.06999 4.00233 2.34148 3.73194 2.67744 3.73194H4.48182ZM3.27661 4.93873L3.27526 12.1795H9.30898V4.93873H3.27661ZM5.68861 3.73194H10.5158V9.76589H11.7226V2.52515H5.68861V3.73194Z" fill="white" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {gatewayDetails && gatewayDetails?.upper_line && <p dangerouslySetInnerHTML={{ __html: gatewayDetails?.upper_line }}></p>}
                                                                {gatewayDetails && gatewayDetails?.lower_line && <h6>Alert : <span dangerouslySetInnerHTML={{ __html: gatewayDetails?.lower_line }}></span></h6>}
                                                            </div>
                                                        </div>
                                                    )
                                            }
                                            <div className={`col-12 ${!gatewayDetails ? 'disabled' : ''}`} >
                                                <div className="addMoneyContainer">
                                                    <form id="txn_form" onSubmit={handleTransactionSubmit}>                                                        
                                                        <div className="uplodScreenShort">
                                                            <div className="uplodContent">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                                                                    <path d="M8.23529 11.875L11.7647 7.125L15.2941 12.4688V10.6875H20V2.375C20 1.06519 18.9447 0 17.6471 0H2.35294C1.05529 0 0 1.06519 0 2.375V16.625C0 17.9348 1.05529 19 2.35294 19H11.7647V14.25H3.52941L7.05882 9.5L8.23529 11.875Z" fill="white" />
                                                                    <path d="M20.25 13H17.75V16.75H14V19.25H17.75V23H20.25V19.25H24V16.75H20.25V13Z" fill="white" />
                                                                </svg>
                                                                <p>Upload Payment Proof*</p>
                                                            </div>
                                                            <div className="upload-btn-wrapper">
                                                                <input type="file" id="transaction_image" name="proof" accept="image/*" ref={fileInputRef} onChange={e => handleSetImage(e)} />
                                                                {previewImage && (
                                                                    <>
                                                                        <div className="uplogImageqr">
                                                                            <img className="preview" src={previewImage} alt="" width='85px' height="73px" />
                                                                            <button onClick={removeImage}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" fill="none">
                                                                                    <path d="M15.6203 5.1817L5.83838 14.09M5.83838 5.1817L15.6203 14.09" stroke="#DEDEDE" strokeWidth="1.63033" strokeLinecap="round" strokeLinejoin="round" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {message && (
                                                            <div className='d-block text-danger mb-2 loginErrors text-center' role="alert">
                                                                {message}
                                                            </div>
                                                        )}
                                                        <div className="addPayment">
                                                            <button type="submit" disabled={loading} >
                                                                {loading && (
                                                                    <span className="loader-spin"></span>
                                                                )}
                                                                <span>Deposit</span>
                                                            </button>

                                                        </div>
                                                    </form>
                                                    <div className="cancelpaytm">
                                                        <button onClick={initModal}>Cancel this transaction</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Modal
                                            show={isShow}
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                            className=""
                                            backdrop="static"
                                            onHide={initModal}
                                        >
                                            <Modal.Body>
                                                <div className="col-12">
                                                    <div className="text-center areYouSure">
                                                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_2585_867)">
                                                                <path d="M15.5 0C6.94009 0 0 6.94009 0 15.5C0 24.0609 6.94009 31 15.5 31C24.0609 31 31 24.0619 31 15.5C31 6.94009 24.0608 0 15.5 0ZM15.5 28.0937C8.54441 28.0937 2.9063 22.4556 2.9063 15.5C2.9063 8.54441 8.54441 2.9063 15.5 2.9063C22.4556 2.9063 28.0937 8.54441 28.0937 15.5C28.0937 22.4556 22.4556 28.0937 15.5 28.0937ZM15.501 6.79769C14.3908 6.79769 13.5508 7.37704 13.5508 8.31283V16.8959C13.5508 17.8327 14.3907 18.4101 15.501 18.4101C16.584 18.4101 17.4511 17.8085 17.4511 16.8959V8.31283C17.451 7.39926 16.584 6.79769 15.501 6.79769ZM15.501 20.3438C14.4344 20.3438 13.5663 21.2118 13.5663 22.2793C13.5663 23.3449 14.4344 24.2129 15.501 24.2129C16.5676 24.2129 17.4346 23.3449 17.4346 22.2793C17.4345 21.2117 16.5676 20.3438 15.501 20.3438Z" fill="#EC4A4A" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_2585_867">
                                                                    <rect width="31" height="31" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        <p>Are you sure ? </p>
                                                        <button onClick={initModal} className="noCancel">No</button>
                                                        <button onClick={handleCancel} className="yesCancel">Yes, Cancel</button>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal >
                                    </>
                                }

                                {!gatewayDetails && !isPageloading &&
                                    (
                                        bankMessage && (
                                            <div className="getwayAlert">
                                                <div className="alert-danger-custom mt-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                                        <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                                                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                                                    </svg>
                                                    <p>
                                                        {bankMessage}
                                                    </p>

                                                </div>
                                                <div className="text-center" style={{margin:'0 auto',display:'flex',justifyContent:'center'}}>
                                                    <span className="d-block text-white" onClick={() => navigate(-1)}>Back</span>
                                                </div>

                                            </div>
                                        )
                                    )}
                            </div>
                        </>
                    )
                    :
                    (

                        <>
                            {isWaiting && isTxnStatus ?
                                (
                                    <>
                                        <div className="verify_Payment">
                                            <div className="innerDivLoder">
                                                <div className="sk-circle">
                                                    <div className="sk-circle1 sk-child"></div>
                                                    <div className="sk-circle2 sk-child"></div>
                                                    <div className="sk-circle3 sk-child"></div>
                                                    <div className="sk-circle4 sk-child"></div>
                                                    <div className="sk-circle5 sk-child"></div>
                                                    <div className="sk-circle6 sk-child"></div>
                                                    <div className="sk-circle7 sk-child"></div>
                                                    <div className="sk-circle8 sk-child"></div>
                                                    <div className="sk-circle9 sk-child"></div>
                                                    <div className="sk-circle10 sk-child"></div>
                                                    <div className="sk-circle11 sk-child"></div>
                                                    <div className="sk-circle12 sk-child"></div>
                                                </div>
                                                <h1>Processing!</h1>
                                                <h3>Payment initiated, This might take few seconds. So, Please wait for moment!</h3>
                                                {/* <p>We're happy to inform you that your payment is now in processing. It may take a little while to complete</p> */}
                                            </div>
                                        </div>
                                    </>
                                ) :
                                (
                                    <>
                                        {txnStatusRes?.reqStatus === 0 && (waitingTime > maxWaitingTime) && <Pending {...props} txnStatusRes={txnStatusRes} />}
                                        {txnStatusRes?.reqStatus === 1 && <Success {...props} txnStatusRes={txnStatusRes} />}
                                        {txnStatusRes?.reqStatus === 2 && <Failed {...props} txnStatusRes={txnStatusRes} />}
                                        {txnStatusRes?.reqStatus === 3 && <Processing {...props} txnStatusRes={txnStatusRes} />}
                                    </>
                                )
                            }
                        </>

                    )
                }
            </div>
        </div>
    )
}
export default GatewaysDetails;