import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Deposit from '../components/deposit';
import Withdrawal from '../components/withdrawal';
import AllPassbook from '../components/all-passbook';
import GamePlay from '../components/gameplay';
import AuthService from "../services/auth.service";
import Loader from '../components/loader';
interface statementList {
  title: string;
  amount: string;
  date: string;
  f_status: string;
}


function Passbooks(props: any) {
  const [isPageloading, setIsPageLoading] = useState(true);
  const navigate = useNavigate();
  const [statementList, setStatementsList] = useState<statementList | any>([]);
  const [errors, setErrors] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isDataEmpty, setIsDataEmpty] = useState<boolean>(false);
  const [height] = useState(window.innerHeight);

  useEffect(() => {
    getStatements();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loading || isPageloading) {
      document.body.style.overflow = 'hidden'; // Disable body scroll
    } else {
      document.body.style.overflow = 'auto'; // Enable body scroll
    }
    // eslint-disable-next-line
  }, [loading]);

  const getStatements = () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      let lastId = statementList.slice(-1)[0]?.id;
      AuthService.statements(lastId)
        .then(
          (response) => {

            if (response.status === true) {
              if ((response.statements).length === 0) {
                setIsDataEmpty(true);
              }
              setStatementsList([...statementList, ...response.statements]);

            } else {
              if (response.code === 401) {
                localStorage.removeItem("sg_token");
                navigate('/', { state: { message: response?.msg } });
              }
            }
          }
        ).catch((error) => {
          const message =
            (error.response &&
              error.response?.data?.msg);
          setErrors(message);
          if (error.response?.data?.code === 401) {
            localStorage.removeItem("sg_token");
            navigate('/', { state: { message: message } });
          }
          setIsPageLoading(false);
        }).finally(() => {
          setLoading(false);
          setIsPageLoading(false);
        });
    }, 1000);
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop === clientHeight && !loading) { //scrollTop>0 && 
      if (!isDataEmpty) {
        getStatements();
      }
    }
  };

  return (
    <>
      {isPageloading && (<Loader />)}
      <section className="gameMain" >
        <div className="container" >
          <div className="row">
            {/* <div className="col-12">
              <h1 className="headHeding text-capitalize">Passbook</h1>
            </div> */}
            <div className="col-12">
              <ul className="nav nav-pills justify-content-between passbookButton" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-All" type="button" role="tab" aria-controls="pills-All" aria-selected="true">All</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Deposit-tab" data-bs-toggle="pill" data-bs-target="#pills-Deposit" type="button" role="tab" aria-controls="pills-Deposit" aria-selected="false">Deposit</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Withdrawal-tab" data-bs-toggle="pill" data-bs-target="#pills-Withdrawal" type="button" role="tab" aria-controls="pills-Withdrawal" aria-selected="false">Withdrawal</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-GamePlay-tab" data-bs-toggle="pill" data-bs-target="#pills-GamePlay" type="button" role="tab" aria-controls="pills-GamePlay" aria-selected="false">Game Play</button>
                </li>
              </ul>
            </div>
            <div className="col-12">
              <div className="tab-content" id="pills-tabContent" onScroll={handleScroll} style={{ height: (height - 190) + 'px', overflowY: 'scroll' }} >
                <div className="tab-pane fade show active" id="pills-All" role="tabpanel" aria-labelledby="pills-home-tab">
                  {!isPageloading && <AllPassbook statementList={statementList} load={loading} />}
                </div>
                <div className="tab-pane fade" id="pills-Deposit" role="tabpanel" aria-labelledby="pills-Deposit-tab">
                  {!isPageloading && <Deposit statementList={statementList} load={loading} />}

                </div>
                <div className="tab-pane fade" id="pills-Withdrawal" role="tabpanel" aria-labelledby="pills-Withdrawal-tab">
                  {!isPageloading && <Withdrawal statementList={statementList} load={loading} />}

                </div>
                <div className="tab-pane fade" id="pills-GamePlay" role="tabpanel" aria-labelledby="pills-GamePlay-tab">
                  {!isPageloading && <GamePlay statementList={statementList} load={loading} />}

                </div>
                {errors &&
                  <div className="col-12 alert-danger-custom">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-emoji-frown" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.5 3.5 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.5 4.5 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                    </svg>
                    <p>{errors}</p>
                  </div>
                }
                {(!isPageloading && loading) &&
                  <div className="spinner-box" style={{ height: '60px' }}>
                    <div className="pulse-container">
                      <div className="pulse-bubble pulse-bubble-1"></div>
                      <div className="pulse-bubble pulse-bubble-2"></div>
                      <div className="pulse-bubble pulse-bubble-3"></div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Passbooks;