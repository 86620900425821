import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Helpers from "../services/helpers";
import AuthService from "../services/auth.service";
import $ from 'jquery';
import Loader from "../components/loader";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Wallet(props: any) {
    const [loading, setLoading] = useState(false);
    const [isPageloading, setIsPageLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [amount, setAmount] = useState("");
    const [videoLink, setVideoLink] = useState("");
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAmountAdd = (event: any, param: any) => {
        event.preventDefault();
        setMessage("");
        setAmount(param);
    };

    const onChangeAmount = (e: any) => {
        setMessage("");
        if (!isNaN(Number(e.target.value))) {
            setAmount(e.target.value);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        $(".walletInput.amount").removeClass('error-class');
        setMessage('');
        setLoading(true);
        if (Number(amount) < 500) {
            setMessage(amount === '' ? 'Please enter amount' : 'Minimum deposit amount INR 500');
            setLoading(false);
            $(".walletInput.amount").addClass('error-class');
            return false;
        }
        navigate('/gateways', { state: { amount: amount } });
        setLoading(false);
    };


    useEffect(() => {
        AuthService.getVideoLink()
            .then(
                (response) => {
                    // console.log(response.profile);
                    if (response.status === true) {
                        setVideoLink(response.video_link);
                    } else {
                        if (response.code === 401) {
                            localStorage.removeItem("sg_token");
                            navigate('/', { state: { message: response?.msg } });
                        }
                    }

                }
            ).catch((error) => {
                const message =
                    (error.response &&
                        error.response?.data?.msg);
                if (error.response?.data?.code === 401) {
                    localStorage.removeItem("sg_token");
                    navigate('/', { state: { message: message } });
                }
            }).finally(() => {
                setIsPageLoading(false);
            });

        // eslint-disable-next-line
    }, []);


    return (
        <div className="container" style={{ marginBottom: '80px' }}>
            {isPageloading && (<Loader />)}
            <div className="row px-2">
                {/* <div className="col-12">
                <h1 className="headHeding text-capitalize">Add Money</h1>
                </div> */}
                <div className="addMoneyContainer mt-2" style={{ paddingBottom: '1rem' }}>
                    <form onSubmit={handleSubmit}>
                        <div className="walletInput">
                            <div className="betingInputBox">
                                <input type="number" id="wallet_amount" name="wallet_amount" placeholder="Enter Amount" maxLength={10} value={amount} onChange={(e) => onChangeAmount(e)} onKeyDown={(e) => Helpers.keyDownFunction(e)} autoComplete="off" />
                            </div>
                        </div>
                        <div className="walletButton">
                            <button onClick={event => handleAmountAdd(event, 500)}>&#8377; 500</button>
                            <button onClick={event => handleAmountAdd(event, 1000)}>&#8377; 1000</button>
                            <button onClick={event => handleAmountAdd(event, 2000)}>&#8377; 2000</button>
                            <button onClick={event => handleAmountAdd(event, 3000)}>&#8377; 3000</button>
                        </div>
                        <div className="walletButton" style={{paddingTop:0}}>
                        <button onClick={event => handleAmountAdd(event, 5000)}>&#8377; 5000</button>
                            <button onClick={event => handleAmountAdd(event, 10000)}>&#8377; 10000</button>
                            <button onClick={event => handleAmountAdd(event, 20000)}>&#8377; 20000</button>
                            <button onClick={event => handleAmountAdd(event, 50000)}>&#8377; 50000</button>
                        </div>
                       
                        <div className="addPayment amountScreen pb-0">
                            {message && (
                                <div className='d-block text-danger mb-3 loginErrors' role="alert">
                                    {message}
                                </div>
                            )}
                            <button type="submit" className="w-100 d-block" disabled={loading} style={{ width: '100%' }}>
                                {loading && (
                                    <span className="loader-spin"></span>
                                )}
                                <span>Add Money</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className='withdrawalBtn'>
                        <Link to="/withdrawal" >
                            Withdrawal
                        </Link>
                    </div>
                </div>
                {videoLink &&
                    <div className="col-12 mt-3">
                        <div className="help_Videos">
                            <iframe
                                className="videoFram"
                                src={videoLink}
                                title="YouTube video player"
                                frameBorder="1"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen>
                            </iframe>
                            <Button variant="primary" onClick={handleShow}>Play</Button>
                        </div>
                    </div>
                }

                <Modal show={show} onHide={handleClose} centered className="videoModel">
                    <Modal.Body>
                        <button onClick={handleClose}><p>x</p></button>
                        <div className="help_Videos">
                            <iframe
                                className="videoFram"
                                src={videoLink + '?autoplay=1'}
                                title="YouTube video player"
                                frameBorder="1"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen>
                            </iframe>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>

    )
}
export default Wallet;