import $ from 'jquery';
// let jsum: number = 0;

const add = function (arr: number[]): number {
    return arr.reduce((a, b) => a + b, 0);
};

$(document).on('paste blur keyup', '.jantri_Input', function (event) {
    const numbers: number[] = [];
    $(".jantri_Input").each(function () {
        numbers.push(parseInt($(this).val() as string, 10));
    });
    const filtered: number[] = numbers.filter(Boolean);
    const jsum: number = add(filtered);
    $('.t_amount_jantri').text('Rs. ' + jsum);
});

function choose_numbers(): any {

    const betnumbers: string = $('#number').val() as string;
    const amount: string = $('#amount').val() as string;
    const numbers: RegExp = /^[0-9]+$/;

    if ($("#withPalti").is(":checked")) {
        if (betnumbers === '' && amount === '') {
            $('#withPalti').parents('label').addClass('activePalti');
            $('#withPalti').parents('.betingInputChip').addClass('error-class');
            $('#amount').parents('.betingInput').addClass('error-class');

        } else if (betnumbers !== '' || amount !== '') {
            $('#withPalti').parents('label').addClass('activePalti');
            $('#withPalti').parents('.betingInputChip').removeClass('error-class');
            $('#amount').parents('.betingInput').removeClass('error-class');

        } else {
            $('#withPalti').parents('.betingInputChip').removeClass('error-class');
            $('#amount').parents('.betingInput').removeClass('error-class');
            $('#withPalti').parents('label').removeClass('activePalti');

        }
    } else {

        $('#withPalti').parents('label').removeClass('activePalti');
        $('#withPalti').parents('.betingInputChip').removeClass('error-class');
        $('#amount').parents('.betingInput').removeClass('error-class');
    }


    if (!betnumbers.match(numbers)) {
        $('#number').parents('.betingInput').focus().css('border', '0.5px solid green');
        return false;
    }
    let betnumbersModified: string = betnumbers.replace(/\s/g, '');

    if ($('#withPalti').is(":checked")) {
        if (betnumbersModified.length % 2 !== 0) {
            betnumbersModified = betnumbersModified.slice(0, -1);
        }
        betnumbersModified += betnumbersModified.split("").reverse().join("");
    }


    const Array: string[] = betnumbersModified.match(/.{1,2}/g) || [];
    let result: string = '';
    const newArray: string[] = [];
    let n: number = 1;
    if (betnumbersModified.length > 1) {
        Array.forEach(function (item: string) {
            const numOccurences: number = Array.filter(elem => elem === item).length;
            const ulength: number = item.length;

            if (ulength === 2) {
                if (newArray.indexOf(item) === -1) {
                    result += `<p class="d-flex justify-content-around betingTabel main-c" id="main-c-${n}"><span>${item}</span><span>=</span><input type="text" class="hide-c" name="main[${item}]" value="${Math.abs(Number(amount)) * numOccurences}" readonly /><button  id="main${item}" class="btn-close"><img src="crossIcon.png" alt=""></button></p>`;
                }
                newArray.push(item);
                n++;
            }
        });
        $('.main-c').remove();
        $('.Main_List').show();
        $('.Main_List').append(result);
    } else {
        $('.main-c').remove();
    }
    /****share icon hide *****/
    $(".leftArrow").removeClass("d-none");
    $(".shareBtnIcon").removeClass("openClass");
    $(".rightArrow").addClass("d-none");
    $(".shareBtnIcon").addClass("shareBtn");
    /****share icon hide *****/
    return true;
}


function choose_harup(): void {
    // $('#harup_number').parents('.betingInput').removeClass('error-class');
    // $('#harup_amount').parents('.betingInput').removeClass('error-class');
    const str: string = (document.getElementById('harup_number') as HTMLInputElement).value;
    const amount: string = (document.getElementById('harup_amount') as HTMLInputElement).value;
    const numbers: RegExp = /^[0-9]+$/;
    let h: number = 0;
    $('.form-check-input.harupType:checked').each(function () {
        h = 1;
    });

    if (!str.match(numbers) && !amount.match(numbers)) {

        if ((h === 0 && str.length === 0)) {
            $('#harup_number').parents('.betingInput').removeClass('error-class');
            $('#harup_amount').parents('.betingInput').removeClass('error-class');
        } else {
            $('#harup_number').parents('.betingInput').addClass('error-class');
            $('#harup_amount').parents('.betingInput').addClass('error-class');

        }
    } else {
        $('#harup_number').parents('.betingInput').removeClass('error-class');
        $('#harup_amount').parents('.betingInput').removeClass('error-class');
    }

    let harupStr: string = '';
    let s_amount: number = 0;

    $('.form-check-input.harupType:checked').each((index, element) => {
        harupStr += $(element).val();
    });

    if (harupStr === '') {
        $('.error-harup-side').show();
        $('.error-harup-side').html('Please Select Harup Side.');
    }

    if (str.length === 0) {
        $('.error-harup-side').hide();
        $('.error-harup-side').html('');
    }

    if (harupStr.length > 0 && str.length) {
        $('.error-harup-side').html('');
        const Array: string[] = str.match(/.{1,1}/g) || [];
        let result: string = '';
        const newArray: string[] = [];
        let n: number = 1;
        Array.forEach(function (item: string) {
            const numOccurences: number = Array.filter(elem => elem === item).length;
            if (item.length === 1) {
                if (newArray.indexOf(item) === -1) {
                    switch (harupStr) {
                        case 'A':
                            result += `<p class="d-flex betingTabel harup-c" id="harup-c-${n}"><span>${item} ${harupStr}</span><span>=</span><input class="hide-c" name="andar[${item}]" value="${Math.abs(Number(amount)) * numOccurences}" readonly /><button id="harup${item}" class="btn-close"><img src="crossIcon.png" alt=""></button></p>`;
                            break;
                        case 'B':
                            result += `<p class="d-flex betingTabel harup-c" id="harup-c-${n}"><span>${item} ${harupStr}</span><span>=</span><input class="hide-c" name="bahar[${item}]" value="${Math.abs(Number(amount)) * numOccurences}" readonly /><button id="harup${item}"  class="btn-close"><img src="crossIcon.png" alt=""></button></p>`;
                            break;
                        case 'AB':
                            s_amount = Math.abs(Number(amount)) * 2;
                            result += `<p class="d-flex betingTabel harup-c" id="harup-c-${n}"><span>${item} ${harupStr}</span><span>=</span><input class="hide-c" name="andar-bahar[${item}]" value="${Math.abs(s_amount) * numOccurences}" readonly /><button id="harup${item}"  class="btn-close"><img src="crossIcon.png" alt=""></button></p>`;
                            break;
                        default:
                    }
                }
                newArray.push(item);
                n++;
            }
        });
        $('.Main_List').show();
        $('.harup-c').remove();
        $('.Main_List').append(result);
    } else {
        $('.harup-c').remove();
    }

    let sum: number = 0;
    $(".hide-c").each((index, element) => {
        const value: string = $(element).val() as string;
        if (value !== "") {
            sum += Number(value);
        }
    });

    $('.t_amount').text('Rs. ' + sum);
}


$(document).on('click', '.form-check-input.harupType', function () {
    choose_harup();
});

$(document).on('keyup', '#number,#amount', function () {
    choose_numbers();
});

$(document).on('keyup', '#harup_number,#harup_amount', function () {
    choose_harup();
});


$(document).on('click', '.checkbox-round#withPalti', function () {
    choose_numbers();
    $('#number').keyup();

});

$('#number').blur(function () {
    if (!$(this).val()) {
        $('.Main_List').hide();
    }
});

$(document).on('keyup', '#number,#amount,#harup_number,#harup_amount', function () {
    let sum: number = 0;
    $(".hide-c").each(function () {
        if ($(this).val() !== "") {
            sum += Number($(this).val());
        }
    });
    $('.t_amount').text('Rs. ' + sum);
});


$(document).on('click', '.btn-close', function () {
    const string: string = $(this).attr('id') || '';
    const type: string = string.replace(/[0-9]/g, '');
    let Array: string[] = [];
    let number: string | null = null;
    let str: string = '';
    let input: string = '';
    switch (type) {
        case 'main':
            number = (string.match(/\d+/)?.[0]) || null;
            str = ($('#number').val() || '') as string;
            str = str.replace(/\s/g, '');
            if (str.length > 2) {
                Array = str.match(/.{1,2}/g) || [];
            }
            input = '#number';
            break;
        case 'harup':
            number = (string.match(/\d+/)?.[0]) || null;
            str = ($('#harup_number').val() || '') as string;
            str = str.replace(/\s/g, '');
            Array = str.split('');
            input = '#harup_number';
            break;
        case 'cross':
            number = (string.match(/\d+/)?.[0]) || null;
            str = ($('#cross_number').val() || '') as string;
            str = str.replace(/\s/g, '');
            Array = str.split('');
            input = '#cross_number';
            break;
        default:
            break;
    }
    let dsum: number = 0;
    if (Array.length > 0 && number !== null) {
        Array = Array.filter(item => item !== number);
    }

    const newVal: string = Array.join("");
    $(input).val(newVal);
    $(this).closest('.d-flex').remove();
    if (type === 'cross') {
        let crSum: number = 0;
        $(".cross_list :input.hide-c").each(function () {
            if ($(this).val() !== "") {
                crSum += Number($(this).val());
            }
        });
        $('.t_cross_amount').text('Rs. ' + crSum);
    } else {
        $(".hide-c").each(function () {
            if ($(this).val() !== "") {
                dsum += Number($(this).val());
            }
        });
        $('.t_amount').text('Rs. ' + dsum);
    }
});



$(document).on('paste keyup', 'input[type="number"].numClass', function (event) {
    const element: JQuery<HTMLElement> = $(event.target);
    setTimeout(function () {
        let text: string | number = $(element).val() as string;
        if (/\D/g.test(text)) text = text.replace(/\D/g, '');
        let finalText: string | number = text;
        if (element[0].id === 'harup_number' || element[0].id === 'cross_number') {
            finalText = removeDuplicate(finalText);
        }
        if (element[0].id === 'cross_number') {
            if ((finalText as string).length > 1) {
                const crossAmount: string = $('.cross_amount').val() as string;
                crossNumbers(finalText, crossAmount);
            }
            if ((finalText as string).length <= 1) {
                $('.cross_list').html('');
                $('.t_cross_amount').text('Rs. 0');
            }
        }
        $('#' + element[0].id).val(finalText);
    }, 100);
    crossValidation();
});


$(document).on('paste keyup', 'input[type="number"].cross_amount', function (event) {
    const finalText: string = $('#cross_number').val() as string;
    if (finalText.length > 1) {
        const crossAmount: string = $('.cross_amount').val() as string;
        crossNumbers(finalText, crossAmount);
    }
    if ((finalText as string).length <= 1) {
        $('.cross_list').html('');
        $('.t_cross_amount').text('Rs. 0');
    }
    crossValidation();
});



function removeDuplicate(string: string): string {
    return string.split('').filter(function (item: string, pos: number, self: string[]) {
        return self.indexOf(item) === pos;
    }).join('');
}


function addNumbers(arr: number[]): number {
    return arr.reduce((a: number, b: number) => a + b, 0);
}

// let sum: number = 0;
$(function () {
    $(".input-text-s").keyup(function () {
        const numbers: number[] = [];
        $(".input-text-s").each(function () {
            numbers.push(parseInt($(this).val() as string, 10));
        });
        const filtered: number[] = numbers.filter(Boolean);
        const sum: number = addNumbers(filtered);
        $('.t_sum').text('Rs. ' + sum);
    });
});

function crossNumbers(numbersStr: string, amount: string) {
    let sum: number = 0;
    if (amount === '') {
        amount = '0';
    }

    const finalArray: string[] = [];
    let result: string = '';
    const numbersToArray: string[] = numbersStr.split('');
    const array: string[] = $.map(numbersToArray, function (value: string, index: number) {
        return [value];
    });
    let nk: number = 1;
    $.each(array, function (key: number, valueObj: string) {
        $.each(array, function (fkey: number, falueObj: string) {
            const fnum: string = valueObj + '' + falueObj;
            if ($('#withJoda').is(":checked")) {
                if (parseInt(fnum) % 11 !== 0) {
                    if ($.inArray(fnum, finalArray) === -1) {
                        finalArray.push(fnum);
                        result += '<p class="d-flex betingTabel align-items-center" id="cross-c-' + nk + '"><span class="first_value">' + fnum + '</span><span>=</span><input type="text" name="main[' + fnum + ']" value="' + parseInt(amount) + '" class="hide-c" readonly /><button class="btn-close" id="cross' + fnum + '"><img src="crossIcon.png" alt=""></button></p>';
                        nk++;
                    }
                }
            } else {
                if ($.inArray(fnum, finalArray) === -1) {
                    finalArray.push(fnum);
                    result += '<p class="d-flex betingTabel align-items-center" id="cross-c-' + nk + '"><span class="first_value">' + fnum + '</span><span>=</span><input type="text" name="main[' + fnum + ']" value="' + parseInt(amount) + '" class="hide-c" readonly /><button class="btn-close" id="cross' + fnum + '"><img src="crossIcon.png" alt=""></button></p>';
                    nk++;
                }
            }
        });       
       
    });

    /****share icon hide *****/
    $(".leftArrow").removeClass("d-none");
    $(".shareBtnIcon").removeClass("openClass");
    $(".rightArrow").addClass("d-none");
    $(".shareBtnIcon").addClass("shareBtn");
    /****share icon hide *****/

    $('.cross_list').html(result);
    $(".cross_list .hide-c").each(function () {
        if ($(this).val() !== "") {
            let camount = $(this).val() || '';
            sum += Number(camount);
        }
    });
    $('.t_cross_amount').text('Rs. ' + sum);
}

$(document).on('click', '.checkbox-round#withJoda', function () {
    $('input[type="number"].cross_amount').keyup();
    crossValidation();

});


function crossValidation() {
    let crossNo = $("#cross_number").val();
    let crossAmt = $('.cross_amount').val();
    let isChecked = $("#withJoda").is(":checked");
    if (isChecked) {

        if (crossNo === '' && crossAmt === '') {
            $('#withJoda').parents('label').addClass('activePalti');
            $('#withJoda').parents('.betingInputChip').addClass('error-class');
            $('.cross_amount').parents('.betingInput').addClass('error-class');

        } else if (crossNo !== '' || crossAmt !== '') {
            $('#withJoda').parents('label').addClass('activePalti');
            $('#withJoda').parents('.betingInputChip').removeClass('error-class');
            $('.cross_amount').parents('.betingInput').removeClass('error-class');

        } else {
            $('#withJoda').parents('.betingInputChip').removeClass('error-class');
            $('.cross_amount').parents('.betingInput').removeClass('error-class');
            $('#withJoda').parents('label').removeClass('activePalti');

        }
    } else {

        $('#withJoda').parents('label').removeClass('activePalti');
        $('#withJoda').parents('.betingInputChip').removeClass('error-class');
        $('.cross_amount').parents('.betingInput').removeClass('error-class');
    }

}