import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from "../services/auth.service";
import Loader from "../components/loader";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Gateways(props: any) {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [gatewaysList, setGatewaysList] = useState<any>();
    const [amount] = useState(location.state?.amount);
    const [videoLink, setVideoLink] = useState("");
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (!location.state?.amount) {
            navigate("/wallet", { replace: true, state: "" });
        }
        // eslint-disable-next-line
    }, [location.state]);


    useEffect(() => {
        if (loading) {
            document.body.style.overflow = 'hidden'; // Disable body scroll
        } else {
            document.body.style.overflow = 'auto'; // Enable body scroll
        }
    }, [loading]);

    useEffect(() => {
        setLoading(true);
        AuthService.gateways(amount)
            .then(
                (response) => {
                    if (response.status === true) {
                        setGatewaysList(response.payment_gateways);
                    } else {
                        if (response.code === 401) {
                            localStorage.removeItem("sg_token");
                            navigate('/', { state: { message: response?.msg } });
                        }
                        //alert(response.msg);
                    }
                }
            ).catch((error) => {
                const message =
                    (error.response &&
                        error.response?.data?.msg);
                //alert(message);
                if (error.response?.data?.code === 401) {
                    localStorage.removeItem("sg_token");
                    navigate('/', { state: { message: message } });
                }
            }).finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const handleSelectGateway = (bankId: any) => {
        navigate('/gateways-details', { state: { amount: amount, bankId: bankId } });
    }


    useEffect(() => {
        AuthService.getVideoLink()
            .then(
                (response) => {
                    // console.log(response.profile);
                    if (response.status === true) {
                        setVideoLink(response.video_link);
                    } else {
                        if (response.code === 401) {
                            localStorage.removeItem("sg_token");
                            navigate('/', { state: { message: response?.msg } });
                        }
                    }

                }
            ).catch((error) => {
                const message =
                    (error.response &&
                        error.response?.data?.msg);
                if (error.response?.data?.code === 401) {
                    localStorage.removeItem("sg_token");
                    navigate('/', { state: { message: message } });
                }
            }).finally(() => {
                setLoading(false);
            });

        // eslint-disable-next-line
    }, []);


    return (
        <div className="container">
            {loading && (<Loader />)}
            <div className="row px-2">
                <div className="col-12">
                    <h1 className="getwayHeding">Select Payment Gateway</h1>
                </div>
                {gatewaysList && gatewaysList.map((key: string, index: string) => (
                    <div className="col-3 paymentOption px-0" key={index}>
                        <button onClick={(e) => handleSelectGateway(gatewaysList[index]['id'])}>
                            <p dangerouslySetInnerHTML={{ __html: gatewaysList[index]['name'] }}></p>
                            <img src={'./uploads/payment_gateways/' + gatewaysList[index]['image']} alt="" className="img-fluid" />
                        </button>
                    </div>
                ))}

            </div>
            {videoLink &&
                <div className="row">
                    <div className="col-12 mt-4">
                        <div className="help_Videos">
                            <iframe
                                className="videoFram"
                                src={videoLink}
                                title="YouTube video player"
                                frameBorder="1"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen>
                            </iframe>
                            <Button variant="primary" onClick={handleShow}>Play</Button>
                        </div>
                    </div>
                </div>
            }

            <Modal show={show} onHide={handleClose} centered className="videoModel">
                <Modal.Body>
                    <button onClick={handleClose}><p>x</p></button>
                    <div className="help_Videos">
                        <iframe
                            className="videoFram"
                            src={videoLink + '?autoplay=1'}
                            title="YouTube video player"
                            frameBorder="1"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen>
                        </iframe>
                    </div>
                </Modal.Body>
            </Modal>
        </div>


    )
}
export default Gateways;