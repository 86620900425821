import React, { useEffect, useRef, useState } from 'react';
import AuthService from "../services/auth.service";
import Helpers from "../services/helpers";
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../components/loader';

function Profile() {
  let fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [isPageloading, setIsPageLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [imageFile, setImageFile] = useState<any>(null);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [usersData, setUsersData] = useState<any>([]);
  const [isPasswordSet, setIsPasswordSet] = useState(0);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    full_name: '',
    user_mobile: '',
    image: {}
  });


  useEffect(() => {
    AuthService.profile()
      .then(
        (response) => {
          // console.log(response.profile);
          if (response.status === true) {
            setMessage(response.msg);
            setUsersData(response.refer_list);
            setIsPasswordSet(response.is_password_set)
            setFormData({
              ...formData,
              [`full_name`]: response.profile[0]?.full_name,
              [`user_mobile`]: response.profile[0]?.user_mobile,
            });
          } else {
            if (response.code === 401) {
              localStorage.removeItem("sg_token");
              navigate('/', { state: { message: response?.msg } });
            }
            setMessage(response.msg);
          }

        }
      ).catch((error) => {
        const message =
          (error.response &&
            error.response?.data?.msg);
        setMessage(message);
        if (error.response?.data?.code === 401) {
          localStorage.removeItem("sg_token");
          navigate('/', { state: { message: message } });
        }
      }).finally(() => {
        setIsPageLoading(false);
      });

    // eslint-disable-next-line
  }, []);


  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};

    if (!formData.full_name) {
      newErrors.full_name = "Name is required";
      isValid = false;
    }

    if (!isValid) {
      setLoading(false);
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSetImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files as FileList;
    if (selectedFile?.[0]) {
      setImageFile(selectedFile?.[0]);
      setPreviewImage(URL.createObjectURL(selectedFile?.[0]));
    }
  }

  const removeImage = () => {
    setImageFile(undefined);
    setPreviewImage("");

    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clearing the value
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    const token = Helpers.getTokenData();
    const form = document.getElementById('profile') as HTMLFormElement;
    const profileData = new FormData(form);
    profileData.append('userId', token?.uid);
    profileData.append("file", imageFile);
    if (validateForm()) {
      AuthService.profileUpdate(profileData).then(
        (response) => {
          // console.log(response.status);
          if (response.status === true) {
            setMessage(response.msg);
            navigate('.');
          } else {
            setMessage(response.msg);
            if (response.code === 401) {
              localStorage.removeItem("sg_token");
              navigate('/', { state: { message: response?.msg } });
            }
          }
          setLoading(false);
        }
      ).catch((error) => {
        const message =
          (error.response &&
            error.response?.data?.msg);
        setMessage(message);

        if (error.response?.data?.code === 401) {
          localStorage.removeItem("sg_token");
          navigate('/', { state: { message: message } });
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  };


  return (
    <>
      {isPageloading && (<Loader />)}
      <section className="profileContainer">
        <div className="container">
          <div className="row position-relative">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="addMoneyContainer" style={{ marginBottom: 0, borderRadius: 10, paddingBottom: 10 }}>
                <form id="profile" onSubmit={handleSubmit}>
                  {/* <div className='profileMain'>
                    <div className="profile ">
                      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="67" viewBox="0 0 60 67" fill="none">
                        <g opacity="0.4">
                          <path d="M29.9406 30.1499C25.6626 30.1499 21.5847 30.8197 17.9014 32.4947C20.0738 36.8497 24.6375 40.1999 29.9406 40.1999C35.2436 40.1999 39.8063 36.8497 41.9805 32.4947C38.2955 30.8197 34.2185 30.1499 29.9406 30.1499Z" fill="#B7B7B7" />
                          <path d="M17.9014 35.8447C8.23464 40.1997 1.22476 49.2449 0 60.2999C0 63.9849 2.79022 66.9999 6.49062 66.9999H53.3906C57.0924 66.9999 59.8829 63.9849 59.8829 60.2999C58.6568 49.2449 51.6453 40.1997 41.9805 35.8447C39.8064 40.1997 35.2437 43.5499 29.9406 43.5499C24.6376 43.5499 20.0739 40.1997 17.9014 35.8447Z" fill="#B7B7B7" />
                          <path d="M17.9014 32.4951C8.23464 36.8501 1.22476 45.8953 0 56.9503C0 60.6353 2.79022 63.6503 6.49062 63.6503H53.3906C57.0924 63.6503 59.8829 60.6353 59.8829 56.9503C58.6568 45.8953 51.6453 36.8501 41.9805 32.4951C39.8064 36.8501 35.2437 40.2003 29.9406 40.2003C24.6376 40.2003 20.0739 36.8501 17.9014 32.4951Z" fill="#B7B7B7" />
                          <path d="M29.9407 30.1499C26.0983 30.1499 22.4327 30.9271 19.0532 32.2437C22.2039 34.3073 25.9107 35.5937 29.9407 35.5937C33.9808 35.5937 37.6725 34.3173 40.8282 32.2437C37.4481 30.9271 33.7832 30.1499 29.9407 30.1499Z" fill="#B7B7B7" />
                          <path d="M46.6904 16.75C46.6904 21.1924 44.9257 25.4528 41.7845 28.594C38.6432 31.7353 34.3828 33.5 29.9404 33.5C25.4981 33.5 21.2376 31.7353 18.0964 28.594C14.9552 25.4528 13.1904 21.1924 13.1904 16.75C13.1904 12.3076 14.9552 8.0472 18.0964 4.90596C21.2376 1.76473 25.4981 0 29.9404 0C34.3828 0 38.6432 1.76473 41.7845 4.90596C44.9257 8.0472 46.6904 12.3076 46.6904 16.75Z" fill="#B7B7B7" />
                          <path d="M17.9015 32.4951C16.8389 33.1651 15.86 33.5001 14.8657 34.1701C17.597 39.5301 23.3781 43.5503 29.9407 43.5503C36.5034 43.5503 42.2855 39.5301 45.0157 34.1701C44.0208 33.5001 43.0426 33.1651 41.9806 32.4951C39.8065 36.8501 35.2438 40.2003 29.9407 40.2003C24.6377 40.2003 20.074 36.8501 17.9015 32.4951Z" fill="#B7B7B7" />
                        </g>
                      </svg>
                      <div className="uplod">
                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                          <rect width="44" height="44" rx="22" fill="#464A99" />
                          <path d="M27.9968 15H15.9756C14.8969 15 14 15.8953 14 17V27C14 28.1047 14.8969 29 15.9753 29H27.9965C29.1031 29 30 28.1047 30 27V17C30.0003 15.8953 29.13 15 27.9968 15ZM17.4783 17C18.3082 17 18.9809 17.6716 18.9809 18.5C18.9809 19.3284 18.336 20 17.4783 20C16.6205 20 15.9756 19.3284 15.9756 18.5C15.9756 17.6716 16.6762 17 17.4783 17ZM27.9655 26.7375C27.8778 26.9 27.7088 27 27.5241 27H16.5676C16.3791 27 16.2067 26.8944 16.1212 26.7266C16.0357 26.5588 16.0519 26.3575 16.163 26.2053L18.3543 23.2053C18.4487 23.075 18.599 23 18.7586 23C18.9183 23 19.0691 23.0763 19.1634 23.2054L20.1796 24.5964L23.1003 20.2495C23.1946 20.0844 23.3511 20 23.517 20C23.6829 20 23.841 20.0835 23.9337 20.2227L27.9407 26.2227C28.0437 26.375 28.0531 26.5719 27.9655 26.7375Z" fill="white" />
                        </svg>
                        <input type="file" id="myFile" name="user_image" accept="image/*" ref={fileInputRef} onChange={handleSetImage} />
                      </div>
                      {previewImage && (
                        <>
                          <div className="uplogImageqr profileView">
                            <img className="preview img-fluid" src={previewImage} alt="" />
                            <button onClick={removeImage}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" fill="none">
                                <path d="M15.6203 5.1817L5.83838 14.09M5.83838 5.1817L15.6203 14.09" stroke="#DEDEDE" strokeWidth="1.63033" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div> */}
                  <div className="loginButton">
                    {/* <div className="registerInput">
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                        <path d="M9.01587 10.8C11.5012 10.8 13.5159 8.38234 13.5159 5.4C13.5159 2.41766 11.5012 0 9.01587 0C6.53059 0 4.51587 2.41766 4.51587 5.4C4.51587 8.38234 6.53059 10.8 9.01587 10.8Z" fill="url(#paint0_linear_4_20)" />
                        <path d="M17.8357 15.39C17.0257 13.77 15.4957 12.42 13.5157 11.61C12.9757 11.43 12.3457 11.43 11.8957 11.7C10.9957 12.24 10.0957 12.51 9.01572 12.51C7.93572 12.51 7.03572 12.24 6.13572 11.7C5.68572 11.52 5.05572 11.43 4.51572 11.7C2.53572 12.51 1.00572 13.86 0.195723 15.48C-0.434278 16.65 0.555722 18 1.90572 18H16.1257C17.4757 18 18.4657 16.65 17.8357 15.39Z" fill="url(#paint1_linear_4_20)" />
                        <defs>
                          <linearGradient id="paint0_linear_4_20" x1="9.01587" y1="0" x2="9.01587" y2="10.8" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#A200DB" />
                            <stop offset="1" stopColor="#8327F7" />
                          </linearGradient>
                          <linearGradient id="paint1_linear_4_20" x1="9.01572" y1="11.4846" x2="9.01572" y2="18" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#A200DB" />
                            <stop offset="1" stopColor="#8327F7" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <input type="text" id="full_name" aria-describedby="full_name" name="full_name" placeholder="Full Name" value={formData?.full_name} onChange={(e) => handleInputChange(e)} autoComplete='off' />
                    </div>
                    {errors.full_name && <div className="text-start text-danger pb-3 loginErrors">{errors.full_name}</div>} */}

                    <div className="registerInput" style={{ width: '100%' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                        <path d="M9.01587 10.8C11.5012 10.8 13.5159 8.38234 13.5159 5.4C13.5159 2.41766 11.5012 0 9.01587 0C6.53059 0 4.51587 2.41766 4.51587 5.4C4.51587 8.38234 6.53059 10.8 9.01587 10.8Z" fill="url(#paint0_linear_4_20)" />
                        <path d="M17.8357 15.39C17.0257 13.77 15.4957 12.42 13.5157 11.61C12.9757 11.43 12.3457 11.43 11.8957 11.7C10.9957 12.24 10.0957 12.51 9.01572 12.51C7.93572 12.51 7.03572 12.24 6.13572 11.7C5.68572 11.52 5.05572 11.43 4.51572 11.7C2.53572 12.51 1.00572 13.86 0.195723 15.48C-0.434278 16.65 0.555722 18 1.90572 18H16.1257C17.4757 18 18.4657 16.65 17.8357 15.39Z" fill="url(#paint1_linear_4_20)" />
                        <defs>
                          <linearGradient id="paint0_linear_4_20" x1="9.01587" y1="0" x2="9.01587" y2="10.8" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#A200DB" />
                            <stop offset="1" stopColor="#8327F7" />
                          </linearGradient>
                          <linearGradient id="paint1_linear_4_20" x1="9.01572" y1="11.4846" x2="9.01572" y2="18" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#A200DB" />
                            <stop offset="1" stopColor="#8327F7" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <input type="text" id="user_id" aria-describedby="user_id" placeholder="User Id" value={Helpers.getTokenData()?.uuid} readOnly />
                    </div>

                    <div className="registerInput" style={{ width: '100%' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M3.625 7.795C5.065 10.625 7.38 12.94 10.215 14.38L12.415 12.175C12.69 11.9 13.085 11.82 13.43 11.93C14.55 12.3 15.755 12.5 17 12.5C17.555 12.5 18 12.945 18 13.5V17C18 17.555 17.555 18 17 18C7.61 18 0 10.39 0 1C0 0.445 0.45 0 1 0H4.5C5.055 0 5.5 0.445 5.5 1C5.5 2.245 5.7 3.45 6.07 4.57C6.18 4.915 6.1 5.31 5.825 5.585L3.625 7.795Z" fill="url(#paint0_linear_2_136)" />
                        <defs>
                          <linearGradient id="paint0_linear_2_136" x1="9" y1="0" x2="9" y2="18" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#A200DB" />
                            <stop offset="1" stopColor="#8327F7" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <input type="tel" id="user_mobile" aria-describedby="user_mobile" placeholder="Mobile No." value={formData?.user_mobile} readOnly />
                    </div>
                    {message && (
                      <div className='d-block text-danger mt-2 loginErrors text-center' role="alert">
                        {message}
                      </div>
                    )}

                    {/* <div className="form-group text-center mt-2">
                      <button type="submit" className="btn btn-primary login-btn" disabled={loading} >
                        {loading && (
                          <span className="loader-spin"></span>
                        )}
                        <span>Update</span>
                      </button>                    
                    </div> */}
                    {isPasswordSet===1 &&
                      <div className='changePasswordMain text-end'>
                        <Link to="/change-password" className='newAcc' style={{ padding: '0' }}>Change Password</Link>
                      </div>
                    }
                  </div>
                </form>
              </div>
            </div>

          </div>
          {usersData.length > 0 &&
            <div className='referral_main mt-3'>
              <div className='row'>
                <div className="col-6 px-0">
                  <p className='referral'>Referral</p>
                </div>

                <div className="col-6 text-end px-0">
                  {usersData.length > 4 &&
                    <Link to="/referral" style={{ textDecoration: 'none' }}>
                      <p className='referralSeeAll'>See All</p>
                    </Link>
                  }
                </div>

                {usersData.map((user: any) => (
                  <div key={user.id} className="col-3 px-0">
                    <div className="usersCard">
                      {/* <img src={user.imageUrl} alt={user.name} className="img-fluid" /> */}
                      <img src="./user123.png" alt="" className='img-fluid' />
                      <p>{user.mobileNumber}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
        </div>
      </section>
    </>
  )
}
export default Profile;

