import React, { useEffect, useState } from "react";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import Helpers from "../services/helpers";

function SetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);
  const [formData, setFormData] = useState({
    pwd: "",
    confirm_pwd: "",
  });


  useEffect(() => {
    const token = Helpers.getTokenData();
    let isPwdSet = localStorage.getItem("is_password_set");
    if (!token?.uid) {
      navigate("/", { replace: true });

    }
    if (isPwdSet === "1") {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, []);


  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  }

  const validateForm = async () => {
    let isValid = true;
    const newErrors: any = {};

    if (formData.pwd.length < 6) {
      newErrors.pwd = "Password required minimum 6 characters";
      isValid = false;
    }

    if (!formData.pwd) {
      newErrors.pwd = "Password required";
      isValid = false;
    }

    if (formData.confirm_pwd !== formData.pwd) {
      newErrors.confirm_pwd = "Confirm password does not match";
      isValid = false;
    }

    if (!formData.confirm_pwd) {
      newErrors.confirm_pwd = "Confirm password required";
      isValid = false;
    }

    if (!isValid) {
      setLoading(false);
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    if (await validateForm()) {
      AuthService.setPassword(formData).then(
        (response) => {
          if (response.status === true) {
            setMessage(response.msg);
            localStorage.setItem("is_password_set", "1");
            // eslint-disable-next-line       
            setFormData({
              ...formData,
              [`pwd`]: '',
              [`confirm_pwd`]: '',
            });
            navigate("/home", { replace: true });
          } else {
            setMessage(response.msg);
            if (response.code === 401) {
              localStorage.removeItem("sg_token");
              navigate('/', { state: { message: response?.msg } });
            }
          }
          setLoading(false);
        }
      ).catch((error) => {
        const message =
          (error.response &&
            error.response?.data?.msg);
        setMessage(message);
        if (error.response?.data?.code === 401) {
          localStorage.removeItem("sg_token");
          navigate('/', { state: { message: message } });
        }
      }).finally(() => {
        setLoading(false);
      });
    }

  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowCnfPassword = () => {
    setShowCnfPassword(!showCnfPassword);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 loginForm Createpassword">
          <div style={{ zIndex: '99', position: 'relative' }}>
            <h1 className="text-white text-start">👉 Create password</h1>
            <p>Create password to continue your account.</p>
            <form className="registerButton" onSubmit={handleSubmit}>


              <div className="registerInput">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" fill="none">
                  <path d="M13.4987 7.501H10.499H4.49957V5.4012C4.49957 3.82635 5.62447 2.40148 7.19932 2.2515C8.99914 2.10151 10.499 3.52638 10.499 5.25121C10.499 5.70117 10.799 6.00114 11.2489 6.00114H11.9989C12.4488 6.00114 12.7488 5.70117 12.7488 5.25121C12.7488 2.40148 10.499 0.0767041 7.64927 0.00171123C4.72455 -0.0732816 2.24979 2.32649 2.24979 5.25121V7.501H1.49986C0.674936 7.501 0 8.17593 0 9.00086V14.2504C0 16.3502 1.64984 18 3.74964 18H11.2489C13.3487 18 14.9986 16.3502 14.9986 14.2504V9.00086C14.9986 8.17593 14.3236 7.501 13.4987 7.501ZM8.24922 13.2754V14.2504C8.24922 14.6253 7.87425 15.0003 7.49929 15.0003C7.12432 15.0003 6.74936 14.6253 6.74936 14.2504V13.2754C6.2994 13.0505 5.99943 12.5255 5.99943 12.0006C5.99943 11.1756 6.67437 10.5007 7.49929 10.5007C8.32421 10.5007 8.99914 11.1756 8.99914 12.0006C8.99914 12.5255 8.69917 13.0505 8.24922 13.2754Z" fill="url(#paint0_linear_2_224)" />
                  <defs>
                    <linearGradient id="paint0_linear_2_224" x1="7.49929" y1="0" x2="7.49929" y2="18" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A200DB" />
                      <stop offset="1" stopColor="#8327F7" />
                    </linearGradient>
                  </defs>
                </svg>
                <input type={showPassword ? "text" : "password"} name="pwd" id="pwd" aria-describedby="pwd" placeholder="Password" autoComplete='off' value={formData.pwd} onChange={(e) => handleInputChange(e)} />
                <div className="password-icon-eye" onClick={handleClickShowPassword}>
                      {showPassword ?
                        <svg style={{ display: 'block', marginRight: 0 }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62283 2.81932 7.5129 4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968ZM5.9356 7.3497C4.60673 8.56015 3.6378 10.1672 3.22278 12.0002C4.14022 16.0521 7.7646 19.0002 12.0003 19.0002C13.5997 19.0002 15.112 18.5798 16.4243 17.8384L14.396 15.8101C13.7023 16.2472 12.8808 16.5002 12.0003 16.5002C9.51498 16.5002 7.50026 14.4854 7.50026 12.0002C7.50026 11.1196 7.75317 10.2981 8.19031 9.60442L5.9356 7.3497ZM12.9139 14.328L9.67246 11.0866C9.5613 11.3696 9.50026 11.6777 9.50026 12.0002C9.50026 13.3809 10.6196 14.5002 12.0003 14.5002C12.3227 14.5002 12.6309 14.4391 12.9139 14.328ZM20.8068 16.5925L19.376 15.1617C20.0319 14.2268 20.5154 13.1586 20.7777 12.0002C19.8603 7.94818 16.2359 5.00016 12.0003 5.00016C11.1544 5.00016 10.3329 5.11773 9.55249 5.33818L7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87993 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925ZM11.7229 7.50857C11.8146 7.50299 11.9071 7.50016 12.0003 7.50016C14.4855 7.50016 16.5003 9.51488 16.5003 12.0002C16.5003 12.0933 16.4974 12.1858 16.4919 12.2775L11.7229 7.50857Z" fill="white" />
                        </svg>

                        :
                        <svg style={{ display: 'block', marginRight: 0 }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                          <path d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z" fill="white" />
                        </svg>
                      }
                    </div>
              </div>
              {errors.pwd && <div className="text-danger text-start pb-2 loginErrors">{errors.pwd}</div>}
              <div className="registerInput">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" fill="none">
                  <path d="M13.4987 7.501H10.499H4.49957V5.4012C4.49957 3.82635 5.62447 2.40148 7.19932 2.2515C8.99914 2.10151 10.499 3.52638 10.499 5.25121C10.499 5.70117 10.799 6.00114 11.2489 6.00114H11.9989C12.4488 6.00114 12.7488 5.70117 12.7488 5.25121C12.7488 2.40148 10.499 0.0767041 7.64927 0.00171123C4.72455 -0.0732816 2.24979 2.32649 2.24979 5.25121V7.501H1.49986C0.674936 7.501 0 8.17593 0 9.00086V14.2504C0 16.3502 1.64984 18 3.74964 18H11.2489C13.3487 18 14.9986 16.3502 14.9986 14.2504V9.00086C14.9986 8.17593 14.3236 7.501 13.4987 7.501ZM8.24922 13.2754V14.2504C8.24922 14.6253 7.87425 15.0003 7.49929 15.0003C7.12432 15.0003 6.74936 14.6253 6.74936 14.2504V13.2754C6.2994 13.0505 5.99943 12.5255 5.99943 12.0006C5.99943 11.1756 6.67437 10.5007 7.49929 10.5007C8.32421 10.5007 8.99914 11.1756 8.99914 12.0006C8.99914 12.5255 8.69917 13.0505 8.24922 13.2754Z" fill="url(#paint0_linear_2_226)" />
                  <defs>
                    <linearGradient id="paint0_linear_2_226" x1="7.49929" y1="0" x2="7.49929" y2="18" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A200DB" />
                      <stop offset="1" stopColor="#8327F7" />
                    </linearGradient>
                  </defs>
                </svg>
                <input type={showCnfPassword ? "text" : "password"} name="confirm_pwd" id="confirm_pwd" aria-describedby="confirm_pwd" placeholder="Confirm Password" autoComplete='off' value={formData.confirm_pwd} onChange={(e) => handleInputChange(e)} />
                <div className="password-icon-eye" onClick={handleClickShowCnfPassword}>
                      {showCnfPassword ?
                        <svg style={{ display: 'block', marginRight: 0 }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62283 2.81932 7.5129 4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968ZM5.9356 7.3497C4.60673 8.56015 3.6378 10.1672 3.22278 12.0002C4.14022 16.0521 7.7646 19.0002 12.0003 19.0002C13.5997 19.0002 15.112 18.5798 16.4243 17.8384L14.396 15.8101C13.7023 16.2472 12.8808 16.5002 12.0003 16.5002C9.51498 16.5002 7.50026 14.4854 7.50026 12.0002C7.50026 11.1196 7.75317 10.2981 8.19031 9.60442L5.9356 7.3497ZM12.9139 14.328L9.67246 11.0866C9.5613 11.3696 9.50026 11.6777 9.50026 12.0002C9.50026 13.3809 10.6196 14.5002 12.0003 14.5002C12.3227 14.5002 12.6309 14.4391 12.9139 14.328ZM20.8068 16.5925L19.376 15.1617C20.0319 14.2268 20.5154 13.1586 20.7777 12.0002C19.8603 7.94818 16.2359 5.00016 12.0003 5.00016C11.1544 5.00016 10.3329 5.11773 9.55249 5.33818L7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87993 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925ZM11.7229 7.50857C11.8146 7.50299 11.9071 7.50016 12.0003 7.50016C14.4855 7.50016 16.5003 9.51488 16.5003 12.0002C16.5003 12.0933 16.4974 12.1858 16.4919 12.2775L11.7229 7.50857Z" fill="white" />
                        </svg>

                        :
                        <svg style={{ display: 'block', marginRight: 0 }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                          <path d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z" fill="white" />
                        </svg>
                      }
                    </div>
              </div>
              {errors.confirm_pwd && <div className="text-danger text-start pb-2 loginErrors">{errors.confirm_pwd}</div>}

              {message && (
                <div className='d-block text-danger' role="alert">
                  {message}
                </div>
              )}
              <button type="submit" className="btn btn-primary login-btn" disabled={loading} >
                {loading && (
                  <span className="loader-spin"></span>
                )}
                <span>Create Password</span>
              </button>
              {/* <Link to="/password" className="btn btn-primary">Continue</Link> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SetPassword;