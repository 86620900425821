import React from "react";
import { Link } from 'react-router-dom';

function Pending(props: any) {
  return (
    <div className="col-12 pandingNew">
      <div className="paymentContainer">
        <div className="Success">
          <div className="addPayment">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path d="M20 0C8.90909 0 0 8.90909 0 20C0 31.0909 8.90909 40 20 40C31.0909 40 40 31.0909 40 20C40 8.90909 31.0909 0 20 0ZM32.1818 23.2727C32 24 31.2727 24.3636 30.5455 24.1818L20.3636 22.1818C20 22.1818 19.6364 21.8182 19.2727 21.6364C18.5455 21.2727 18.1818 20.7273 18.1818 20L18.5455 5.45455C18.5455 4.54545 19.2727 4 20 4C20.7273 4 21.4545 4.72727 21.4545 5.45455L21.6364 18L31.0909 21.2727C32 21.4545 32.3636 22.3636 32.1818 23.2727Z" fill="#fff" />
            </svg>
            <p className="pendingHeding">Pending</p>
            {props.txnStatusRes?.amount && <p className="pendingPyment">₹{props.txnStatusRes?.amount}</p>}
            {props.txnStatusRes?.txnId && <p className="pendingPyment">Txn Id : {props.txnStatusRes?.txnId}</p>}
            {props.txnStatusRes?.msg && <p className="warningPayment">{props.txnStatusRes?.msg}</p>}
            <div className="btn-back">
              <Link to="/home">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
export default Pending;