import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/auth.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import $ from 'jquery';
import Loader from '../components/loader';
// function GamePlay(props: any) {
//     const [statementList, setStatementList] = useState(props.statementList);
//     useEffect(() => {
//         setStatementList(props.statementList);

//         // eslint-disable-next-line
//     }, [statementList])
//     // console.log(props.statementList);

interface data {
    [key: string]: any[];
}

interface details {
    status: boolean,
    userWallet: string,
    statementType: string,
    title: string,
    icon: string,
    game: string,
    amount: string,
    total_bidding_amount: string,
    date: string,
    time: string,
    rimg: string,
    txn_id: string,
    msg: string,
    account_holder: string,
    account_number: string,
    bank_name: string,
    deposite_method: string,
    ref_no: string,
    remark: string,
    rlink: string,
}

function GamePlay({ statementList, load }: any) {
    const [isShow, invokeModal] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [statementData, setStatementData] = useState<details>();
    const [mainChart, setMainChart] = useState<data>();
    const [outerChart, setOuterChart] = useState<data>();
    const [innerChart, setInnerChart] = useState<data>();
    const [statusType, setStatusType] = useState("");
    const navigate = useNavigate();
    const [isPdfGenerating, setIsPdfGenerating] = useState<boolean>(false);
    const pdfTemplateRef = useRef(null);
    var total = 0;

    const handleGeneratePdf = async () => {
        setIsPdfGenerating(true);
        $(".downlodButton").addClass('d-none');
        $(".betNumberData").removeClass('betNumberDataScroll');

        let fileName = Date.now();
        try {
            const input = pdfTemplateRef.current as any as HTMLElement;
            const pdf = new jsPDF({
                orientation: "portrait",
                unit: "px",
                format: "a4"
            });

            const canvas = await html2canvas(input);
            const inputWidth = pdf.internal.pageSize.getWidth();
            const inputHeight = (canvas.height * inputWidth) / canvas.width;

            let height = pdf.internal.pageSize.height;
            let pageHeightInPixels = inputHeight;
            let pages = pageHeightInPixels / height;

            const roundOff = Number(pages.toString().split('.')[1].substring(0, 1));
            const pageNo = (roundOff > 0 ? pages + 1 : pages);
            let pageCount = pages < 1 ? 1 : Math.trunc(pageNo);

            html2canvas(input)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');

                    for (let i = 1; i <= pageCount; i++) {
                        let pdfStartingHeight = height * (i - 1);
                        pdf.addImage(imgData, 'PNG', 20, -pdfStartingHeight, inputWidth * 0.8, inputHeight * 0.8);
                        if (i < pageCount) {
                            pdf.addPage();
                        }
                    }
                    pdf.save(fileName + '.pdf');

                    setTimeout(() => {
                        $(".downlodButton").removeClass('d-none');
                        $(".betNumberData").addClass('betNumberDataScroll');
                        setIsPdfGenerating(false);
                    }, 1000);
                });

        } catch (error) {
            console.error("error generating pdf : ", error);
        }

    };

    const handleClick = (value: any): void => {
        let formData = {
            'typeId': value?.type,
            'rowId': value?.rowId,
            'mainId': value?.id,
            'userId': value?.user,
        }
        setStatusType(value?.f_status);
        setMainChart(undefined);
        setOuterChart(undefined);
        setInnerChart(undefined);
        setLoading(true);
        AuthService.statementDetails(formData).then(
            (response) => {

                if (response.status === true) {
                    setStatementData(response);
                    setMainChart(JSON.parse(response.main_chart));
                    setOuterChart(JSON.parse(response.outer_chart));
                    setInnerChart(JSON.parse(response.inner_chart));

                } else {
                    setMessage(response.msg);
                    if (response.code === 401) {
                        localStorage.removeItem("sg_token");
                        navigate('/', { state: { message: response?.msg } });
                    }
                }
            }
        ).catch((error) => {
            const message =
                (error.response &&
                    error.response?.data?.msg);
            setMessage(message);

            if (error.response?.data?.code === 401) {
                localStorage.removeItem("sg_token");
                navigate('/', { state: { message: message } });
            }
        }).finally(() => {
            initModal();
            setLoading(false);
        });

    };


    const initModal = () => {
        return invokeModal(!isShow);
    }

    return (
        <>
            <div>
                {loading && (<Loader />)}
                {statementList.length > 0 && Object.entries(statementList).map((value, index) => (
                    statementList[index].type === "1" &&
                    <section className="passbookContainer card" key={index} onClick={() => handleClick(statementList[index])}>
                        <span className='d-none'>{++total}</span>
                        <div className="row">
                            <div className="col-6 px-0 d-flex">
                                <div className='statusIcons'>

                                    {statementList[index].f_status === 'Success' &&
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_112_5190)">
                                                <rect x="1" y="1" width="15" height="15" rx="7.5" fill="#66A252" />
                                                <path d="M8.50098 0.999878C4.36522 0.999878 1.00098 4.36413 1.00098 8.49988C1.00098 12.6356 4.36522 15.9999 8.50098 15.9999C12.6367 15.9999 16.001 12.635 16.001 8.49988C16.001 4.36471 12.6367 0.999878 8.50098 0.999878ZM8.50098 14.838C5.00659 14.838 2.16286 11.9948 2.16286 8.49988C2.16286 5.00491 5.00659 2.16176 8.50098 2.16176C11.9959 2.16176 14.8391 5.00491 14.8391 8.49988C14.8391 11.9948 11.9954 14.838 8.50098 14.838Z" fill="#66A252" />
                                                <path d="M11.9416 5.89158C11.7051 5.67663 11.3374 5.69348 11.1213 5.93108L7.5775 9.8333L5.86545 8.0928C5.63945 7.86391 5.2723 7.86042 5.04399 8.08583C4.81509 8.31066 4.81161 8.67839 5.03702 8.90729L7.18011 11.0858C7.23415 11.1409 7.2986 11.1846 7.36972 11.2144C7.44084 11.2442 7.51719 11.2596 7.59431 11.2595C7.5978 11.2595 7.60187 11.2595 7.60536 11.2601C7.68447 11.2585 7.76242 11.2408 7.83444 11.2081C7.90647 11.1753 7.97104 11.1282 8.02422 11.0696L11.981 6.71248C12.1966 6.47427 12.1792 6.10712 11.9416 5.89158Z" fill="white" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_112_5190">
                                                    <rect x="1" y="1" width="15" height="15" rx="7.5" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    }
                                    {statementList[index].f_status === 'Pending' &&

                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 2C4.67273 2 2 4.67273 2 8C2 11.3273 4.67273 14 8 14C11.3273 14 14 11.3273 14 8C14 4.67273 11.3273 2 8 2ZM11.6545 8.98182C11.6 9.2 11.3818 9.30909 11.1636 9.25455L8.10909 8.65455C8 8.65455 7.89091 8.54545 7.78182 8.49091C7.56364 8.38182 7.45455 8.21818 7.45455 8L7.56364 3.63636C7.56364 3.36364 7.78182 3.2 8 3.2C8.21818 3.2 8.43636 3.41818 8.43636 3.63636L8.49091 7.4L11.3273 8.38182C11.6 8.43636 11.7091 8.70909 11.6545 8.98182Z" fill="#D7E445" />
                                        </svg>
                                    }
                                    {statementList[index].f_status === 'Failed' &&

                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5ZM9 7.93935L6.87868 5.81802L5.81802 6.87868L7.93935 9L5.81802 11.1213L6.87868 12.1819L9 10.0606L11.1213 12.1819L12.1819 11.1213L10.0606 9L12.1819 6.87868L11.1213 5.81802L9 7.93935Z" fill="#CC4242" />
                                        </svg>
                                    }
                                </div>
                                <div>
                                    <h6 className="depositName" >{statementList[index].title}</h6>
                                    <h6 className="depositdatetime">{statementList[index].date}</h6>
                                </div>
                            </div>
                            <div className="col-6 text-end d-flex justify-content-end px-0">
                                <div>
                                    <h6 className={`depositAmount ${statementList[index].f_status}`}>{statementList[index].amount}</h6>
                                    <div className='closing_Balance'>
                                        <p>Closing Bal : {statementList[index].closing_balance}</p>
                                    </div>
                                </div>
                                <button className='show_detal'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                                        <path d="M1.53292 0L6.5 4.94673L11.4671 0L13 1.52664L6.5 8L0 1.52664L1.53292 0Z" fill="white" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </section>

                ))}
                {!load && total === 0 &&
                    <div className="col-12 alert-danger-custom">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-emoji-frown" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.5 3.5 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.5 4.5 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                        </svg>
                        <p>No Data Found</p>
                    </div>
                }
            </div>

            <Modal show={isShow} onHide={initModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                {isPdfGenerating && (<Loader />)}
                <Modal.Header closeButton className={'ModelHeaderpassbook status-' + statusType}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='modelHeader'>
                            {statusType === 'Success' ? (
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 40 40" fill="none">
                                        <g clipPath="url(#clip0_1177_487)">
                                            <path d="M20.187 -0.000244141C9.39877 -0.000244141 0.623047 8.77548 0.623047 19.5637C0.623047 30.3519 9.39877 39.1276 20.187 39.1276C30.9752 39.1276 39.7509 30.3504 39.7509 19.5637C39.7509 8.77701 30.9752 -0.000244141 20.187 -0.000244141ZM20.187 36.0968C11.0718 36.0968 3.65385 28.6804 3.65385 19.5637C3.65385 10.447 11.0718 3.03056 20.187 3.03056C29.3037 3.03056 36.7201 10.447 36.7201 19.5637C36.7201 28.6804 29.3022 36.0968 20.187 36.0968Z" fill="white" />
                                            <path d="M29.1638 12.7598C28.547 12.1991 27.5878 12.2431 27.0241 12.8628L17.78 23.0419L13.3141 18.5018C12.7246 17.9047 11.7669 17.8956 11.1713 18.4836C10.5742 19.07 10.5652 20.0293 11.1531 20.6264L16.7435 26.3092C16.8844 26.4527 17.0525 26.5667 17.2381 26.6445C17.4236 26.7223 17.6227 26.7623 17.8239 26.7623C17.833 26.7623 17.8436 26.7623 17.8527 26.7638C18.0591 26.7597 18.2624 26.7134 18.4503 26.628C18.6382 26.5425 18.8066 26.4196 18.9453 26.2667L29.2668 14.9012C29.8291 14.2798 29.7836 13.3221 29.1638 12.7598Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1177_487">
                                                <rect width="39.1279" height="39.1279" fill="white" transform="translate(0.620117)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </>
                            ) : (
                                statusType === 'Failed' ? (
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                                            <g clipPath="url(#clip0_2087_3910)">
                                                <path d="M19 38C8.52388 38 0 29.4761 0 19C0 8.52388 8.52388 0 19 0C29.4761 0 38 8.52388 38 19C38 29.4761 29.4761 38 19 38ZM19 2.375C9.8325 2.375 2.375 9.8325 2.375 19C2.375 28.1675 9.8325 35.625 19 35.625C28.1675 35.625 35.625 28.1675 35.625 19C35.625 9.8325 28.1675 2.375 19 2.375ZM25.0871 26.3031C24.9307 26.3033 24.7758 26.2726 24.6313 26.2126C24.4868 26.1527 24.3556 26.0648 24.2452 25.954L18.9964 20.6791L13.7227 25.9291C13.4991 26.1507 13.1967 26.2748 12.8819 26.2741C12.567 26.2734 12.2652 26.1481 12.0424 25.9255C11.8205 25.702 11.6963 25.3996 11.6969 25.0846C11.6976 24.7696 11.8231 24.4678 12.046 24.2452L17.3209 18.9964L12.0709 13.7227C11.8594 13.4973 11.744 13.1983 11.7493 12.8892C11.7545 12.5801 11.88 12.2852 12.099 12.0671C12.3181 11.8489 12.6135 11.7247 12.9227 11.7208C13.2318 11.7169 13.5303 11.8335 13.7548 12.046L19.0036 17.3209L24.2773 12.0709C24.5027 11.8594 24.8017 11.744 25.1108 11.7493C25.4199 11.7545 25.7148 11.88 25.9329 12.099C26.1511 12.3181 26.2753 12.6135 26.2792 12.9227C26.2831 13.2318 26.1665 13.5303 25.954 13.7548L20.6791 19.0036L25.9291 24.2773C26.0937 24.444 26.2054 24.6556 26.2502 24.8855C26.2951 25.1155 26.271 25.3535 26.1811 25.5699C26.0912 25.7862 25.9394 25.9712 25.7448 26.1017C25.5502 26.2321 25.3214 26.3022 25.0871 26.3031Z" fill="white" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_2087_3910">
                                                    <rect width="38" height="38" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </>
                                ) : (
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                                            <g clipPath="url(#clip0_2117_955)">
                                                <path d="M11.2274 19C11.2274 18.0461 10.454 17.2727 9.50013 17.2727H2.59104C1.63713 17.2727 0.86377 18.0461 0.86377 19C0.86377 19.9539 1.63713 20.7273 2.59104 20.7273H9.50013C10.454 20.7273 11.2274 19.9539 11.2274 19ZM35.4092 17.2727H31.9547C31.0008 17.2727 30.2274 18.0461 30.2274 19C30.2274 19.9539 31.0008 20.7273 31.9547 20.7273H35.4092C36.3631 20.7273 37.1365 19.9539 37.1365 19C37.1365 18.0461 36.3631 17.2727 35.4092 17.2727ZM19.8638 10.3636C20.8177 10.3636 21.591 9.59028 21.591 8.63636V1.72727C21.591 0.773357 20.8177 0 19.8638 0C18.9099 0 18.1365 0.773357 18.1365 1.72727V8.63636C18.1365 9.59028 18.9099 10.3636 19.8638 10.3636ZM19.8639 27.6364C18.91 27.6364 18.1366 28.4097 18.1366 29.3636V36.2727C18.1366 37.2266 18.91 38 19.8639 38C20.8178 38 21.5912 37.2266 21.5912 36.2727V29.3636C21.5912 28.4097 20.8178 27.6364 19.8639 27.6364ZM8.8714 5.56493C8.19708 4.89037 7.10337 4.89048 6.4287 5.56493C5.75414 6.23948 5.75414 7.33308 6.4287 8.00764L11.3142 12.8933C11.4744 13.0539 11.6648 13.1813 11.8744 13.2681C12.084 13.355 12.3087 13.3995 12.5355 13.3993C12.9775 13.3993 13.4197 13.2306 13.7568 12.8934C14.4314 12.2188 14.4314 11.1252 13.7568 10.4507L8.8714 5.56493ZM28.4133 25.1069C27.7389 24.4325 26.6452 24.4325 25.9706 25.1069C25.296 25.7815 25.2962 26.8751 25.9706 27.5497L30.8562 32.4351C31.0165 32.5957 31.2069 32.7231 31.4165 32.8099C31.6261 32.8967 31.8508 32.9412 32.0777 32.9409C32.5197 32.9409 32.9618 32.7722 33.299 32.4351C33.9735 31.7605 33.9735 30.6669 33.299 29.9924L28.4133 25.1069ZM11.3142 25.1069L6.42881 29.9924C5.75425 30.6669 5.75425 31.7605 6.42881 32.4351C6.58903 32.5957 6.77942 32.7231 6.98903 32.8099C7.19864 32.8967 7.42335 32.9412 7.65022 32.9409C7.87708 32.9412 8.10177 32.8967 8.31136 32.8099C8.52095 32.7231 8.71132 32.5957 8.87152 32.4351L13.7569 27.5497C14.4315 26.8751 14.4315 25.7815 13.7569 25.1069C13.0824 24.4324 11.9887 24.4324 11.3142 25.1069Z" fill="white" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_2117_955">
                                                    <rect width="38" height="38" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </>
                                )
                            )}
                            <p style={{ fontSize: '14px', margin: 0, color: '#fff' }}>{statementData?.title}</p>
                            <div className='betingDatalist_New'>

                                {statusType !== 'Pending' &&
                                    <div className='downlodButton' onClick={handleGeneratePdf}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path d="M0.75 10.0834H11.25V11.25H0.75V10.0834ZM6.58333 6.68345L10.1248 3.14198L10.9498 3.96694L6 8.91669L1.05025 3.96694L1.87521 3.14198L5.41667 6.68345V0.166687H6.58333V6.68345Z" fill="#8723F5" />
                                        </svg>
                                    </div>
                                }
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <div className="container">
                        <div className="row" ref={pdfTemplateRef}>
                            {!loading ?
                                (
                                    <>
                                        {statementData && (
                                            <>
                                                <div className="col-12 px-0">
                                                    <ul className='pale_Bet'>
                                                        {statementData?.game &&
                                                            <li>
                                                                <p>Game Name :</p>
                                                                <p className='fw-bolder'>{statementData?.game}</p>
                                                            </li>
                                                        }
                                                        {statementData?.total_bidding_amount &&
                                                            <li>
                                                                <p>Total bidding amount :</p>
                                                                <p className='fw-bolder'>{statementData?.total_bidding_amount}</p>
                                                            </li>
                                                        }
                                                        {statementData?.amount &&
                                                            <li>
                                                                <p>Amount :</p>
                                                                <p className='fw-bolder'>{statementData?.amount}</p>
                                                            </li>
                                                        }
                                                        {statementData?.txn_id &&
                                                            <li>
                                                                <p>Txn Id :</p>
                                                                <p className='fw-bolder'>{statementData?.txn_id}</p>
                                                            </li>
                                                        }
                                                        {statementData?.deposite_method &&
                                                            <li>
                                                                <p>Gateway :</p>
                                                                <p className='fw-bolder'>{statementData?.deposite_method}</p>
                                                            </li>
                                                        }
                                                        {statementData?.ref_no &&
                                                            <li>
                                                                <p>Ref. No. :</p>
                                                                <p className='fw-bolder'>{statementData?.ref_no}</p>
                                                            </li>
                                                        }
                                                        {statementData?.bank_name &&
                                                            <li>
                                                                <p>Bank Name :</p>
                                                                <p className='fw-bolder'>{statementData?.bank_name}</p>
                                                            </li>
                                                        }
                                                        {statementData?.account_holder &&
                                                            <li>
                                                                <p>Account Holder Name :</p>
                                                                <p className='fw-bolder'>{statementData?.account_holder}</p>
                                                            </li>
                                                        }
                                                        {statementData?.account_number &&
                                                            <li>
                                                                <p>Account No. :</p>
                                                                <p className='fw-bolder'>{statementData?.account_number}</p>
                                                            </li>
                                                        }
                                                        {statementData?.date &&
                                                            <li>
                                                                <p>Play Date :</p>
                                                                <p className='d-flex fw-bolder'>
                                                                    {statementData?.date}
                                                                </p>
                                                            </li>
                                                        }
                                                        {statementData?.time &&
                                                            <li>
                                                                <p>Date & Time :</p>
                                                                <p className='d-flex fw-bolder'>
                                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none" style={{ marginRight: 5 }}>
                                                                        <path d="M1.22363 7.39388H13.4511V12.8962C13.4511 13.2339 13.1774 13.5076 12.8397 13.5076H1.83501C1.49736 13.5076 1.22363 13.2339 1.22363 12.8962V7.39388ZM10.3942 2.5029H12.8397C13.1774 2.5029 13.4511 2.77662 13.4511 3.11427V6.17114H1.22363V3.11427C1.22363 2.77662 1.49736 2.5029 1.83501 2.5029H4.2805V1.28015H5.50324V2.5029H9.17148V1.28015H10.3942V2.5029Z" fill="url(#paint0_linear_1222_12)" />
                                                                        <path d="M1.22363 7.39388H13.4511V12.8962C13.4511 13.2339 13.1774 13.5076 12.8397 13.5076H1.83501C1.49736 13.5076 1.22363 13.2339 1.22363 12.8962V7.39388ZM10.3942 2.5029H12.8397C13.1774 2.5029 13.4511 2.77662 13.4511 3.11427V6.17114H1.22363V3.11427C1.22363 2.77662 1.49736 2.5029 1.83501 2.5029H4.2805V1.28015H5.50324V2.5029H9.17148V1.28015H10.3942V2.5029Z" fill="url(#paint1_linear_1222_12)" />
                                                                        <defs>
                                                                            <linearGradient id="paint0_linear_1222_12" x1="26.987" y1="0.229679" x2="-2.41744" y2="4.76034" gradientUnits="userSpaceOnUse">
                                                                                <stop stopColor="#00FFDD" />
                                                                                <stop offset="1" stopColor="#6F27FF" />
                                                                            </linearGradient>
                                                                            <linearGradient id="paint1_linear_1222_12" x1="7.33736" y1="1.28015" x2="7.33736" y2="13.5076" gradientUnits="userSpaceOnUse">
                                                                                <stop stopColor="#A200DB" />
                                                                                <stop offset="1" stopColor="#8327F7" />
                                                                            </linearGradient>
                                                                        </defs>
                                                                    </svg> */}
                                                                    {statementData?.time}
                                                                </p>
                                                            </li>
                                                        }
                                                        {statementData?.remark &&
                                                            <li>
                                                                <p>Remark :</p>
                                                                <p className='fw-bolder'>{statementData?.remark}</p>
                                                            </li>
                                                        }
                                                    </ul>
                                                    {statementData?.msg && <p className="text-danger mt-2 text-center w-100" style={{ fontSize: 14 }}>{statementData?.msg}</p>}
                                                    {statementData?.rimg &&
                                                        <div className="screenShortImage text-center">
                                                            <img src={statementData?.rimg} alt="" className="img-fluid" />
                                                        </div>
                                                    }
                                                    {statementData?.rlink &&
                                                        <div>
                                                            <iframe
                                                                className="videoFram"
                                                                src={statementData?.rlink}
                                                                title="YouTube video player"
                                                                frameBorder="1"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"
                                                                allowFullScreen>
                                                            </iframe>
                                                        </div>
                                                    }
                                                </div>
                                                {mainChart &&
                                                    <div className='col-12 px-0'>
                                                        <h5 className='suuccessTital'>Numbers</h5>
                                                        <div className='betNumberData betNumberDataScroll'>
                                                            <div>
                                                                {[...Array(Math.ceil(Object.keys(mainChart).length / 6))].map((key, index1) => (
                                                                    <div key={index1} className='row'>
                                                                        {[...Array(6)].map((element, index) => {
                                                                            return (

                                                                                <React.Fragment key={index}>
                                                                                    {Object.entries(mainChart)[index + index1 * 6] && (
                                                                                        <div className="col-2 text-center px-0" key={index} >
                                                                                            {Object.entries(mainChart)[index + index1 * 6] && (
                                                                                                <React.Fragment key={index}>
                                                                                                    <div className='betingChartCard'>
                                                                                                        <span className="betingChartNumber">{Object.entries(mainChart)[index + index1 * 6][0]}</span>
                                                                                                        <span className="betingChartAmount"> ₹ {Object.entries(mainChart)[index + index1 * 6][1]}</span>
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {innerChart &&
                                                    <div className='col-12 px-0'>
                                                        <h5 className='suuccessTital'>Andar/A</h5>
                                                        <div className='betNumberData betNumberDataScroll'>
                                                            <div>
                                                                {[...Array(Math.ceil(Object.keys(innerChart).length / 6))].map((key, index1) => (
                                                                    <div key={index1} className='row'>
                                                                        {[...Array(6)].map((element, index) => {
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    {Object.entries(innerChart)[index + index1 * 6] && (
                                                                                        <div className="col-2 text-center px-0" key={index} >
                                                                                            {Object.entries(innerChart)[index + index1 * 6] && (
                                                                                                <React.Fragment key={index}>
                                                                                                    <div className='betingChartCard'>
                                                                                                        <span className="betingChartNumber">{Object.entries(innerChart)[index + index1 * 6][0]}</span>
                                                                                                        <span className="betingChartAmount"> ₹ {Object.entries(innerChart)[index + index1 * 6][1]}</span>
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {outerChart &&
                                                    <div className='col-12 px-0'>
                                                        <h5 className='suuccessTital'>Bahar/B</h5>
                                                        <div className='betNumberData betNumberDataScroll'>
                                                            <div>
                                                                {[...Array(Math.ceil(Object.keys(outerChart).length / 6))].map((key, index1) => (
                                                                    <div key={index1} className='row'>
                                                                        {[...Array(6)].map((element, index) => {
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    {Object.entries(outerChart)[index + index1 * 6] && (
                                                                                        <div className="col-2 text-center px-0" key={index} >
                                                                                            {Object.entries(outerChart)[index + index1 * 6] && (
                                                                                                <React.Fragment key={index}>
                                                                                                    <div className='betingChartCard'>
                                                                                                        <span className="betingChartNumber">{Object.entries(outerChart)[index + index1 * 6][0]}</span>
                                                                                                        <span className="betingChartAmount"> ₹ {Object.entries(outerChart)[index + index1 * 6][1]}</span>
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )}
                                    </>
                                )
                                :
                                (
                                    <div className="spinner-box">
                                        <div className="pulse-container">
                                            <div className="pulse-bubble pulse-bubble-1">15151</div>
                                            <div className="pulse-bubble pulse-bubble-2"></div>
                                            <div className="pulse-bubble pulse-bubble-3"></div>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default GamePlay;
