import React, { useEffect, useState, useRef } from 'react';
import Openplay from '../components/openplay';
import Jantri from '../components/Jantri'
import Cross from '../components/cross';
import Loader from '../components/loader';
import AuthService from "../services/auth.service";
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import '../assets/js/home';

function Home(props: any) {
  const divRef = useRef<HTMLDivElement>(null);
  const [gamesList, setGamesList] = useState([]);
  const [gameId, setGameId] = useState('');
  const [gameType, setGamesType] = useState("1");
  const [isErrorClass, setIsErrorClass] = useState(false);
  const [isButtonClick, setIsButtonClick] = useState(false);
  const [isBetSuccess, setIsBetSuccess] = useState();
  const [isPageloading, setIsPageLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const active = document.querySelector('.error-class') as HTMLDivElement;
    if (active) {
      divRef.current?.focus();      
      // $(".custom-select").prop('size', 5);      

    }
  }, [isErrorClass, isButtonClick]);

  useEffect(() => {
    //if(isBetSuccess!==undefined){
      props.setIsPlaceBet(!props.isPlaceBet); 
    //}
    // eslint-disable-next-line
  }, [isBetSuccess]);

  useEffect(() => {  
    AuthService.gameList()
      .then(
        (response) => {
          if (response.status === true) {
            setGamesList(response.games);
          } else {
            if (response.code === 401) {
              localStorage.removeItem("sg_token");
              navigate('/', { state: { message: response?.msg } });
            }
            //alert(response.msg);
          }

        }
      ).catch((error) => {
        const message =
          (error.response &&
            error.response?.data?.msg);
        //alert(message);
        if (error.response?.data?.code === 401) {
          localStorage.removeItem("sg_token");
          navigate('/', { state: { message: message } });
        }
      }).finally(() => {
        setIsPageLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const handleChange = (e: any) => {
    setGameId(e.target.value);
  };

  const handleClick = (value: any) => () => {
    setGamesType(value);

    if(value==="2"){
      $(".leftArrow").removeClass("d-none");
      $(".shareBtnIcon").removeClass("openClass");
      $(".rightArrow").addClass("d-none");
      $(".shareBtnIcon").addClass("shareBtn");  
      
    }else{      
      if(value==="1" && $('.Main_List .betingTabel').length===0){
        $(".leftArrow").addClass("d-none");
        $(".shareBtnIcon").addClass("openClass");
        $(".rightArrow").removeClass("d-none");
        $(".shareBtnIcon").removeClass("shareBtn");

      }else if(value==="3" && $('.cross_list .betingTabel').length===0){
        $(".leftArrow").addClass("d-none");
        $(".shareBtnIcon").addClass("openClass");
        $(".rightArrow").removeClass("d-none");
        $(".shareBtnIcon").removeClass("shareBtn");

      }else{
        $(".leftArrow").removeClass("d-none");
        $(".shareBtnIcon").removeClass("openClass");
        $(".rightArrow").addClass("d-none");
        $(".shareBtnIcon").addClass("shareBtn"); 
      }
      
    }
  };

 
  return (
    <>
      {isPageloading && (<Loader />)}
      <section className="gameMain">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="nav nav-pills justify-content-between gameSelectButton" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" data-game_type="1" onClick={handleClick('1')}>Open Play</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" data-game_type="2" onClick={handleClick('2')}>Jantri</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" data-game_type="3" onClick={handleClick('3')}>Cross</button>
                </li>
              </ul>
            </div>
            <div className="col-12">
              <div className={`gameSelectBox ${isErrorClass ? 'error-class' : ''}`} ref={divRef} tabIndex={0}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="17" viewBox="0 0 24 17" fill="none">
                  <path d="M18.5236 0.208066C16.1188 -0.498825 15.3636 0.840548 12 0.840548C8.63642 0.840548 7.88122 -0.504139 5.47637 0.208066C3.07152 0.914957 1.87177 3.41831 0.736295 8.11674C-0.39918 12.8205 -0.190295 16.2168 1.14871 16.8492C2.48771 17.4817 3.99811 16.0307 5.43888 14.2396C6.60649 12.8364 7.1903 12.7461 12 12.7461C16.8043 12.7461 17.3025 12.7833 18.5611 14.2396C20.0019 16.0307 21.5123 17.4817 22.8513 16.8492C24.1903 16.2168 24.3992 12.8205 23.2637 8.11674C22.1282 3.41831 20.9231 0.920272 18.5236 0.208066ZM5.15501 8.90336C3.99275 8.90336 3.05009 7.96261 3.05009 6.79863C3.05009 5.63996 3.99275 4.6939 5.15501 4.6939C6.31727 4.6939 7.25992 5.63996 7.25992 6.79863C7.25992 7.95729 6.31727 8.90336 5.15501 8.90336ZM14.8762 7.83505C14.3084 7.83505 13.8425 7.37264 13.8425 6.80394C13.8425 6.23524 14.3084 5.77284 14.8762 5.77284C15.4493 5.77284 15.9152 6.23524 15.9152 6.80394C15.9099 7.37264 15.4493 7.83505 14.8762 7.83505ZM17.1364 10.0886C16.5687 10.0886 16.1027 9.62619 16.1027 9.05749C16.1027 8.48347 16.5687 8.02639 17.1364 8.02639C17.7095 8.02639 18.1755 8.48879 18.1755 9.05749C18.1755 9.63151 17.7095 10.0886 17.1364 10.0886ZM17.1364 5.57619C16.5687 5.57619 16.1027 5.11378 16.1027 4.54508C16.1027 3.97638 16.5687 3.51398 17.1364 3.51398C17.7095 3.51398 18.1755 3.97638 18.1755 4.54508C18.1755 5.11378 17.7095 5.57619 17.1364 5.57619ZM19.402 7.83505C18.8289 7.83505 18.3683 7.37264 18.3683 6.80394C18.3683 6.23524 18.8343 5.77284 19.402 5.77284C19.9698 5.77284 20.4357 6.23524 20.4357 6.80394C20.4357 7.37264 19.9751 7.83505 19.402 7.83505Z" fill="white" />
                </svg>

                <select className="form-select custom-select" value={gameId} aria-label="Default select example" onChange={(event) => handleChange(event)}>
                  <option value={''} >Select Game</option>
                  {gamesList.length > 0 && Object.entries(gamesList).map((value, index) => (
                    <option value={gamesList[index]['id']} key={index} disabled={gamesList[index]['game_status'] === 'Close' ? true : false}>{gamesList[index]['name']}</option>
                  ))}
                </select>

                <svg className='arrowSelect' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 13 8" fill="none">
                  <path d="M1.53292 0L6.5 4.94673L11.4671 0L13 1.52664L6.5 8L0 1.52664L1.53292 0Z" fill="white" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="gameMain" style={{ marginBottom: 150 }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <Openplay gameType={gameType} gameId={gameId} setIsErrorClass={setIsErrorClass} setIsButtonClick={setIsButtonClick} isButtonClick={isButtonClick} setGameId={setGameId} setIsBetSuccess={setIsBetSuccess} isBetSuccess={isBetSuccess} />
                </div>
                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                  <Jantri gameType={gameType} gameId={gameId} setIsErrorClass={setIsErrorClass} setIsButtonClick={setIsButtonClick} isButtonClick={isButtonClick} setGameId={setGameId} setIsBetSuccess={setIsBetSuccess} isBetSuccess={isBetSuccess} />
                </div>
                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <Cross gameType={gameType} gameId={gameId} setIsErrorClass={setIsErrorClass} setIsButtonClick={setIsButtonClick} isButtonClick={isButtonClick} setGameId={setGameId} setIsBetSuccess={setIsBetSuccess} isBetSuccess={isBetSuccess} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>      
    </>
  )

}

export default Home;