import axios from "axios";

// const baseUrlName = 'https://apisoft.bashgames.in/';
const baseUrlName = 'https://soft911.net/api/v1/';

const axiosInstance = () => {
  
  const common_axios = axios.create({ 
    baseURL: baseUrlName
  });

  common_axios.defaults.headers.common['Authorization'] = "Bearer " + getToken();
  // common_axios.defaults.headers.post['Content-Type'] = 'application/json';
  common_axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
  common_axios.defaults.headers.post['Cache-Control'] = 'no-cache';
  return common_axios;
}


function getToken() {
  // @ts-ignore
  let localData = localStorage.getItem("sg_token");
  const token = localData ? JSON.parse(localStorage.getItem("sg_token") || "") : '';
  if (token !== null && token.access_token !== '') {
    return token.access_token;

  } else {
    return null;
  }
}

const AuthHeader = {
  axiosInstance,
  baseUrlName
}

export default AuthHeader;
