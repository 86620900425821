import React, { useEffect, useRef, useState } from 'react';
import '../App.css';
import AuthService from "../services/auth.service";
import { Link, useNavigate } from 'react-router-dom';
import { auth } from "../services/firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Loader from '../components/loader';

function SendOTP() {
  const [loading, setLoading] = useState(false);
  const [isPageloading, setIsPageLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [message, setMessage] = useState("");
  const queryParameters = new URLSearchParams(window.location.search)
  const refCode = queryParameters.get("code");
  const [referal] = useState<any | string>(refCode);
  const [isOtpSend, setIsOtpSend] = useState<boolean>(false);
  const [isUserAlreadyRegistered, setIsUserAlreadyRegistered] = useState<boolean>(false);
  const [isAllowedRegister, setIsAllowedRegister] = useState<boolean>(true);
  const [user, addUser] = useState<any>();
  const [counter, setCounter] = useState(20);
  const [countryCode, setCountryCode] = useState('+91');
  const [countryCodeList, setCountryCodeList] = useState<any>();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_mobile: "",
    country_code: "+91",
    refered_by: referal || '',
  });

  /******* start OTP Verify section */
  const [otp, setOtp] = useState<string[]>(Array(6).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));

  const handleChange = (index: number, value: string) => {
    if (isNaN(Number(value))) {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);
    var newArray = newOtp.filter(value => Object.keys(value).length !== 0);
    if (newArray.length === 6) {
      setIsOtpValid(true);
    } else {
      setIsOtpValid(false);
    }
    // Focus on next input field
    if (value !== '' && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyUp = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (e.key === 'Backspace' && value === '' && index > 0) {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      inputRefs.current[index - 1]?.focus();
    }
  };

  const pastefunction = (event: any) => {
    event.preventDefault();
    let data = event.clipboardData.getData('text/plain');
    if (!isNaN(Number(data))) {
      var dataArr = data.split('');
      var dataArr1 = dataArr.slice(0, 6);
      const newOtp = [...otp];
      for (let i = 0; i < 6; i++) {
        newOtp[i] = dataArr[i] ? dataArr[i] : '';
      }
      setOtp(newOtp);
      if (dataArr1.length === 6) {
        setIsOtpValid(true);
      } else {
        setIsOtpValid(false);
      }
      inputRefs.current[Number(dataArr1.length - 1)]?.focus();
    }
  }
  /******* end OTp Verify section ********/

  useEffect(() => {
    AuthService.getLocation().then(
      async (response) => {
        setCountryCode(response.country_calling_code);
        formData.country_code = response.country_calling_code;
        //getCountryCodeList(); 
        // eslint-disable-next-line         
      }
    ).catch((error) => {
      //console.log(error);
    }).finally(() => {
      setIsPageLoading(false);
    });

  }, []);

  /*
  const getCountryCodeList=async ()=>{   
    await AuthService.getCountryCode().then(
      (response) => {            
        if (response.status === true) {
          setCountryCodeList(response.country_code);          
        } else {
          setMessage(response.msg);
        }
        // eslint-disable-next-line         
      }
    ).catch((error) => {
      const message =
        (error.response &&
          error.response?.data?.msg);  
          setMessage(message);   
    }).finally(() => {
      setIsPageLoading(false);
    });
  }
  */

  useEffect(() => {
    if (isOtpSend === true) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);   //for countdown timer
    }
  });


  const checkReferal = async () => {
    let isStatus = '';
    await AuthService.checkReferral(formData.refered_by).then(
      (response) => {

        if (response.status === true) {
          isStatus = 'Valid';
        } else {
          isStatus = 'Invalid';
        }
        setLoading(false);
        // eslint-disable-next-line         
      }
    ).catch((error) => {
      const message =
        (error.response &&
          error.response?.data?.msg);
      setMessage(message);
      isStatus = message;
    }).finally(() => {
      setLoading(false);
    });

    return isStatus
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === 'user_mobile' && (isNaN(e.target.value) || (e.target.value).length>10)) {
      return false
    }
    

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === 'user_mobile' && (e.target.value).length === 10) {
      checkNumberIsExists(e.target.value);
    }
    if (name === 'user_mobile' && (e.target.value).length < 10) {
      setIsUserAlreadyRegistered(false);
      setIsAllowedRegister(true);
      setFormData(prev => ({
        ...prev,
        ['refered_by']: ''
      }));
    }

  }

  const checkNumberIsExists = async (mobile: any) => {

    await AuthService.isNumberAlreadyRegistered(mobile).then(
      (response) => {
        if (response.status === true) {
          setIsUserAlreadyRegistered(false);
          if (response.is_password_set === 1) {
            setIsAllowedRegister(false);
          }

          setFormData(prev => ({
            ...prev,
            ['refered_by']: ''
          }));
        } else {
          setIsUserAlreadyRegistered(true);
          setIsAllowedRegister(true);
        }
        setLoading(false);
        // eslint-disable-next-line         
      }
    ).catch((error) => {
      const message =
        (error.response &&
          error.response?.data?.msg);
      console.log(message);

    }).finally(() => {
      //setLoading(false);
    });
  }


  const validateForm = async () => {
    let isValid = true;
    const newErrors: any = {};

    if (!formData.country_code) {
      newErrors.country_code = "Enter valid country code";
      isValid = false;
    }

    if ((formData.user_mobile.length < 10 || formData.user_mobile.length > 10) && (formData.country_code === '91' || formData.country_code === '+91')) {
      newErrors.user_mobile = "Mobile no. should be 10 digits";
      isValid = false;
    }

    if (!formData.user_mobile) {
      newErrors.user_mobile = "Enter valid mobile no.";
      isValid = false;
    }

    if (formData.refered_by !== '') {
      let isvalidCode = await checkReferal();
      if (isvalidCode !== 'Valid') {
        newErrors.refered_by = "Invalid referral code";
        isValid = false;
      }
    }

    if (!isValid) {
      setLoading(false);
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    if (await validateForm()) {
      otpSend();
    }

  }

  const otpSend = async () => {
    setSending(true);
    //var recaptcha;
    try {
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {
        size: "invisible",
      });

      const confirmation = await signInWithPhoneNumber(
        auth,
        formData.country_code + formData.user_mobile,
        recaptcha
      );
      if (confirmation?.verificationId) {
        setMessage("OTP sent successfully");
        addUser(confirmation);
        setIsOtpSend(true);
        setCounter(20);
      }

    } catch (error: any) {
      switch (error.code) {
        case "auth/too-many-requests":
          setMessage("Too many requests. Please try again later.");
          break;
        case "auth/invalid-phone-number":
          setMessage("The phone number is invalid.");
          break;
        default:
          setMessage(error.code);
          break;
      }
      //recaptcha = "";
      setLoading(false);
    } finally {
      setLoading(false);
      setSending(false);
      setTimeout(() => {
        setMessage('');
      }, 5000);
    }
  }
  const editMobile = () => {
    setOtp(Array(6).fill(''));
    setIsOtpSend(false);
    setMessage("");
  }

  const handleSubmitOTP = async (e: any) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    const otpString = otp.join("");
    if (otpString.length < 6) {
      setMessage('OTP should be 6 digits');
      setLoading(false);
      return;
    }

    try {
      const data = await user.confirm(otpString);
      if (data) {
        let formDataUser = {
          ...formData,
          firebaseRes: JSON.stringify(data),
        }

        await AuthService.createUser(formDataUser).then(
          (response) => {
            if (response.status === true) {
              // eslint-disable-next-line    
              /* setFormData({
                ...formData,
                [`user_mobile`]: '',
                [`refered_by`]: '',
              }); */
              navigate('/home?recordId=' + response.userId);
            } else {
              setMessage(response.msg);
            }
          }
        ).catch((error) => {
          const message =
            (error.response &&
              error.response?.data?.msg);
          setMessage(message);
          setLoading(false);

        }).finally(() => {
          setLoading(false);
        });
      }
    } catch (error: any) {
      switch (error.code) {
        case "auth/invalid-verification-code":
          setMessage("The verification code is invalid.");
          break;
        case "auth/code-expired":
          setMessage("The verification code is expired.");
          break;
        default:
          setMessage("Something went wrong. Please try again later.");
          break;
      }
    
    } finally {
      setLoading(false);
    }
  }

  const handleSelectChange = (e: any) => {
    setCountryCode(e.target.value);
  };

  return (
    <div>
      {isPageloading && (<Loader />)}
      <h1 className="text-white" style={{ margin: 0 }}>SignUp</h1>
      <div className="logo">
        <img src="/logo.png" alt="Logo" className="img-fluid" />
      </div>
      {!isOtpSend ?
        (
          <form className="registerButton" onSubmit={handleSubmit}>
            <div className="registerInput" style={{ padding: 0 }}>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M3.625 7.795C5.065 10.625 7.38 12.94 10.215 14.38L12.415 12.175C12.69 11.9 13.085 11.82 13.43 11.93C14.55 12.3 15.755 12.5 17 12.5C17.555 12.5 18 12.945 18 13.5V17C18 17.555 17.555 18 17 18C7.61 18 0 10.39 0 1C0 0.445 0.45 0 1 0H4.5C5.055 0 5.5 0.445 5.5 1C5.5 2.245 5.7 3.45 6.07 4.57C6.18 4.915 6.1 5.31 5.825 5.585L3.625 7.795Z" fill="url(#paint0_linear_2_136)" />
                <defs>
                  <linearGradient id="paint0_linear_2_136" x1="9" y1="0" x2="9" y2="18" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A200DB" />
                    <stop offset="1" stopColor="#8327F7" />
                  </linearGradient>
                </defs>
              </svg> */}
              {/* <select className="countryCode" aria-label="Default select example" value={countryCode} onChange={(event) => handleSelectChange(event)}>              
                  {countryCodeList && countryCodeList.map((value:any, index:any) => ( 
                    <option  value={'+'+value.country_code} key={index}>{'+'+value.country_code}</option>
                  ))}
                
              </select> */}
              <span className='contryBack'>
                <input type="text" name="country_code" value={formData.country_code} onChange={(e) => handleInputChange(e)} />
              </span>
              {/* <svg className='arrowSelect' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 13 8" fill="none">
                  <path d="M1.53292 0L6.5 4.94673L11.4671 0L13 1.52664L6.5 8L0 1.52664L1.53292 0Z" fill="white" />
                </svg> */}
              <input style={{ paddingLeft: 10 }} type="number" name="user_mobile" id="user_mobile" aria-describedby="user_mobile" placeholder="Enter Mobile Number" autoComplete='off' pattern="^[0-9]*$" maxLength={10} value={formData.user_mobile} onChange={(e) => handleInputChange(e)} />
            </div>
            {errors.country_code && <div className="text-danger text-start pb-2 loginErrors">{errors.country_code}</div>}
            {errors.user_mobile && <div className="text-danger text-start pb-2 loginErrors">{errors.user_mobile}</div>}

            {isUserAlreadyRegistered &&
              <>
                <div className="registerInput">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                    <path d="M18.0391 17.0199C18.0394 17.0206 18.0389 17.0214 18.0382 17.0214H8.63341C8.26651 17.0214 7.96908 16.724 7.96908 16.3571C7.96991 16.0479 7.85319 15.8316 7.53201 15.7915C7.15951 15.7455 7.01548 15.5207 7.01962 15.1519C7.02624 14.514 7.02127 13.8752 7.02458 13.2373C7.0279 12.6419 7.2456 12.1386 7.74227 11.8328C8.40201 11.4274 9.09569 11.079 9.77198 10.7017C9.9723 10.5902 10.1072 10.6404 10.2769 10.7997C11.9176 12.3404 14.1228 12.3208 15.7701 10.7818C15.8702 10.6881 16.1086 10.6302 16.2187 10.6872C16.929 11.0569 17.635 11.4393 18.3147 11.8643C18.7666 12.1479 18.9694 12.6266 18.9827 13.1666C18.9992 13.8471 19.015 14.5293 18.9727 15.2073C18.9611 15.398 18.7691 15.7089 18.6209 15.7353C18.1102 15.8273 17.9984 16.1297 18.0373 16.5845C18.0481 16.7105 18.039 16.8382 18.039 17.0195C18.039 17.0196 18.039 17.0198 18.0391 17.0199Z" fill="url(#paint0_linear_2_233)" />
                    <path d="M9.50118 5.98306C9.50118 5.98318 9.50115 5.98331 9.5011 5.98342C9.15716 6.72838 9.02095 7.45694 9.07087 8.17011C9.1334 9.06355 8.88297 10.0215 8.10711 10.4689C7.36047 10.8995 6.39957 10.7296 5.85818 10.059C5.79201 9.977 5.7257 9.89477 5.65946 9.81253C4.90866 8.88076 4.21498 8.88331 3.45839 9.82616C2.85329 10.5808 2.26391 11.349 1.63563 12.0823C1.59776 12.1263 1.54554 12.1573 1.48381 12.1817C1.2435 12.2769 0.946236 12.0617 0.963469 11.8038C0.994925 11.3405 0.908836 11.0049 0.360018 10.9334C0.224263 10.9155 0.0338737 10.6114 0.0239404 10.4309C-0.0124819 9.72395 -6.52046e-05 9.01277 0.0206293 8.305C0.0371848 7.76076 0.271446 7.30339 0.729208 7.02829C1.4055 6.62203 2.10084 6.24983 2.80197 5.88956C2.90544 5.83675 3.1248 5.88445 3.21668 5.96962C4.90038 7.52825 7.10145 7.54528 8.72969 5.96621C9.0316 5.67345 9.21449 5.90794 9.50053 5.98223C9.50091 5.98233 9.50118 5.98267 9.50118 5.98306Z" fill="url(#paint1_linear_2_233)" />
                    <path d="M12.9879 4.81471C14.6782 4.8096 15.9911 6.17063 15.9894 7.926C15.9878 9.56639 14.631 10.9547 13.0251 10.9581C11.377 10.9615 10.0286 9.58257 10.0145 7.8766C10.0004 6.21322 11.3539 4.81897 12.9879 4.81471Z" fill="url(#paint2_linear_2_233)" />
                    <path d="M6.00135 6.14252C4.33751 6.14508 3.01721 4.7849 3.0081 3.06019C2.999 1.39169 4.3491 0.00170498 5.98148 1.56205e-06C7.65359 -0.00170185 8.98797 1.38999 8.98218 3.12833C8.97721 4.76361 7.61552 6.13997 6.00135 6.14252Z" fill="url(#paint3_linear_2_233)" />
                    <path d="M9.16768 17.9987C9.16768 17.9985 9.16751 17.9983 9.16729 17.9983C7.68654 18.0255 6.28111 17.7862 5.09004 16.8085C4.05069 15.9547 3.6169 14.8143 3.47428 13.5184C3.43321 13.1452 3.12857 12.8561 2.75323 12.8472C2.49082 12.8412 2.36583 12.7314 2.56118 12.4852C3.15304 11.7374 3.75649 10.9998 4.35415 10.2571C4.51308 10.0596 4.64884 10.1021 4.79122 10.2793C5.37894 11.0118 5.97246 11.74 6.55852 12.4741C6.74146 12.7033 6.67607 12.8378 6.38966 12.8472C5.99879 12.8594 5.67198 13.1566 5.68633 13.5474C5.72126 14.4993 5.93604 15.3882 6.42773 16.214C7.05428 17.2666 8.03005 17.7623 9.16722 17.9991C9.16746 17.9991 9.16768 17.9989 9.16768 17.9987Z" fill="url(#paint4_linear_2_233)" />
                    <defs>
                      <linearGradient id="paint0_linear_2_233" x1="13.0097" y1="10.6417" x2="13.0097" y2="17.0214" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A200DB" />
                        <stop offset="1" stopColor="#8327F7" />
                      </linearGradient>
                      <linearGradient id="paint1_linear_2_233" x1="4.75095" y1="5.82294" x2="4.75095" y2="12.3191" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A200DB" />
                        <stop offset="1" stopColor="#8327F7" />
                      </linearGradient>
                      <linearGradient id="paint2_linear_2_233" x1="13.0019" y1="4.8147" x2="13.0019" y2="10.9581" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A200DB" />
                        <stop offset="1" stopColor="#8327F7" />
                      </linearGradient>
                      <linearGradient id="paint3_linear_2_233" x1="5.99513" y1="0" x2="5.99513" y2="6.14252" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A200DB" />
                        <stop offset="1" stopColor="#8327F7" />
                      </linearGradient>
                      <linearGradient id="paint4_linear_2_233" x1="5.81675" y1="10.1266" x2="5.81675" y2="18.0004" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A200DB" />
                        <stop offset="1" stopColor="#8327F7" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <input type="text" name="refered_by" id="refered_by" autoComplete='off' aria-describedby="refered_by" placeholder="Referral Code (optional) " value={formData.refered_by} maxLength={10} onChange={(e) => handleInputChange(e)} />
                </div>
                {errors.refered_by && <div className="text-danger text-start pb-2 loginErrors">{errors.refered_by}</div>}
              </>
            }
            {message && (
              <div className='d-block text-danger' role="alert">
                {message}
              </div>
            )}

            {!isAllowedRegister &&
              <div className='d-block text-danger' role="alert">
                User already registered. <Link to="/login" style={{background:"none", color:"#fff"}}>Click here </Link> to login.
              </div>

            }

            <button type="submit" className="btn btn-primary login-btn" disabled={loading || !isAllowedRegister} >
              {loading && (
                <span className="loader-spin"></span>
              )}
              <span>Continue</span>
            </button>

            <div className='changePassword'>
              <Link to="/login">Already have an account? Login</Link>
            </div>
            <div id="recaptcha"></div>
          </form>
        ) :
        (
          <>
            <h4 style={{ paddingTop: '10px', margin: 0 }}>OTP Verification</h4>
            <h2>Enter 6 digits OTP send to </h2>
            <h3>
              {formData.country_code} {formData.user_mobile}
              <svg style={{ margin: '4px', cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="15.121px" height="15.121px" viewBox="0 0 23 23" fill="none" onClick={editMobile}>
                <path d="M10 3.12134H3C2.46957 3.12134 1.96086 3.33205 1.58579 3.70712C1.21071 4.0822 1 4.5909 1 5.12134V19.1213C1 19.6518 1.21071 20.1605 1.58579 20.5356C1.96086 20.9106 2.46957 21.1213 3 21.1213H17C17.5304 21.1213 18.0391 20.9106 18.4142 20.5356C18.7893 20.1605 19 19.6518 19 19.1213V12.1213" stroke="#A004DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.5 1.62132C17.8978 1.2235 18.4374 1 19 1C19.5626 1 20.1022 1.2235 20.5 1.62132C20.8978 2.01915 21.1213 2.55871 21.1213 3.12132C21.1213 3.68393 20.8978 4.2235 20.5 4.62132L11 14.1213L7 15.1213L8 11.1213L17.5 1.62132Z" stroke="#9F04DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </h3>
            <div className="otp-input-container">
              <form className="otpInput otpInput_Error pt-0" onSubmit={handleSubmitOTP}>
                <div>
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      pattern="\d"
                      inputMode="numeric"
                      autoComplete='off'
                      id={`digit${index + 1}`}
                      value={digit}
                      onChange={(e) => handleChange(index, e.target.value)}
                      onKeyUp={(e) => handleKeyUp(index, e)}
                      ref={(ref) => (inputRefs.current[index] = ref)}
                      onPaste={(e) => pastefunction(e)}
                    />
                  ))}
                </div>

                <div className='errors_Otp'>
                  <p>
                    {message && (
                      <>
                        <svg width="16px" height="16px" style={{ marginRight: 3, marginBottom: 3 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"></path></svg>
                        {message}
                      </>
                    )}
                  </p>
                </div>

                <button type="submit" className="btn btn-primary login-btn mt-0" disabled={loading || !isOtpValid}  >
                  {loading && (
                    <span className="loader-spin"></span>
                  )}
                  <span>Verify OTP</span>
                </button>
                <div className='errors_Otp'>
                  {counter > 0 ? (
                    <p>
                      Resend OTP
                      <svg style={{ marginRight: 3, marginLeft: 3 }} xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM13 12H17V14H11V7H13V12Z"></path></svg>
                      <b>00:{counter < 10 ? '0' + counter : counter}</b>
                    </p>
                  ) : (
                    <p style={{ cursor: 'pointer' }} onClick={otpSend}>{sending ? 'Sending...' : 'Resend'}</p>
                  )}
                </div>
                <div id="recaptcha"></div>
              </form>

            </div>
          </>
        )}
    </div>

  )
}
export default SendOTP;