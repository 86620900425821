import React from "react";
import { Link } from 'react-router-dom';

function Failed(props: any) {

  return (
    <div className="col-12 failedNew">
      <div className="paymentContainer">
        <div className="Success">
          <div className="addPayment">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path d="M20 0C16.0444 0 12.1776 1.17298 8.8886 3.37061C5.59962 5.56823 3.03617 8.69181 1.52242 12.3463C0.00866568 16.0008 -0.387401 20.0222 0.384303 23.9018C1.15601 27.7814 3.06082 31.3451 5.85787 34.1421C8.65492 36.9392 12.2186 38.844 16.0982 39.6157C19.9778 40.3874 23.9991 39.9913 27.6537 38.4776C31.3082 36.9638 34.4318 34.4004 36.6294 31.1114C38.827 27.8224 40 23.9556 40 20C40 17.3736 39.4827 14.7728 38.4776 12.3463C37.4725 9.91982 35.9993 7.71503 34.1421 5.85786C32.285 4.00069 30.0802 2.5275 27.6537 1.52241C25.2272 0.517315 22.6264 0 20 0ZM26.42 23.58C26.6075 23.7659 26.7562 23.9871 26.8578 24.2308C26.9593 24.4746 27.0116 24.736 27.0116 25C27.0116 25.264 26.9593 25.5254 26.8578 25.7691C26.7562 26.0129 26.6075 26.2341 26.42 26.42C26.2341 26.6074 26.0129 26.7562 25.7692 26.8578C25.5254 26.9593 25.264 27.0116 25 27.0116C24.736 27.0116 24.4746 26.9593 24.2308 26.8578C23.9871 26.7562 23.7659 26.6074 23.58 26.42L20 22.82L16.42 26.42C16.2341 26.6074 16.0129 26.7562 15.7692 26.8578C15.5254 26.9593 15.264 27.0116 15 27.0116C14.736 27.0116 14.4746 26.9593 14.2309 26.8578C13.9871 26.7562 13.7659 26.6074 13.58 26.42C13.3925 26.2341 13.2438 26.0129 13.1422 25.7691C13.0407 25.5254 12.9884 25.264 12.9884 25C12.9884 24.736 13.0407 24.4746 13.1422 24.2308C13.2438 23.9871 13.3925 23.7659 13.58 23.58L17.18 20L13.58 16.42C13.2034 16.0434 12.9918 15.5326 12.9918 15C12.9918 14.4674 13.2034 13.9566 13.58 13.58C13.9566 13.2034 14.4674 12.9918 15 12.9918C15.5326 12.9918 16.0434 13.2034 16.42 13.58L20 17.18L23.58 13.58C23.9566 13.2034 24.4674 12.9918 25 12.9918C25.5326 12.9918 26.0434 13.2034 26.42 13.58C26.7966 13.9566 27.0082 14.4674 27.0082 15C27.0082 15.5326 26.7966 16.0434 26.42 16.42L22.82 20L26.42 23.58Z" fill="#fff" />
            </svg>
            <p className="failedHeding">Failed</p>
            {props.txnStatusRes?.amount && <p className="pendingPyment">₹{props.txnStatusRes?.amount}</p>}
            {props.txnStatusRes?.remark && <p className="warningPayment">{props.txnStatusRes?.remark}</p>}

            {(props.txnStatusRes?.reject_youtube_link || props.txnStatusRes?.rimg) &&

              <div className="focusIcon bounce2">
                <svg width="35" height="48" viewBox="0 0 35 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.7375 18.8851C16.1721 19.3197 16.8767 19.3197 17.3113 18.8851L24.3933 11.8031C24.8279 11.3685 24.8279 10.6639 24.3933 10.2293C23.9587 9.79475 23.2541 9.79475 22.8195 10.2293L16.5244 16.5244L10.2293 10.2293C9.79475 9.79475 9.09015 9.79475 8.65556 10.2293C8.22098 10.6639 8.22098 11.3685 8.65556 11.8031L15.7375 18.8851ZM15.4116 16.5244L15.4116 18.0982L17.6372 18.0982L17.6372 16.5244L15.4116 16.5244Z" fill="white" />
                  <path d="M15.7375 29.9017C16.1721 30.3363 16.8767 30.3363 17.3113 29.9017L24.3933 22.8197C24.8279 22.3851 24.8279 21.6805 24.3933 21.2459C23.9587 20.8113 23.2541 20.8113 22.8195 21.2459L16.5244 27.541L10.2293 21.2459C9.79475 20.8113 9.09015 20.8113 8.65556 21.2459C8.22098 21.6805 8.22098 22.3851 8.65556 22.8197L15.7375 29.9017ZM15.4116 27.541L15.4116 29.1148L17.6372 29.1148L17.6372 27.541L15.4116 27.541Z" fill="white" />
                </svg>
              </div>
            }
            {props.txnStatusRes?.reject_youtube_link &&
              <iframe
                className="videoFram"
                src={props.txnStatusRes?.reject_youtube_link}
                title="YouTube video player"
                frameBorder="1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen></iframe>
            }

            {props.txnStatusRes?.rimg &&
              <div className="screenShortImage">
                <img src={props.txnStatusRes?.rimg} alt="" className="img-fluid" />
              </div>
            }

            <br />
            <div className="btn-back">
              <Link to="/home">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
export default Failed;