import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom"
import Login from '../pages/login';
import Password from '../pages/set-password';
// import Register from '../pages/register';
import ChangePassword from '../components/change-password';
import Forgot from '../pages/forgot-password';
import Home from '../pages/home';
import Help from '../pages/help';
// import Help from '../pages/chat';
import Result from '../pages/result';
import Wallet from '../pages/wallet';
// import Payment from '../pages/payment';
// import Success from '../pages/success';
// import Pending from '../pages/pending';
// import Failed from '../pages/failed';
import Profile from '../pages/profile';
import ReferEarn from '../pages/refer-and-earn';
import Layout from '../layout/layout';
import ProtectedLayout from '../layout/protected-layout';
import Passbooks from "../pages/passbook";
import Withdrawal from "../pages/withdrawal";
import AddBank from "../pages/add-bank";
import Referral from "../pages/referral";
import SendOTP from "../pages/send-otp";
import Gateways from "../components/gateways";
import GatewaysDetails from "../components/gateways-details";

export const AppRouter = (props: any) => {
    const [isPlaceBet, setIsPlaceBet] = useState(false);
    var today = new Date(),
    date = (today.getMonth() + 1) +''+ today.getDate();
    return (
        <Routes>
            <Route element={<ProtectedLayout {...props} isPlaceBet={isPlaceBet} />}>
                <Route path='change-password' element={<ChangePassword />} />
                <Route path='home' element={<Home {...props} setIsPlaceBet={setIsPlaceBet} isPlaceBet={isPlaceBet} />} />
                <Route path='passbook' element={<Passbooks title="Home" />} />                
                <Route path='wallet' element={<Wallet />} />
                <Route path='gateways' element={<Gateways />} />
                <Route path='gateways-details' element={<GatewaysDetails />} />
                {/* <Route path='payment' element={<Payment />} /> */}
                {/* <Route path='success' element={<Success />} />
                <Route path='pending' element={<Pending />} />
                <Route path='failed' element={<Failed />} /> */}
                <Route path='profile' element={<Profile />} />
                <Route path='refer-and-earn' element={<ReferEarn />} />
                <Route path='withdrawal' element={<Withdrawal />} />
                <Route path='add-bank' element={<AddBank />} />
                <Route path='referral' element={<Referral />} />
                <Route path='*' element={<Navigate to='/home' replace />} />
            </Route>

            <Route element={<Layout />}>
                {/* <Route path='/' element={<Login />} /> */}
                <Route path='/' element={<Login />} />
                <Route path='register' element={<SendOTP />} />
                
                {/* <Route path={'lgn/'+date} element={<Login />} /> */}
                <Route path={'login'} element={<Login />} />
                {/* <Route path='signup' element={<Register />} /> */}
                <Route path='forgot-password' element={<Forgot />} />
                <Route path='*' element={<Navigate to='/' replace />} />
            </Route>
            <Route path='help' element={<Help />} />
            <Route path='satta-king-online-result' element={<Result />} />
            <Route path='set-password' element={<Password />} />
            {/* <Route path="result" element={<Navigate replace to="/satta-king-online-result" />} /> */}
        </Routes>
    )
}