import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthService from "../services/auth.service";
import Helpers from '../services/helpers';
import { Modal } from "react-bootstrap";
import Loader from './loader';
import SetPassword from '../pages/set-password';
// import '../assets/js/custom';

function Header(props: any) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isShow, invokeModal] = useState(false);
  const [isAppButton, setIsAppButton] = useState(false);

  const { pathname } = location;
  const splitLocation = pathname.split("/");
  let pageName = splitLocation[1];
  let pageTitle = pageName.replace(/[^a-zA-Z ]/g, " ");
  const [wallet, setWallet] = useState("0.00");
  const [userId, setUserid] = useState("");
  const [uuid, setUuId] = useState("");
  const [appUrl, setAppUrl] = useState("");

  useEffect(() => {    
    const token = Helpers.getTokenData();    
    let isPwdSet = localStorage.getItem("is_password_set");
    if (token?.uid && isPwdSet!=="1") {
      AuthService.isPasswordSet()
        .then(
          (response) => {           
            if (response.status === true && response?.is_password_set === 0) {
              navigate("/set-password", { replace: true});          
            }else{
              localStorage.setItem("is_password_set", response?.is_password_set); 
            }
          }
        )
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const token = Helpers.getTokenData();
    setUserid(token?.uid);
    setUuId(token?.uuid);
    //if (props.isBet !== undefined) {
    if (token?.uid) {
      AuthService.wallet()
        .then(
          (response) => {
            if (response.status === true) {
              setWallet(response.wallet);
              setAppUrl(response.app_url);
              setIsAppButton(response.show_icon);
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response?.data?.msg);
            alert(message);
            if (error.response?.data?.code === 401) {
              localStorage.removeItem("sg_token");
              navigate('/', { replace:true });
            }
          }
        )
    }
    // eslint-disable-next-line
  }, [props.isBet]);

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    //alert('Complete Cache Cleared')      
  };


  useEffect(() => {
    //clearCacheData(); 
  }, [pageName]);

  function handleLogoutClick() {
    setLoading(true);
    AuthService.logout()
      .then(
        (response) => {
          if (response.status === true) {
            //setMessage(response.msg);
            navigate('/');
          } else {
            if (response.code === 401) {
              localStorage.removeItem("sg_token");
              navigate('/', { state: { message: response?.msg } });
            }
            // setMessage(response.msg);
          }
        }
      ).catch((error) => {
        const message =
          (error.response &&
            error.response?.data?.msg);
        // setMessage(message);
        alert(message);
        if (error.response?.data?.code === 401) {
          localStorage.removeItem("sg_token");
          navigate('/', { state: { message: message } });
        }
      }).finally(() => {
        setLoading(false);
      });
  }

  const initModal = () => {
    return invokeModal(!isShow);
  }

  return (
    <section className="navBar">
      {loading && (<Loader />)}
      <div className="container position-relative">
        <div className="row">
          {/* <div className="col-6">
            <div className="logoHead">
              <img src="./logoMain.png" alt="" className='img-fluid' />
            </div>
            {uuid &&
            <div className='userId'>
              <h6>
                <svg style={{ marginRight: 2 }} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <path d="M8.33366 9.16669H1.66699V8.33335C1.66699 7.18277 2.59973 6.25002 3.75033 6.25002H6.25033C7.40091 6.25002 8.33366 7.18277 8.33366 8.33335V9.16669ZM5.00033 5.41669C3.61961 5.41669 2.50033 4.2974 2.50033 2.91669C2.50033 1.53597 3.61961 0.416687 5.00033 0.416687C6.38103 0.416687 7.50033 1.53597 7.50033 2.91669C7.50033 4.2974 6.38103 5.41669 5.00033 5.41669Z" fill="white" />
                </svg>
                {uuid}1235</h6>
            </div>
             }
          </div> */}
          {/* <div className="col-6 text-end">
            {pageTitle !== 'home' ?
              (
                pageTitle !== 'change password' && <h1 className="headHeding text-capitalize">{pageTitle === "gateways details" ? 'Details' : pageTitle}</h1>
              ) : (
            <button className="addMoneyBtn">
              <Link to="/wallet" className="addMoneyMain">
                <p className="rupee">₹</p>
                <p className="money">{wallet}</p>
                <img src="./addMoneyIcon.png" alt="addMoney" />
              </Link>
            </button>
             )}
          </div> */}
          <div className="col-4 px-0">
            <Link to="/" style={{ textDecoration: 'none' }}>
              <h1 className='mainLogoApp'>
                <img src="./mainLogo.png" alt="" className='img-fluid' />
                <span>Soft911</span>
              </h1>
            </Link>
          </div>
          {uuid &&
            <div className="col-4 text-center px-0">
              {/* {pageTitle !== 'home' ?
              (
                pageTitle !== 'change password' && <h1 className="headHeding text-capitalize">{pageTitle === "gateways details" ? 'Details' : pageTitle}</h1>
              ) : ( */}
              <button className="addMoneyBtn">
                <Link to="/wallet" className="addMoneyMain">
                  <p className="rupee">₹</p>
                  <p className="money">{wallet}</p>
                  <img src="./addMoneyIcon.png" alt="addMoney" />
                </Link>
              </button>
              {/* )} */}
            </div>
          }
          <div className="col-4 text-end px-0">
            {uuid &&
              <div className='userId'>
                <Link to="/profile" style={{ textDecoration: 'none' }}>
                  <h6>
                    <svg style={{ marginRight: 4 }} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                      <path d="M8.33366 9.16669H1.66699V8.33335C1.66699 7.18277 2.59973 6.25002 3.75033 6.25002H6.25033C7.40091 6.25002 8.33366 7.18277 8.33366 8.33335V9.16669ZM5.00033 5.41669C3.61961 5.41669 2.50033 4.2974 2.50033 2.91669C2.50033 1.53597 3.61961 0.416687 5.00033 0.416687C6.38103 0.416687 7.50033 1.53597 7.50033 2.91669C7.50033 4.2974 6.38103 5.41669 5.00033 5.41669Z" fill="white" />
                    </svg>
                    <span>
                      {uuid}</span>
                  </h6>
                </Link>
              </div>
            }
          </div>
        </div>
      </div>
      <Modal
        show={isShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=""
        backdrop="static"
        onHide={initModal}
      >
        <Modal.Body>
          <div className="col-12">
            <div className="text-center areYouSure">
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2585_867)">
                  <path d="M15.5 0C6.94009 0 0 6.94009 0 15.5C0 24.0609 6.94009 31 15.5 31C24.0609 31 31 24.0619 31 15.5C31 6.94009 24.0608 0 15.5 0ZM15.5 28.0937C8.54441 28.0937 2.9063 22.4556 2.9063 15.5C2.9063 8.54441 8.54441 2.9063 15.5 2.9063C22.4556 2.9063 28.0937 8.54441 28.0937 15.5C28.0937 22.4556 22.4556 28.0937 15.5 28.0937ZM15.501 6.79769C14.3908 6.79769 13.5508 7.37704 13.5508 8.31283V16.8959C13.5508 17.8327 14.3907 18.4101 15.501 18.4101C16.584 18.4101 17.4511 17.8085 17.4511 16.8959V8.31283C17.451 7.39926 16.584 6.79769 15.501 6.79769ZM15.501 20.3438C14.4344 20.3438 13.5663 21.2118 13.5663 22.2793C13.5663 23.3449 14.4344 24.2129 15.501 24.2129C16.5676 24.2129 17.4346 23.3449 17.4346 22.2793C17.4345 21.2117 16.5676 20.3438 15.501 20.3438Z" fill="#EC4A4A" />
                </g>
                <defs>
                  <clipPath id="clip0_2585_867">
                    <rect width="31" height="31" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Are you sure ? </p>
              <button onClick={initModal} className="noCancel">No</button>
              <button onClick={handleLogoutClick} className="yesCancel">Yes, Logout</button>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </section>
  )
}
export default Header;