import React, { useEffect, useRef, useState } from 'react';
import AuthService from "../services/auth.service";
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { Modal } from 'react-bootstrap';
import Moment from 'moment';
import Helpers from '../services/helpers';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Loader from '../components/loader';

interface data {
    [key: string]: any[];
}

function Cross(props: any) {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [betData, setBetData] = useState<any>({});
    const [allData, setAllData] = useState<data>({});
    const [isShow, invokeModal] = useState(false);
    const [isCancel, isCancelShow] = useState(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const navigate = useNavigate();
    const [isPdfGenerating, setIsPdfGenerating] = useState<boolean>(false);
    const pdfTemplateRef = useRef(null);
    var timerId: any = undefined;

    const handleCancel = () => {
        isCancelShow(false);
        setLoading(false);
        setIsFormValid(false);
    };

    useEffect(() => {
        if (isCancel && isFormValid) {
            timerId = setTimeout(() => {
                isCancelShow(false);
                placeBet();
            }, 3000);
        }

        return () => {
            clearTimeout(timerId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCancel]);


    const handleSubmit = (event: any) => {
        event.preventDefault();
        setMessage('');
        setLoading(true);
        props.setIsErrorClass(false);
        props.setIsButtonClick(!props.isButtonClick);

        if (!props.gameId) {
            setMessage("Please select game");
            props.setIsErrorClass(true);
            setLoading(false);
            return false;
        }

        let gameAmt = $('.t_cross_amount').text();
        let amt = gameAmt.match(/\d+/);
        if (isNaN(Number(amt)) || Number(amt) <= 0) {
            setMessage("Game amount should be greater than 0");
            setLoading(false);
            return false;
        }

        let crossNumber = $('#cross_number').val();
        if (!crossNumber) {
            setMessage("Please Enter numbers");
            setLoading(false);
            return false;
        }

        let crossAmount = $('.cross_amount').val();
        if (crossAmount === '') {
            setMessage("Please Enter Amount");
            setLoading(false);
            return false;
        }
        isCancelShow(true);
        setIsFormValid(true);

    };

    function placeBet() {
        const token = Helpers.getTokenData();
        const form = document.getElementById('cross') as HTMLFormElement;
        const formData = new FormData(form);
        formData.append('play_type', props.gameType);
        formData.append('game', props.gameId);
        formData.append('userId', token?.uid);
        AuthService.placeBet(formData).then(
            (response) => {

                if (response.status === true) {
                    setBetData(response);
                    setAllData(JSON.parse(response.alldata));
                    props.setIsBetSuccess(!props.isBetSuccess);
                    initModal();
                } else {
                    setMessage(response.msg);
                    if (response.code === 401) {
                        localStorage.removeItem("sg_token");
                        navigate('/', { state: { message: response?.msg } });
                    }
                }
                setLoading(false);
            }
        ).catch((error) => {
            const message =
                (error.response &&
                    error.response?.data?.msg);
            setMessage(message);
            if (error.response?.data?.code === 401) {
                localStorage.removeItem("sg_token");
                navigate('/', { state: { message: message } });
            }
        }).finally(() => {
            setLoading(false);
        });
    }
    const initModal = () => {
        if (isShow === true) {
            props.setGameId('');
            $('.cross_list').html('');
            $('.t_cross_amount').text('Rs. 0');
            $('#cross_number').val('');
            $('.cross_amount').val('');
            $('#withJoda').prop('checked', false);
        }

        return invokeModal(!isShow);

    }

    const handleGeneratePdf = async () => {
        setIsPdfGenerating(true);
        $(".downlodButton").addClass('d-none');
        $(".betNumberData").removeClass('betNumberDataScroll');

        let fileName = Date.now();
        try {
            const input = pdfTemplateRef.current as any as HTMLElement;
            const pdf = new jsPDF({
                orientation: "portrait",
                unit: "px",
                format: "a4"
            });

            const canvas = await html2canvas(input);
            const inputWidth = pdf.internal.pageSize.getWidth();
            const inputHeight = (canvas.height * inputWidth) / canvas.width;

            let height = pdf.internal.pageSize.height;
            let pageHeightInPixels = inputHeight;
            let pages = pageHeightInPixels / height;

            const roundOff = Number(pages.toString().split('.')[1].substring(0, 1));
            const pageNo = (roundOff > 0 ? pages + 1 : pages);
            let pageCount = pages < 1 ? 1 : Math.trunc(pageNo);

            html2canvas(input)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');

                    for (let i = 1; i <= pageCount; i++) {
                        let pdfStartingHeight = height * (i - 1);
                        pdf.addImage(imgData, 'PNG', 20, -pdfStartingHeight, inputWidth * 0.8, inputHeight * 0.8);
                        if (i < pageCount) {
                            pdf.addPage();
                        }
                    }
                    pdf.save(fileName + '.pdf');

                    setTimeout(() => {
                        $(".downlodButton").removeClass('d-none');
                        $(".betNumberData").addClass('betNumberDataScroll');
                        setIsPdfGenerating(false);
                    }, 1000);
                });

        } catch (error) {
            console.error("error generating pdf : ", error);
        }

    };

    return (
        <>
            <section className="openPlayContainer">
                <div className="row">
                    <div className="col-8 pb-2">
                        <div className="betingInputChip position-relative">
                            <div className="betingInputBox">
                                <input style={{ width: '100%' }} type="number" placeholder="Numbers" id="cross_number" className='numClass' onKeyDown={(e) => Helpers.keyDownFunction(e)} />
                            </div>
                            <div className="betingChipCross">
                                <label>
                                    <p>Without Joda</p>
                                    <input type="checkbox" className='checkbox-round' id="withJoda" />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 px-0">
                        <span className="castomDvider">
                            <p>=</p>
                        </span>
                    </div>
                    <div className="col-3">
                        <div className="betingInput">
                            <div className="betingInputBox">
                                <input type="number" placeholder="Amount" className="cross_amount" onKeyDown={(e) => Helpers.keyDownFunction(e)} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <form className='form-class' onSubmit={handleSubmit} id="cross">
                <section className="openPlayContainer">
                    <div className="row">
                        <div className="col-12 cross_list">
                        </div>
                    </div>
                </section>
                <section className="placeBet">
                    <div className="container-fluid">
                        <div className="row betingButton">
                            {message &&
                                <div className='col-12 text-center'>
                                    <span className='text-danger'>
                                        {message}
                                    </span>
                                </div>
                            }
                            <div className="col-12">
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <p className='t_cross_amount'>Rs. 0</p>
                                    </span>
                                    <div>
                                        {isCancel && isFormValid ? (
                                            <div className='CancelButton' onClick={handleCancel}><p>Cancel</p></div>
                                        ) : (
                                            <button type="submit" disabled={loading}>
                                                {loading && (
                                                    <span className="loader-spin"></span>
                                                )}
                                                <span>Place Bet</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>


            <Modal id="betDetails" show={isShow} onHide={initModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                {isPdfGenerating && (<Loader />)}
                <Modal.Header closeButton className='placeBetModelHeader'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='modelHeader'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <g clipPath="url(#clip0_1177_487)">
                                    <path d="M20.187 -0.000244141C9.39877 -0.000244141 0.623047 8.77548 0.623047 19.5637C0.623047 30.3519 9.39877 39.1276 20.187 39.1276C30.9752 39.1276 39.7509 30.3504 39.7509 19.5637C39.7509 8.77701 30.9752 -0.000244141 20.187 -0.000244141ZM20.187 36.0968C11.0718 36.0968 3.65385 28.6804 3.65385 19.5637C3.65385 10.447 11.0718 3.03056 20.187 3.03056C29.3037 3.03056 36.7201 10.447 36.7201 19.5637C36.7201 28.6804 29.3022 36.0968 20.187 36.0968Z" fill="white" />
                                    <path d="M29.1638 12.7598C28.547 12.1991 27.5878 12.2431 27.0241 12.8628L17.78 23.0419L13.3141 18.5018C12.7246 17.9047 11.7669 17.8956 11.1713 18.4836C10.5742 19.07 10.5652 20.0293 11.1531 20.6264L16.7435 26.3092C16.8844 26.4527 17.0525 26.5667 17.2381 26.6445C17.4236 26.7223 17.6227 26.7623 17.8239 26.7623C17.833 26.7623 17.8436 26.7623 17.8527 26.7638C18.0591 26.7597 18.2624 26.7134 18.4503 26.628C18.6382 26.5425 18.8066 26.4196 18.9453 26.2667L29.2668 14.9012C29.8291 14.2798 29.7836 13.3221 29.1638 12.7598Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1177_487">
                                        <rect width="39.1279" height="39.1279" fill="white" transform="translate(0.620117)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            {/* <h1>Success!</h1> */}
                            <h6>{betData && betData?.msg}</h6>
                            <div className='betingDatalist_New'>
                                <div className='downlodButton' onClick={handleGeneratePdf}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path d="M0.75 10.0834H11.25V11.25H0.75V10.0834ZM6.58333 6.68345L10.1248 3.14198L10.9498 3.96694L6 8.91669L1.05025 3.96694L1.87521 3.14198L5.41667 6.68345V0.166687H6.58333V6.68345Z" fill="#8723F5" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <>
                        <div className="container">
                            <div className="row" ref={pdfTemplateRef}>
                                <div className="col-12 px-0">
                                    <ul className='pale_Bet'>
                                        {betData && betData?.name &&
                                            <li>
                                                <p>Game Name :</p>
                                                <p>{betData?.name}</p>
                                            </li>
                                        }
                                        {betData && betData?.total_bidding_amount &&
                                            <li>
                                                <p>Total bidding amount :</p>
                                                <p>₹ {betData?.total_bidding_amount}</p>
                                            </li>
                                        }
                                        {betData && betData?.transaction_id &&
                                            <li>
                                                <p>Trans Id :</p>
                                                <p>{betData?.transaction_id}</p>
                                            </li>
                                        }
                                        {betData && betData?.play_date &&
                                            <li>
                                                <p>Play Date :</p>
                                                <p className='d-flex'>
                                                    {betData?.play_date}
                                                </p>
                                            </li>
                                        }
                                        {betData && betData?.created_at && <li>
                                            <p>Date & Time :</p>
                                            <p className='d-flex'>
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none" style={{ marginRight: 5 }}>
                                                    <path d="M1.22363 7.39388H13.4511V12.8962C13.4511 13.2339 13.1774 13.5076 12.8397 13.5076H1.83501C1.49736 13.5076 1.22363 13.2339 1.22363 12.8962V7.39388ZM10.3942 2.5029H12.8397C13.1774 2.5029 13.4511 2.77662 13.4511 3.11427V6.17114H1.22363V3.11427C1.22363 2.77662 1.49736 2.5029 1.83501 2.5029H4.2805V1.28015H5.50324V2.5029H9.17148V1.28015H10.3942V2.5029Z" fill="url(#paint0_linear_1222_12)" />
                                                    <path d="M1.22363 7.39388H13.4511V12.8962C13.4511 13.2339 13.1774 13.5076 12.8397 13.5076H1.83501C1.49736 13.5076 1.22363 13.2339 1.22363 12.8962V7.39388ZM10.3942 2.5029H12.8397C13.1774 2.5029 13.4511 2.77662 13.4511 3.11427V6.17114H1.22363V3.11427C1.22363 2.77662 1.49736 2.5029 1.83501 2.5029H4.2805V1.28015H5.50324V2.5029H9.17148V1.28015H10.3942V2.5029Z" fill="url(#paint1_linear_1222_12)" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_1222_12" x1="26.987" y1="0.229679" x2="-2.41744" y2="4.76034" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#00FFDD" />
                                                            <stop offset="1" stopColor="#6F27FF" />
                                                        </linearGradient>
                                                        <linearGradient id="paint1_linear_1222_12" x1="7.33736" y1="1.28015" x2="7.33736" y2="13.5076" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#A200DB" />
                                                            <stop offset="1" stopColor="#8327F7" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg> */}
                                                {betData && Moment(betData?.created_at).format('DD MMM yyyy, h:mm A')}
                                            </p>

                                        </li>
                                        }
                                    </ul>

                                </div>
                                <div className='col-12 px-0'>
                                    <h5 className='suuccessTital'>Numbers</h5>
                                    <div className='betNumberData betNumberDataScroll'>
                                        <div>
                                            {allData && Object.keys(allData).map((key: any, index1: any): any => {
                                                return (
                                                    <div key={index1} className='row'>
                                                        {[...Array(6)].map((element, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    {Object.entries(allData)[index + index1 * 6] && (
                                                                        <div className="col-2 text-center px-0" key={index} >
                                                                            {Object.entries(allData)[index + index1 * 6] && (
                                                                                <React.Fragment key={index}>
                                                                                    <div className='betingChartCard'>
                                                                                        <span className="betingChartNumber">{Object.entries(allData)[index + index1 * 6][0]}</span>
                                                                                        <span className="betingChartAmount"> ₹ {Object.entries(allData)[index + index1 * 6][1]}</span>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Cross;