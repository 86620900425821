import axios from "axios";
import AuthHeader from "./auth.header";

interface Token {
  access_token: any | null;
  uid: string;
}

const ext = AuthHeader.baseUrlName==='https://apisoft.bashgames.in/' ? '.php' : '';

function getUserId(): string | null {
  let localData = localStorage.getItem("sg_token");
  const token: Token | null = localData ? JSON.parse(localStorage.getItem("sg_token") || "") : '';
  if (token !== null && token.uid !== '') {
    return token.uid;
  } else {
    return null;
  }
}

const login = async (data: { mobile: string, password: string }): Promise<any> => {
  const formData = {
    user_email: data.mobile,
    pwd: data.password
  }

  const response = await AuthHeader.axiosInstance().post("_signin"+ext, formData);

  if (response.data.status === true) {
    localStorage.setItem("sg_token",
      JSON.stringify({
        access_token: response.data.accessToken,
        uid: response.data.userId,
        uuid: response.data.uuid
      })
    );
  }

  return response.data;
};

const gameList = async (): Promise<any> => {
  const formData = {
    userId: getUserId()
  }
  const response = await AuthHeader.axiosInstance().post("_games"+ext, formData);
  return response.data;
}

const profile = async (): Promise<any> => {
  const formData = {
    userId: getUserId()
  }

  const response = await AuthHeader.axiosInstance().post("_profile"+ext, formData);
  return response.data;
}

const profileUpdate = async (data: any): Promise<any> => {
  const response = await AuthHeader.axiosInstance().post("_profileUpdate"+ext, data);
  return response.data;
}

const wallet = async (): Promise<any> => {
  const response = await AuthHeader.axiosInstance().get("_wallet"+ext);
  return response.data;
}

const placeBet = async (data: any): Promise<any> => { 
  const response = await AuthHeader.axiosInstance().post("_placeBet"+ext, data);
  return response.data;
}

const gateways = async (data:any): Promise<any> => {
  const formData = {
    amount: data,    
  }
  const response = await AuthHeader.axiosInstance().post("_gateways"+ext, formData);
  return response.data;
}

const gatewayDetails = async (data: any): Promise<any> => {
  const formData = { ...data, userId: getUserId() }
  const response = await AuthHeader.axiosInstance().post("_gateway_detials"+ext, formData);
  return response.data;
}

const addMoney = async (data: any): Promise<any> => {

  const response = await AuthHeader.axiosInstance().post("_submitAddMoney"+ext, data);
  return response.data;
}

const addMoneyStatus = async (data: any): Promise<any> => {
  const formData = {
    userId: getUserId(),
    id: data,
  }

  const response = await AuthHeader.axiosInstance().post("_checkAddMoneyStatus"+ext, formData);
  return response.data;
}

const statements = async (data: any): Promise<any> => {
  const formData = {
    userId: getUserId(),
    last_id: data,
  }
  const response = await AuthHeader.axiosInstance().post("_statements"+ext, formData);
  return response.data;
}

const statementDetails = async (data: any): Promise<any> => {

  const response = await AuthHeader.axiosInstance().post("_statementDetails"+ext, data);
  return response.data;
}

const logout = async (): Promise<any> => {
  const response = await AuthHeader.axiosInstance().delete("_logout"+ext);
  if (response.data.status === true) {
    localStorage.removeItem("sg_token");
  }
  return response.data;
  }

const changePassword = async (data: any): Promise<any> => {
  const formData = { ...data, userId: getUserId() }
  const response = await AuthHeader.axiosInstance().post("_changePassword"+ext, formData);
  return response.data;
};

const referralList = async (): Promise<any> => {
  const formData = {
    userId: getUserId()
  }

  const response = await AuthHeader.axiosInstance().post("_referlist"+ext, formData);
  return response.data;
}

const referralCode = async (): Promise<any> => {
  const formData = {
    userId: getUserId()
  }

  const response = await AuthHeader.axiosInstance().post("_getReferalCode"+ext, formData);
  return response.data;
}

const register = async (formData: any): Promise<any> => {

  const response = await AuthHeader.axiosInstance().post("_register"+ext, formData);
  return response.data;
}

const setPassword = async (data: any): Promise<any> => {
  const formData = {
    userId: getUserId(),
    password: data.pwd
  }
 
  const response = await AuthHeader.axiosInstance().post("_setPassword"+ext, formData);
  return response.data;
}

const forgotPassword = async (data: any): Promise<any> => {  
  const response = await AuthHeader.axiosInstance().post("_forgotPassword"+ext, data);  
  
  return response.data;
}

const isPasswordSet = async (): Promise<any> => {
  const formData = {
    userId: getUserId()    
  }
  const response = await AuthHeader.axiosInstance().post("_isPasswordSet"+ext, formData);
  return response.data;
}

const createUser = async (formData: any): Promise<any> => {  
  const response = await AuthHeader.axiosInstance().post("_register"+ext, formData);
  if (response.data.status === true) {
    localStorage.setItem("sg_token",
      JSON.stringify({
        access_token: response.data.accessToken,
        uid: response.data.userId,
        uuid: response.data.uuid
      })
    );
  }
  return response.data;
}

const addBank = async (data: any): Promise<any> => {
  const formData = { ...data, userId: getUserId() }
  const response = await AuthHeader.axiosInstance().post("_addBank"+ext, formData);
  return response.data;
}

const bankAccountList = async (): Promise<any> => {
  const formData = {
    userId: getUserId()
  }

  const response = await AuthHeader.axiosInstance().post("_getSavedBanks"+ext, formData);
  return response.data;
}

const help = async (): Promise<any> => {
  const formData = {
    userId: getUserId()
  }

  const response = await AuthHeader.axiosInstance().post("_help"+ext, formData);
  return response.data;
}

const helpChat = async (data: any): Promise<any> => { 
  return {"status":true,"msg":"Query Submitted Successfully"}
  const response = await AuthHeader.axiosInstance().post("_chatHelp"+ext, data);
  return response.data;
}

const withdrawalMoney = async (data: any, amount: any): Promise<any> => { 
  const formData = { ...data, amount: amount, account_number: data.account_no, userId: getUserId() }
  delete formData?.insertedAt;
  delete formData?.status;
  delete formData?.user_id;
  delete formData?.account_no; 

  const response = await AuthHeader.axiosInstance().post("_submitWithdrawalMoney"+ext, formData);
  return response.data;
}

const withdrawalMoneyStatus = async (data: any): Promise<any> => {

  const formData = {
    userId: getUserId(),
    id: data,
  }

  const response = await AuthHeader.axiosInstance().post("_checkWithdrawalMoneyStatus"+ext, formData);
  return response.data;
}

const checkReferral = async (data: any): Promise<any> => {
  const formData = { refered_by: data }
  const response = await AuthHeader.axiosInstance().post("_getReferal"+ext, formData);
  return response.data;
}

const isNumberAlreadyRegistered = async (data: any): Promise<any> => {
  const formData = { user_mobile: data }
  const response = await AuthHeader.axiosInstance().post("_isExist"+ext, formData);
  return response.data;
}

const result = async (data: any): Promise<any> => {
  const formData = {
    userId: getUserId(),
    date: data
  }
  const response = await AuthHeader.axiosInstance().post("_result"+ext, formData);
  return response.data;
}

const checkIFSC = async (data: any): Promise<any> => {
  const response = await axios.get("https://ifsc.razorpay.com/" + data, {})
  return response.data;
}

const getCountryCode = async (): Promise<any> => {
  const response = await AuthHeader.axiosInstance().get("_countryCode"+ext);
  return response.data;
};

const getVideoLink = async (): Promise<any> => {
  const response = await AuthHeader.axiosInstance().get("_videoFetch"+ext);
  return response.data;
};

const getMetaTags = async (slug:any): Promise<any> => {
  const response = await AuthHeader.axiosInstance().get("_metaTags"+ext+'?slug='+slug);
  return response.data;
};

const getLocation = async (): Promise<any> => {
  const response = await AuthHeader.axiosInstance().get("https://ipapi.co/json/");
  return response.data;
};

const AuthService = {
  login,
  setPassword,
  forgotPassword,
  isPasswordSet,
  gameList,
  profile,
  profileUpdate,
  gateways,
  gatewayDetails,
  wallet,
  addMoney,
  addMoneyStatus,
  placeBet,
  statements,
  statementDetails,
  logout,
  changePassword,
  referralList,
  checkReferral,
  isNumberAlreadyRegistered,
  register,
  createUser,
  addBank,
  bankAccountList,
  help,
  helpChat,
  withdrawalMoney,
  withdrawalMoneyStatus,
  referralCode,
  result,
  checkIFSC,
  getCountryCode,
  getVideoLink,
  getLocation,
  getMetaTags
}

export default AuthService;
