import React, { useState } from "react";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";

function AddBank() {
   const [loading, setLoading] = useState(false);
   const [ifscLoading, setIfscLoading] = useState(false);
   const [errors, setErrors] = useState<any>({});
   const [message, setMessage] = useState("");
   const navigate = useNavigate();

   const [formData, setFormData] = useState({
      account_holder: "",
      account_number: "",
      c_account_number: "",
      ifsc: "",
      bank_name: "",
      is_ifsc_valid: false
   });

   const handleInputChange = (e: any) => {
      const { name, value } = e.target;

      if ((name === 'account_number' || name === 'c_account_number') && isNaN(e.target.value)) {
         return false
       }

      if (e.target.name === 'ifsc' && (e.target.value).length === 11) {
         setErrors({ [`ifsc`]: "" });
         setIfscLoading(true);
         setFormData({
            ...formData,
            [`bank_name`]: '',
         });
         AuthService.checkIFSC(e.target.value).then(
            (response) => {
               if (!response) {
                  setErrors({ [`ifsc`]: "Invalid IFSC Code" });
               }
               setFormData(prev => ({
                  ...prev,
                  [`bank_name`]: response.BANK,
                  [name]: response.IFSC,
                  [`is_ifsc_valid`]: true,
               }));              
            }
         ).catch((error) => {
            setErrors({ [`ifsc`]: "Invalid IFSC Code" });
            setFormData(prev => ({
               ...prev,
               [name]: value,
               [`is_ifsc_valid`]: false,
            }));

         }).finally(() => {
            setIfscLoading(false);
         });
      } else {
         setFormData(prev => ({
            ...prev,
            [name]: value,
         }));
      }

   }

   const validateForm = () => {
      let isValid = true;
      const newErrors: any = {};

      if (!formData.account_holder) {
         newErrors.name = "Account holder name is required";
         isValid = false;
      }

      if (!formData.account_number) {
         newErrors.account_number = "Account no. is required";
         isValid = false;
      }

      if (formData.c_account_number !== formData.account_number) {
         newErrors.c_account_number = "Account no. does not match";
         isValid = false;
      }

      if (!formData.c_account_number) {
         newErrors.c_account_number = "Re-enter Account no. is required";
         isValid = false;
      }

      if (!formData.is_ifsc_valid) {
         newErrors.ifsc = "Invalid IFSC code";
         isValid = false;
      }

      if (!formData.ifsc) {
         newErrors.ifsc = "IFSC code is required";
         isValid = false;
      }

      if (!formData.bank_name) {
         newErrors.bank_name = "Bank Name is required";
         isValid = false;
      }

      if (!isValid) {
         setLoading(false);
      }
      setErrors(newErrors);
      return isValid;
   };

   const handleSubmit = (e: any) => {
      e.preventDefault();
      setMessage('');
      setLoading(true);

      if (validateForm()) {
         AuthService.addBank(formData).then(
            (response) => {
               if (response.status === true) {
                  setMessage(response.msg);
                  setFormData({
                     ...formData,
                     [`account_holder`]: '',
                     [`account_number`]: '',
                     [`c_account_number`]: '',
                     [`ifsc`]: '',
                     [`bank_name`]: '',
                     [`is_ifsc_valid`]: false,
                  });
                  navigate("/withdrawal")
               } else {
                  setMessage(response.msg);
                  if (response.code === 401) {
                     localStorage.removeItem("sg_token");
                     navigate('/', { state: { message: response?.msg } });
                  }
               }
               setLoading(false);
            }
         ).catch((error) => {
            const message =
               (error.response &&
                  error.response?.data?.msg);
            setMessage(message);
            if (error.response?.data?.code === 401) {
               localStorage.removeItem("sg_token");
               navigate('/', { state: { message: message } });
            }
         }).finally(() => {
            setLoading(false);
         });
      }

   }


   return (
      <div className="container">
         <div className="row">
            <div className="col-12">
               <section className="addBankForm">
                  <form onSubmit={handleSubmit}>
                     <div className="registerInput">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                           <path d="M8.00002 9.58482C10.2057 9.58482 11.9937 7.43918 11.9937 4.79241C11.9937 2.14563 10.2057 0 8.00002 0C5.79438 0 4.00635 2.14563 4.00635 4.79241C4.00635 7.43918 5.79438 9.58482 8.00002 9.58482Z" fill="url(#paint0_linear_1137_1144)" />
                           <path d="M15.8276 13.6582C15.1087 12.2204 13.7509 11.0223 11.9937 10.3035C11.5144 10.1437 10.9553 10.1437 10.556 10.3833C9.75722 10.8626 8.95848 11.1022 8 11.1022C7.04152 11.1022 6.24278 10.8626 5.44405 10.3833C5.04468 10.2236 4.48557 10.1437 4.00633 10.3833C2.24911 11.1022 0.891262 12.3003 0.1724 13.738C-0.386714 14.7764 0.491894 15.9745 1.69 15.9745H14.31C15.5081 15.9745 16.3867 14.7764 15.8276 13.6582Z" fill="url(#paint1_linear_1137_1144)" />
                           <defs>
                              <linearGradient id="paint0_linear_1137_1144" x1="8.00002" y1="0" x2="8.00002" y2="9.58482" gradientUnits="userSpaceOnUse">
                                 <stop stopColor="#A200DB" />
                                 <stop offset="1" stopColor="#8327F7" />
                              </linearGradient>
                              <linearGradient id="paint1_linear_1137_1144" x1="8" y1="10.1921" x2="8" y2="15.9745" gradientUnits="userSpaceOnUse">
                                 <stop stopColor="#A200DB" />
                                 <stop offset="1" stopColor="#8327F7" />
                              </linearGradient>
                           </defs>
                        </svg>
                        <input
                           type="text"
                           name="account_holder"
                           aria-describedby="account_holder"
                           placeholder="Account holder name"
                           autoComplete="off"
                           value={formData.account_holder}
                           onChange={(e) => handleInputChange(e)}
                        />
                     </div>
                     {errors.name && <div className="text-danger text-start pb-2 loginErrors">{errors.name}</div>}
                     <div className="registerInput">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                           <path d="M8.55717 0.0909536C8.83842 -0.0303179 9.16185 -0.0303179 9.4431 0.0909536L17.3181 3.46606C17.8103 3.67735 18.0845 4.20469 17.9755 4.725C17.8701 5.24883 17.4095 5.59336 16.8435 5.625V5.90625C16.8435 6.37383 16.499 6.75 15.9997 6.75H1.93725C1.50272 6.75 1.0935 6.37383 1.0935 5.90625V5.625C0.591114 5.59336 0.130497 5.24883 0.0230876 4.725C-0.0843076 4.20469 0.191001 3.67735 0.681817 3.46606L8.55717 0.0909536ZM9.00014 4.5C9.6224 4.5 10.1251 3.99727 10.1251 3.375C10.1251 2.75379 9.6224 2.25 9.00014 2.25C8.37787 2.25 7.87514 2.75379 7.87514 3.375C7.87514 3.99727 8.37787 4.5 9.00014 4.5ZM4.4685 14.625H5.87475V7.875H8.12475V14.625H9.84389V7.875H12.0939V14.625H13.5001V7.875H15.7501V14.7762C15.7712 14.7867 15.7923 14.7691 15.8134 14.8148L17.5009 15.9398C17.9122 16.2141 18.0951 16.7273 17.9509 17.202C17.8068 17.6766 17.3708 18 16.8751 18H1.0935C0.629434 18 0.19202 17.6766 0.048372 17.202C-0.0953116 16.7273 0.0885556 16.2141 0.501114 15.9398L2.18861 14.8148C2.20865 14.7691 2.22939 14.7867 2.2185 14.7762V7.875H4.4685V14.625Z" fill="url(#paint0_linear_1146_1150)" />
                           <defs>
                              <linearGradient id="paint0_linear_1146_1150" x1="8.99984" y1="0" x2="8.99984" y2="18" gradientUnits="userSpaceOnUse">
                                 <stop stopColor="#A200DB" />
                                 <stop offset="1" stopColor="#8327F7" />
                              </linearGradient>
                           </defs>
                        </svg>
                        <input
                           type="tel"
                           name="account_number"
                           aria-describedby="account_number"
                           placeholder="Account Number"
                           autoComplete="off"
                           maxLength={16}
                           value={formData.account_number}
                           onChange={(e) => handleInputChange(e)}
                        />
                     </div>
                     {errors.account_number && <div className="text-danger text-start pb-2 loginErrors">{errors.account_number}</div>}
                     <div className="registerInput">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                           <path d="M8.55717 0.0909536C8.83842 -0.0303179 9.16185 -0.0303179 9.4431 0.0909536L17.3181 3.46606C17.8103 3.67735 18.0845 4.20469 17.9755 4.725C17.8701 5.24883 17.4095 5.59336 16.8435 5.625V5.90625C16.8435 6.37383 16.499 6.75 15.9997 6.75H1.93725C1.50272 6.75 1.0935 6.37383 1.0935 5.90625V5.625C0.591114 5.59336 0.130497 5.24883 0.0230876 4.725C-0.0843076 4.20469 0.191001 3.67735 0.681817 3.46606L8.55717 0.0909536ZM9.00014 4.5C9.6224 4.5 10.1251 3.99727 10.1251 3.375C10.1251 2.75379 9.6224 2.25 9.00014 2.25C8.37787 2.25 7.87514 2.75379 7.87514 3.375C7.87514 3.99727 8.37787 4.5 9.00014 4.5ZM4.4685 14.625H5.87475V7.875H8.12475V14.625H9.84389V7.875H12.0939V14.625H13.5001V7.875H15.7501V14.7762C15.7712 14.7867 15.7923 14.7691 15.8134 14.8148L17.5009 15.9398C17.9122 16.2141 18.0951 16.7273 17.9509 17.202C17.8068 17.6766 17.3708 18 16.8751 18H1.0935C0.629434 18 0.19202 17.6766 0.048372 17.202C-0.0953116 16.7273 0.0885556 16.2141 0.501114 15.9398L2.18861 14.8148C2.20865 14.7691 2.22939 14.7867 2.2185 14.7762V7.875H4.4685V14.625Z" fill="url(#paint0_linear_1146_1150)" />
                           <defs>
                              <linearGradient id="paint0_linear_1146_1150" x1="8.99984" y1="0" x2="8.99984" y2="18" gradientUnits="userSpaceOnUse">
                                 <stop stopColor="#A200DB" />
                                 <stop offset="1" stopColor="#8327F7" />
                              </linearGradient>
                           </defs>
                        </svg>
                        <input
                           type="tel"
                           name="c_account_number"
                           aria-describedby="c_account_number"
                           placeholder="Re-enter Account Number"
                           autoComplete="off"
                           maxLength={16}
                           value={formData.c_account_number}
                           onChange={(e) => handleInputChange(e)}
                        />
                     </div>
                     {errors.c_account_number && <div className="text-danger text-start pb-2 loginErrors">{errors.c_account_number}</div>}
                     <div className="registerInput">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                           <path d="M8.55717 0.0909536C8.83842 -0.0303179 9.16185 -0.0303179 9.4431 0.0909536L17.3181 3.46606C17.8103 3.67735 18.0845 4.20469 17.9755 4.725C17.8701 5.24883 17.4095 5.59336 16.8435 5.625V5.90625C16.8435 6.37383 16.499 6.75 15.9997 6.75H1.93725C1.50272 6.75 1.0935 6.37383 1.0935 5.90625V5.625C0.591114 5.59336 0.130497 5.24883 0.0230876 4.725C-0.0843076 4.20469 0.191001 3.67735 0.681817 3.46606L8.55717 0.0909536ZM9.00014 4.5C9.6224 4.5 10.1251 3.99727 10.1251 3.375C10.1251 2.75379 9.6224 2.25 9.00014 2.25C8.37787 2.25 7.87514 2.75379 7.87514 3.375C7.87514 3.99727 8.37787 4.5 9.00014 4.5ZM4.4685 14.625H5.87475V7.875H8.12475V14.625H9.84389V7.875H12.0939V14.625H13.5001V7.875H15.7501V14.7762C15.7712 14.7867 15.7923 14.7691 15.8134 14.8148L17.5009 15.9398C17.9122 16.2141 18.0951 16.7273 17.9509 17.202C17.8068 17.6766 17.3708 18 16.8751 18H1.0935C0.629434 18 0.19202 17.6766 0.048372 17.202C-0.0953116 16.7273 0.0885556 16.2141 0.501114 15.9398L2.18861 14.8148C2.20865 14.7691 2.22939 14.7867 2.2185 14.7762V7.875H4.4685V14.625Z" fill="url(#paint0_linear_1146_1150)" />
                           <defs>
                              <linearGradient id="paint0_linear_1146_1150" x1="8.99984" y1="0" x2="8.99984" y2="18" gradientUnits="userSpaceOnUse">
                                 <stop stopColor="#A200DB" />
                                 <stop offset="1" stopColor="#8327F7" />
                              </linearGradient>
                           </defs>
                        </svg>
                        <input
                           type="text"
                           name="ifsc"
                           aria-describedby="ifsc"
                           placeholder="IFSC Code"
                           autoComplete="off"
                           maxLength={11}
                           pattern="[A-Z]{4}[0][A-Z0-9]{6}$"
                           value={formData.ifsc}
                           onChange={(e) => handleInputChange(e)}
                        />
                        {ifscLoading && <span className="loader-spin mt-1" style={{color:'#A200DB'}}></span>}
                     </div>
                     {errors.ifsc && <div className="text-danger text-start pb-2 loginErrors">{errors.ifsc}</div>}

                     <input
                        type="hidden"
                        name="bank_name"
                        placeholder="Bank Name"
                        value={formData.bank_name}
                     />
                     {formData.bank_name && <p className="text-white">{formData.bank_name}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                           <path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z" fill="#66A252" />
                        </svg>
                     </p>}
                     <div className="addBankSubmit">
                        {message && (
                           <div className='d-block text-danger' role="alert">
                              {message}
                           </div>
                        )}
                        <button type="submit" className="btn btn-primary login-btn" disabled={loading} >
                           {loading && (
                              <span className="loader-spin"></span>
                           )}
                           <span>Continue</span>
                        </button>
                     </div>
                  </form>
               </section>
            </div>
         </div>
      </div>
   )
}
export default AddBank;