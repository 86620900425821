import { Navigate, Outlet } from "react-router-dom"
import Header from "../components/header";
import Footer from "../components/footer";
import Helpers from "../services/helpers";

const ProtectedLayout = (props: any) => {
    const isBet = props.isPlaceBet;
    const Token = Helpers.getTokenData();
    if (!Token?.access_token) {
        return <Navigate to="/" replace />
    }


    return (
        <>            
            <Header {...props} isBet={isBet} />
            <Outlet />
            <Footer />
        </>
    )
}

export default ProtectedLayout;