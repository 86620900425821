import React from "react";
import { Link, useNavigate } from 'react-router-dom';

function Success(props: any) {
  const navigate = useNavigate();

  setTimeout(() => {
    navigate("/home", { replace: true });
  }, 10000);

  return (
    <div className="col-12 SuccessNew">
      <div className="paymentContainer">
        <div className="Success">
          <div className="addPayment">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path d="M20 0C8.95312 0 0 8.95312 0 20C0 31.0469 8.95312 40 20 40C31.0469 40 40 31.0469 40 20C40 8.95312 31.0469 0 20 0ZM29.0469 16.5469L19.0469 26.5469C18.6172 26.9766 18.0625 27.1875 17.5 27.1875C16.9375 27.1875 16.3797 26.9751 15.9523 26.5478L10.9523 21.5478C10.1 20.6931 10.1 19.3064 10.9523 18.4517C11.807 17.597 13.1938 17.597 14.0484 18.4517L17.5 21.9062L25.9531 13.4531C26.8078 12.5984 28.1945 12.5984 29.0492 13.4531C29.8984 14.3047 29.8984 15.6953 29.0469 16.5469Z" fill="#fff" />
            </svg>
            <p className="successHeding">Success</p>
            {props.txnStatusRes?.amount && <p className="successPyment">₹{props.txnStatusRes?.amount}</p>}
            {props.txnStatusRes?.txnId && <p className="pendingPyment">Txn Id : {props.txnStatusRes?.txnId}</p>}
            {props.txnStatusRes?.rimg &&
              <div className="screenShortImage">
                <img src={props.txnStatusRes?.rimg} alt="" className="img-fluid" />
              </div>
            }
            <br />
            <div className="btn-back">
              <Link to="/home">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}
export default Success;