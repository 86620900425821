import React, { useEffect, useRef, useState } from 'react';
import '../App.css';
import AuthService from "../services/auth.service";
import { Link, useNavigate } from 'react-router-dom';
import { auth } from "../services/firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Loader from '../components/loader';

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [isPageloading, setIsPageLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [errorsPwd, setErrorsPwd] = useState<any>({});
  const [message, setMessage] = useState("");
  const [messagePwd, setMessagePwd] = useState("");
  const [isOtpSend, setIsOtpSend] = useState<boolean>(false);
  const [isOtpVerify, setIsOtpVerify] = useState<boolean>(false);
  const [isUserAlreadyRegistered, setIsUserAlreadyRegistered] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);
  const [user, addUser] = useState<any>();
  const [counter, setCounter] = useState(20);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_mobile: "",
    country_code: "+91"
  });

  const [formDataPwd, setFormDataPwd] = useState({
    pwd: "",
    confirm_pwd: "",
  });

  /******* start OTP Verify section */
  const [otp, setOtp] = useState<string[]>(Array(6).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));

  const handleChange = (index: number, value: string) => {
    if (isNaN(Number(value))) {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);
    var newArray = newOtp.filter(value => Object.keys(value).length !== 0);
    if (newArray.length === 6) {
      setIsOtpValid(true);
    } else {
      setIsOtpValid(false);
    }
    // Focus on next input field
    if (value !== '' && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyUp = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (e.key === 'Backspace' && value === '' && index > 0) {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      inputRefs.current[index - 1]?.focus();
    }
  };

  const pastefunction = (event: any) => {
    event.preventDefault();
    let data = event.clipboardData.getData('text/plain');
    if (!isNaN(Number(data))) {
      var dataArr = data.split('');
      var dataArr1 = dataArr.slice(0, 6);
      const newOtp = [...otp];
      for (let i = 0; i < 6; i++) {
        newOtp[i] = dataArr[i] ? dataArr[i] : '';
      }
      setOtp(newOtp);
      if (dataArr1.length === 6) {
        setIsOtpValid(true);
      } else {
        setIsOtpValid(false);
      }
      inputRefs.current[Number(dataArr1.length - 1)]?.focus();
    }
  }
  /******* end OTp Verify section ********/

  useEffect(() => {
    AuthService.getLocation().then(
      async (response) => {
        formData.country_code = response.country_calling_code;
        // eslint-disable-next-line         
      }
    ).catch((error) => {
      //console.log(error);
    }).finally(() => {
      setIsPageLoading(false);
    });

  }, []);

  const handleInputChangePwd = (e: any) => {
    const { name, value } = e.target;
    //console.log(e)
    // if(e.target.value.trim()===''){
    //   return false;
    // }
   
    setFormDataPwd(prev => ({
      ...prev,
      [name]: value
    }));
  }


  useEffect(() => {
    if (isOtpSend === true) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);   //for countdown timer
    }
  });


  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === 'user_mobile' && (isNaN(e.target.value) || (e.target.value).length > 10)) {
      return false
    }


    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === 'user_mobile' && (e.target.value).length === 10) {
      checkNumberIsExists(e.target.value);
    }
    if (name === 'user_mobile' && (e.target.value).length < 10) {
      setIsUserAlreadyRegistered(false);
      setFormData(prev => ({
        ...prev,
        ['refered_by']: ''
      }));
    }

  }

  const checkNumberIsExists = async (mobile: any) => {

    await AuthService.isNumberAlreadyRegistered(mobile).then(
      (response) => {
        if (response.status === true) {
          setIsUserAlreadyRegistered(false);


          setFormData(prev => ({
            ...prev,
            ['refered_by']: ''
          }));
        } else {
          setIsUserAlreadyRegistered(true);
        }
        setLoading(false);
        // eslint-disable-next-line         
      }
    ).catch((error) => {
      const message =
        (error.response &&
          error.response?.data?.msg);
      console.log(message);

    }).finally(() => {
      //setLoading(false);
    });
  }


  const validateForm = async () => {
    let isValid = true;
    const newErrors: any = {};

    if (!formData.country_code) {
      newErrors.country_code = "Enter valid country code";
      isValid = false;
    }

    if ((formData.user_mobile.length < 10 || formData.user_mobile.length > 10) && (formData.country_code === '91' || formData.country_code === '+91')) {
      newErrors.user_mobile = "Mobile no. should be 10 digits";
      isValid = false;
    }

    if (!formData.user_mobile) {
      newErrors.user_mobile = "Enter valid mobile no.";
      isValid = false;
    }


    if (!isValid) {
      setLoading(false);
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    if (await validateForm()) {
      otpSend();
    }

  }

  const otpSend = async () => {
    setSending(true);
    //var recaptcha;
    try {
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {
        size: "invisible",
      });

      const confirmation = await signInWithPhoneNumber(
        auth,
        formData.country_code + formData.user_mobile,
        recaptcha
      );
      if (confirmation?.verificationId) {
        setMessage("OTP sent successfully");
        addUser(confirmation);
        setIsOtpSend(true);
        setCounter(20);
      }

    } catch (error: any) {
      switch (error.code) {
        case "auth/too-many-requests":
          setMessage("Too many requests. Please try again later.");
          break;
        case "auth/invalid-phone-number":
          setMessage("The phone number is invalid.");
          break;
        default:
          setMessage(error.code);
          break;
      }
      //recaptcha = "";
      setLoading(false);
    } finally {
      setLoading(false);
      setSending(false);
      setTimeout(() => {
        setMessage('');
      }, 5000);
    }
  }
  const editMobile = () => {
    setOtp(Array(6).fill(''));
    setIsOtpSend(false);
    setMessage("");
  }

  const handleSubmitOTP = async (e: any) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    const otpString = otp.join("");
    if (otpString.length < 6) {
      setMessage('OTP should be 6 digits');
      setLoading(false);
      return;
    }

    try {
      const data = await user.confirm(otpString);
      if (data) {
        setIsOtpVerify(true);
      }
    } catch (error: any) {
      switch (error.code) {
        case "auth/invalid-verification-code":
          setMessage("The verification code is invalid.");
          break;
        case "auth/code-expired":
          setMessage("The verification code is expired.");
          break;
        default:
          setMessage("Something went wrong. Please try again later.");
          break;
      }

    } finally {
      setLoading(false);
    }
  }

  const validateFormPwd = async () => {
    let isValid = true;
    const newErrors: any = {};

    if (formDataPwd.pwd.length < 6) {
      newErrors.pwd = "Password required minimum 6 characters";
      isValid = false;
    }

    if (!formDataPwd.pwd) {
      newErrors.pwd = "Password is required";
      isValid = false;
    }

    if (formDataPwd.confirm_pwd !== formDataPwd.pwd) {
      newErrors.confirm_pwd = "Confirm password does not match";
      isValid = false;
    }

    if (!formDataPwd.confirm_pwd) {
      newErrors.confirm_pwd = "Confirm password is required";
      isValid = false;
    }


    if (!isValid) {
      setLoading(false);
    }
    setErrorsPwd(newErrors);
    return isValid;
  };


  const handleSubmitSetPwd = async (e: any) => {
    e.preventDefault();
    setMessagePwd('');
    setLoading(true);
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const randomInd = Math.floor(Math.random() * characters.length);
    result = characters.charAt(randomInd);

    let mobile = btoa(formData.user_mobile)
    const data = { ...formDataPwd, user_email: result + mobile }
    if (await validateFormPwd()) {
      AuthService.forgotPassword(data).then(
        (response) => {
          if (response.status === true) {
            setMessagePwd(response.msg);
            setTimeout(() => {
              navigate('/');
            }, 2000);
            
          } else {
            setMessagePwd(response.msg);
          }
          setLoading(false);
        }
      ).catch((error) => {
        const message =
          (error.response &&
            error.response?.data?.msg);
        setMessagePwd(message);

      }).finally(() => {
        setLoading(false);
      });
    }

  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowCnfPassword = () => {
    setShowCnfPassword(!showCnfPassword);
  };

  return (
    <div>
      {isPageloading && (<Loader />)}

      {!isOtpSend ?
        (
          <>
            <h1 className="text-white" style={{ margin: 0 }}>Forgot Password</h1>
            <div className="logo">
              <img src="/logo.png" alt="Logo" className="img-fluid" />
            </div>
            <form className="registerButton" onSubmit={handleSubmit}>
              <div className="registerInput" style={{ padding: 0 }}>

                <span className='contryBack'>
                  <input type="text" name="country_code" value={formData.country_code} onChange={(e) => handleInputChange(e)} />
                </span>
                {/* <svg className='arrowSelect' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 13 8" fill="none">
                  <path d="M1.53292 0L6.5 4.94673L11.4671 0L13 1.52664L6.5 8L0 1.52664L1.53292 0Z" fill="white" />
                </svg> */}
                <input style={{ paddingLeft: 10 }} type="number" name="user_mobile" id="user_mobile" aria-describedby="user_mobile" placeholder="Enter Mobile Number" autoComplete='off' pattern="^[0-9]*$" maxLength={10} value={formData.user_mobile} onChange={(e) => handleInputChange(e)} />
              </div>
              {errors.country_code && <div className="text-danger text-start pb-2 loginErrors">{errors.country_code}</div>}
              {errors.user_mobile && <div className="text-danger text-start pb-2 loginErrors">{errors.user_mobile}</div>}

              {message && (
                <div className='d-block text-danger' role="alert">
                  {message}
                </div>
              )}

              {isUserAlreadyRegistered &&
                <div className='d-block text-danger' role="alert">
                  Mobile no. does not exists.
                </div>

              }

              <button type="submit" className="btn btn-primary login-btn" disabled={loading || isUserAlreadyRegistered} >
                {loading && (
                  <span className="loader-spin"></span>
                )}
                <span>Continue</span>
              </button>

              <div className='changePassword'>
                <Link to="/login">Already have an account? Login</Link>
              </div>
              <div id="recaptcha"></div>
            </form>
          </>
        ) :
        (
          <>
            {!isOtpVerify ?
              <>
                <h4 style={{ paddingTop: '10px', margin: 0 }}>OTP Verification</h4>
                <h2>Enter 6 digits OTP send to </h2>
                <h3>
                  {formData.country_code} {formData.user_mobile}
                  <svg style={{ margin: '4px', cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="15.121px" height="15.121px" viewBox="0 0 23 23" fill="none" onClick={editMobile}>
                    <path d="M10 3.12134H3C2.46957 3.12134 1.96086 3.33205 1.58579 3.70712C1.21071 4.0822 1 4.5909 1 5.12134V19.1213C1 19.6518 1.21071 20.1605 1.58579 20.5356C1.96086 20.9106 2.46957 21.1213 3 21.1213H17C17.5304 21.1213 18.0391 20.9106 18.4142 20.5356C18.7893 20.1605 19 19.6518 19 19.1213V12.1213" stroke="#A004DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.5 1.62132C17.8978 1.2235 18.4374 1 19 1C19.5626 1 20.1022 1.2235 20.5 1.62132C20.8978 2.01915 21.1213 2.55871 21.1213 3.12132C21.1213 3.68393 20.8978 4.2235 20.5 4.62132L11 14.1213L7 15.1213L8 11.1213L17.5 1.62132Z" stroke="#9F04DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </h3>
                <div className="otp-input-container">
                  <form className="otpInput otpInput_Error pt-0" onSubmit={handleSubmitOTP}>
                    <div>
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="text"
                          maxLength={1}
                          pattern="\d"
                          inputMode="numeric"
                          autoComplete='off'
                          id={`digit${index + 1}`}
                          value={digit}
                          onChange={(e) => handleChange(index, e.target.value)}
                          onKeyUp={(e) => handleKeyUp(index, e)}
                          ref={(ref) => (inputRefs.current[index] = ref)}
                          onPaste={(e) => pastefunction(e)}
                        />
                      ))}
                    </div>

                    <div className='errors_Otp'>
                      <p>
                        {message && (
                          <>
                            <svg width="16px" height="16px" style={{ marginRight: 3, marginBottom: 3 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"></path></svg>
                            {message}
                          </>
                        )}
                      </p>
                    </div>

                    <button type="submit" className="btn btn-primary login-btn mt-0" disabled={loading || !isOtpValid}  >
                      {loading && (
                        <span className="loader-spin"></span>
                      )}
                      <span>Verify OTP</span>
                    </button>
                    <div className='errors_Otp'>
                      {counter > 0 ? (
                        <p>
                          Resend OTP
                          <svg style={{ marginRight: 3, marginLeft: 3 }} xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM13 12H17V14H11V7H13V12Z"></path></svg>
                          <b>00:{counter < 10 ? '0' + counter : counter}</b>
                        </p>
                      ) : (
                        <p style={{ cursor: 'pointer' }} onClick={otpSend}>{sending ? 'Sending...' : 'Resend'}</p>
                      )}
                    </div>
                    <div id="recaptcha"></div>
                  </form>

                </div>

              </>

              :

              <div>
                <h1 className="text-white">New Password</h1>
                <div className="logo">
                  <img src="/logo.png" alt="Logo" className="img-fluid" />
                </div>
                <form className="registerButton" onSubmit={handleSubmitSetPwd}>
                  <div className="registerInput">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" fill="none">
                      <path d="M13.4987 7.501H10.499H4.49957V5.4012C4.49957 3.82635 5.62447 2.40148 7.19932 2.2515C8.99914 2.10151 10.499 3.52638 10.499 5.25121C10.499 5.70117 10.799 6.00114 11.2489 6.00114H11.9989C12.4488 6.00114 12.7488 5.70117 12.7488 5.25121C12.7488 2.40148 10.499 0.0767041 7.64927 0.00171123C4.72455 -0.0732816 2.24979 2.32649 2.24979 5.25121V7.501H1.49986C0.674936 7.501 0 8.17593 0 9.00086V14.2504C0 16.3502 1.64984 18 3.74964 18H11.2489C13.3487 18 14.9986 16.3502 14.9986 14.2504V9.00086C14.9986 8.17593 14.3236 7.501 13.4987 7.501ZM8.24922 13.2754V14.2504C8.24922 14.6253 7.87425 15.0003 7.49929 15.0003C7.12432 15.0003 6.74936 14.6253 6.74936 14.2504V13.2754C6.2994 13.0505 5.99943 12.5255 5.99943 12.0006C5.99943 11.1756 6.67437 10.5007 7.49929 10.5007C8.32421 10.5007 8.99914 11.1756 8.99914 12.0006C8.99914 12.5255 8.69917 13.0505 8.24922 13.2754Z" fill="url(#paint0_linear_2_224)" />
                      <defs>
                        <linearGradient id="paint0_linear_2_224" x1="7.49929" y1="0" x2="7.49929" y2="18" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#A200DB" />
                          <stop offset="1" stopColor="#8327F7" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <input type={showPassword ? "text" : "password"} name="pwd" id="pwd" aria-describedby="pwd" placeholder="Password" autoComplete='off' value={formDataPwd.pwd} onChange={(e) => handleInputChangePwd(e)} />
                    <div className="password-icon-eye" onClick={handleClickShowPassword}>
                      {showPassword ?
                        <svg style={{ display: 'block', marginRight: 0 }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62283 2.81932 7.5129 4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968ZM5.9356 7.3497C4.60673 8.56015 3.6378 10.1672 3.22278 12.0002C4.14022 16.0521 7.7646 19.0002 12.0003 19.0002C13.5997 19.0002 15.112 18.5798 16.4243 17.8384L14.396 15.8101C13.7023 16.2472 12.8808 16.5002 12.0003 16.5002C9.51498 16.5002 7.50026 14.4854 7.50026 12.0002C7.50026 11.1196 7.75317 10.2981 8.19031 9.60442L5.9356 7.3497ZM12.9139 14.328L9.67246 11.0866C9.5613 11.3696 9.50026 11.6777 9.50026 12.0002C9.50026 13.3809 10.6196 14.5002 12.0003 14.5002C12.3227 14.5002 12.6309 14.4391 12.9139 14.328ZM20.8068 16.5925L19.376 15.1617C20.0319 14.2268 20.5154 13.1586 20.7777 12.0002C19.8603 7.94818 16.2359 5.00016 12.0003 5.00016C11.1544 5.00016 10.3329 5.11773 9.55249 5.33818L7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87993 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925ZM11.7229 7.50857C11.8146 7.50299 11.9071 7.50016 12.0003 7.50016C14.4855 7.50016 16.5003 9.51488 16.5003 12.0002C16.5003 12.0933 16.4974 12.1858 16.4919 12.2775L11.7229 7.50857Z" fill="white" />
                        </svg>

                        :
                        <svg style={{ display: 'block', marginRight: 0 }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                          <path d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z" fill="white" />
                        </svg>
                      }
                    </div>
                  </div>
                  {errorsPwd.pwd && <div className="text-danger text-start pb-2 loginErrors">{errorsPwd.pwd}</div>}
                  <div className="registerInput">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" fill="none">
                      <path d="M13.4987 7.501H10.499H4.49957V5.4012C4.49957 3.82635 5.62447 2.40148 7.19932 2.2515C8.99914 2.10151 10.499 3.52638 10.499 5.25121C10.499 5.70117 10.799 6.00114 11.2489 6.00114H11.9989C12.4488 6.00114 12.7488 5.70117 12.7488 5.25121C12.7488 2.40148 10.499 0.0767041 7.64927 0.00171123C4.72455 -0.0732816 2.24979 2.32649 2.24979 5.25121V7.501H1.49986C0.674936 7.501 0 8.17593 0 9.00086V14.2504C0 16.3502 1.64984 18 3.74964 18H11.2489C13.3487 18 14.9986 16.3502 14.9986 14.2504V9.00086C14.9986 8.17593 14.3236 7.501 13.4987 7.501ZM8.24922 13.2754V14.2504C8.24922 14.6253 7.87425 15.0003 7.49929 15.0003C7.12432 15.0003 6.74936 14.6253 6.74936 14.2504V13.2754C6.2994 13.0505 5.99943 12.5255 5.99943 12.0006C5.99943 11.1756 6.67437 10.5007 7.49929 10.5007C8.32421 10.5007 8.99914 11.1756 8.99914 12.0006C8.99914 12.5255 8.69917 13.0505 8.24922 13.2754Z" fill="url(#paint0_linear_2_226)" />
                      <defs>
                        <linearGradient id="paint0_linear_2_226" x1="7.49929" y1="0" x2="7.49929" y2="18" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#A200DB" />
                          <stop offset="1" stopColor="#8327F7" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <input type={showCnfPassword ? "text" : "password"} name="confirm_pwd" id="confirm_pwd" aria-describedby="confirm_pwd" placeholder="Confirm Password" autoComplete='off' value={formDataPwd.confirm_pwd} onChange={(e) => handleInputChangePwd(e)} />
                    <div className="password-icon-eye" onClick={handleClickShowCnfPassword}>
                      {showCnfPassword ?
                        <svg style={{ display: 'block', marginRight: 0 }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62283 2.81932 7.5129 4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968ZM5.9356 7.3497C4.60673 8.56015 3.6378 10.1672 3.22278 12.0002C4.14022 16.0521 7.7646 19.0002 12.0003 19.0002C13.5997 19.0002 15.112 18.5798 16.4243 17.8384L14.396 15.8101C13.7023 16.2472 12.8808 16.5002 12.0003 16.5002C9.51498 16.5002 7.50026 14.4854 7.50026 12.0002C7.50026 11.1196 7.75317 10.2981 8.19031 9.60442L5.9356 7.3497ZM12.9139 14.328L9.67246 11.0866C9.5613 11.3696 9.50026 11.6777 9.50026 12.0002C9.50026 13.3809 10.6196 14.5002 12.0003 14.5002C12.3227 14.5002 12.6309 14.4391 12.9139 14.328ZM20.8068 16.5925L19.376 15.1617C20.0319 14.2268 20.5154 13.1586 20.7777 12.0002C19.8603 7.94818 16.2359 5.00016 12.0003 5.00016C11.1544 5.00016 10.3329 5.11773 9.55249 5.33818L7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87993 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925ZM11.7229 7.50857C11.8146 7.50299 11.9071 7.50016 12.0003 7.50016C14.4855 7.50016 16.5003 9.51488 16.5003 12.0002C16.5003 12.0933 16.4974 12.1858 16.4919 12.2775L11.7229 7.50857Z" fill="white" />
                        </svg>

                        :
                        <svg style={{ display: 'block', marginRight: 0 }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                          <path d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z" fill="white" />
                        </svg>
                      }
                    </div>
                  </div>
                  {errorsPwd.confirm_pwd && <div className="text-danger text-start pb-2 loginErrors">{errorsPwd.confirm_pwd}</div>}


                  {messagePwd && (
                    <div className='d-block text-danger' role="alert">
                      {messagePwd}
                    </div>
                  )}
                  <button type="submit" className="btn btn-primary login-btn" disabled={loading} >
                    {loading && (
                      <span className="loader-spin"></span>
                    )}
                    <span>New Password</span>
                  </button>

                </form>
              </div>
            }
          </>
        )}
    </div>

  )
}
export default ForgotPassword;