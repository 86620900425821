import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import AuthService from "../services/auth.service";
import Loader from "../components/loader";
import Helpers from "../services/helpers";
import Pending from "./pending";
import Success from "./success";
import Failed from "./failed";
import Processing from "./processing";
import $ from 'jquery';

function Withdrawal(props: any) {
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isPageloading, setIsPageLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState<any>("");
  const [banksList, setBanksList] = useState([]);
  const [wallet, setWallet] = useState("0.00");
  const [amount, setAmount] = useState('');
  const [waitingTime, setWaitingTime] = useState<number>(-1);
  const [maxWaitingTime, setMaxWaitingTime] = useState<number>(0);
  const [txnData, setTxnData] = useState({ id: '', time: '' });
  const [txnStatusRes, setTxnStatusRes] = useState<any>();
  const [isTxnStatus, setIsTxnStatus] = useState<any>();
  const navigate = useNavigate();

  const handleCheckboxChange = (e: any) => {

    let isChecked = $("#" + e.target.id).parent().hasClass('checkedIcon');
    $(".checkIcon").not($("#" + e.target.id)).removeClass('checkedIcon');
    if (!isChecked) {
      setSelected((prev: any) => (e.target.value === prev ? null : e.target.value));
      $("#" + e.target.id).parent().addClass('checkedIcon');

    } else {
      setSelected(null);
      $("#" + e.target.id).parent().removeClass('checkedIcon');
    }

  };

  useEffect(() => {
    if (loading || isPageloading) {
      document.body.style.overflow = 'hidden'; // Disable body scroll
    } else {
      document.body.style.overflow = 'auto'; // Enable body scroll
    }
  }, [loading, isPageloading]);

  useEffect(() => {
    AuthService.wallet()
      .then(
        (response) => {
          if (response.status === true) {
            setWallet(response.wallet);
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response?.data?.msg);
          alert(message);
        }
      )
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    AuthService.bankAccountList()
      .then(
        (response) => {
          if (response.status === true) {
            //setMessage(response.msg);
            setBanksList(response.banks)
          } else {
            if (response.code === 401) {
              localStorage.removeItem("sg_token");
              navigate('/', { state: { message: response?.msg } });
            }
            setMessage(response.msg);
          }
        }
      ).catch((error) => {
        const message =
          (error.response &&
            error.response?.data?.msg);
        setMessage(message);
        if (error.response?.data?.code === 401) {
          localStorage.removeItem("sg_token");
          navigate('/', { state: { message: message } });
        }
      }).finally(() => {
        setIsPageLoading(false);
      });

    // eslint-disable-next-line
  }, []);


  const handleInputChange = (e: any) => {
    if (!isNaN(Number(e.target.value))) {
      setAmount(e.target.value);
    }
  };

  const handleClick = () => {
    setErrors('');
    setLoading(true);
    setIsWaiting(true);
    setMessage("");
    if (Number(amount) < 500) {
      setErrors('Minimum withdrawal amount INR 500');
      setLoading(false)
      return false
    }
    if (Number(amount) > Number(wallet)) {
      setErrors('Insufficient balance');
      setLoading(false)
      return false
    }

    if (!selected) {
      setErrors('Please select bank account');
      setLoading(false)
      return false
    }

    AuthService.withdrawalMoney(banksList[selected], amount).then(
      (response) => {
        if (response.status === true) {
          setMessage(response.msg);
          setIsTxnStatus(true);
          setValues(response.id, response.time);
          setMaxWaitingTime(response.time);
        } else {
          setMessage(response.msg);
          if (response.code === 401) {
            localStorage.removeItem("sg_token");
            navigate('/', { state: { message: response?.msg } });
          }

        }
      }
    ).catch((error) => {
      const message =
        (error.response &&
          error.response?.data?.msg);
      setMessage(message);
      if (error.response?.data?.code === 401) {
        localStorage.removeItem("sg_token");
        navigate('/', { state: { message: message } });
      }
    }).finally(() => {
      setLoading(false);
    });

  };

  function setValues(id = '', time = '') {
    setTxnData({
      ...txnData,
      [`id`]: id,
      [`time`]: time
    });
  }

  useEffect(() => {
    if (txnData.id) {
      withdrawalMoneyStatus();
    }
    // eslint-disable-next-line
  }, [txnData]);

  async function withdrawalMoneyStatus() {
    await AuthService.withdrawalMoneyStatus(txnData.id).then(
      (response) => {
        if (response.status === false) {
          setMessage(response.msg);
          setIsWaiting(false);
        }
        setWaitingTime(response.mins);
        setTxnStatusRes(response);
      }).catch((error: any) => {
        const message =
          (error.response &&
            error.response?.data?.msg);
        setMessage(message);
      });
  }


  useEffect(() => {
    if (txnData.time !== '') {
      if (waitingTime <= Number(txnData.time) && txnStatusRes.reqStatus === 0) {
        const timeoutId = setTimeout(() => {
          withdrawalMoneyStatus();
        }, 20000);

        return () => clearTimeout(timeoutId);
      } else {
        setIsWaiting(false);
      }
    }
    // eslint-disable-next-line
  }, [waitingTime]);

  function maskify(cc: any) {
    return cc.slice(0, -4).replace(/./g, '*') + cc.slice(-4);
  }

  return (
    <>
      {isPageloading && (<Loader />)}
      <section className="withdrawalSec">
        <div className="container">
          {!isTxnStatus &&
            <>
              <div className="row">
                {/* <div className="col-12">
                <h1 className="headHeding text-capitalize">Result</h1>
                </div> */}
                <div className="col-12">
                  <div className="amountSection">
                    <p>Enter Amount </p>
                    {/* <h1>₹500.00</h1> */}
                    <div className="withdrawMoney">
                      {/* <h6>₹</h6> */}
                      <input type="number" name="amount" placeholder="₹0" maxLength={10} value={amount} onChange={(e) => handleInputChange(e)} onKeyDown={(e) => Helpers.keyDownFunction(e)} autoComplete="off" />
                    </div>
                  </div>
                  <div className="availableBalance">
                    <h1>Available Balance <span>₹{wallet}</span></h1>
                  </div>
                </div>
              </div>
              <div className="row addBankSection">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <h6 className="WithdrawTital">Withdraw Money</h6>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end">
                  <Link to="/add-bank" className="addBank">
                    <img src="./addMoneyIcon.png" alt="" className="img-fluid" />
                    <p>Add Bank</p>
                  </Link>
                </div>
              </div>
              <div className="row">
                {banksList.length > 0 && banksList.map((bank: any, index) => (
                  <React.Fragment key={index}>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="bankAccountList">
                        <div className="bankIcon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M8.55717 0.0909536C8.83842 -0.0303179 9.16185 -0.0303179 9.4431 0.0909536L17.3181 3.46606C17.8103 3.67735 18.0845 4.20469 17.9755 4.725C17.8701 5.24883 17.4095 5.59336 16.8435 5.625V5.90625C16.8435 6.37383 16.499 6.75 15.9997 6.75H1.93725C1.50272 6.75 1.0935 6.37383 1.0935 5.90625V5.625C0.591114 5.59336 0.130497 5.24883 0.0230876 4.725C-0.0843076 4.20469 0.191001 3.67735 0.681817 3.46606L8.55717 0.0909536ZM9.00014 4.5C9.6224 4.5 10.1251 3.99727 10.1251 3.375C10.1251 2.75379 9.6224 2.25 9.00014 2.25C8.37787 2.25 7.87514 2.75379 7.87514 3.375C7.87514 3.99727 8.37787 4.5 9.00014 4.5ZM4.4685 14.625H5.87475V7.875H8.12475V14.625H9.84389V7.875H12.0939V14.625H13.5001V7.875H15.7501V14.7762C15.7712 14.7867 15.7923 14.7691 15.8134 14.8148L17.5009 15.9398C17.9122 16.2141 18.0951 16.7273 17.9509 17.202C17.8068 17.6766 17.3708 18 16.8751 18H1.0935C0.629434 18 0.19202 17.6766 0.048372 17.202C-0.0953116 16.7273 0.0885556 16.2141 0.501114 15.9398L2.18861 14.8148C2.20865 14.7691 2.22939 14.7867 2.2185 14.7762V7.875H4.4685V14.625Z" fill="url(#paint0_linear_1149_1204)" />
                            <defs>
                              <linearGradient id="paint0_linear_1149_1204" x1="8.99984" y1="0" x2="8.99984" y2="18" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#A200DB" />
                                <stop offset="1" stopColor="#8327F7" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <div className="bandDetal">
                          <p className="accountHolder">{bank?.bank_name}</p>
                          <p className="accountNumber">{maskify(bank?.account_no)}</p>
                        </div>
                        <label className="checkIcon">
                          <input className="bankCheck" type="checkbox" id={'vehicle' + index} name="vehicle[]" value={index} onChange={(e) => handleCheckboxChange(e)} />
                        </label>
                      </div>
                    </div>
                  </React.Fragment>
                ))
                }
                {banksList.length <= 0 ? (
                  <h6 className="text-danger">{message}</h6>
                )
                  :
                  (
                    <>
                      {errors &&
                        <div className='col-12 text-center'>
                          <span className='text-danger loginErrors'>
                            {errors}
                          </span>
                        </div>
                      }
                      {message && (
                        <div className='d-block text-danger mb-3 text-center loginErrors' role="alert">
                          {message}
                        </div>
                      )}
                      <div className="col-12">
                        <div className="confirmWithdraw">
                          <button type="button" className="btn btn-primary login-btn" disabled={loading} onClick={handleClick}>
                            {loading && (
                              <span className="loader-spin"></span>
                            )}
                            <span>Confirm Withdraw</span>
                          </button>
                        </div>
                      </div>
                    </>
                  )
                }

              </div>
            </>
          }
          <div className="row">
            {isWaiting && isTxnStatus ?
              (
                <>                  
                  <div className="verify_Payment">
                    <div className="innerDivLoder">
                      <div className="sk-circle">
                        <div className="sk-circle1 sk-child"></div>
                        <div className="sk-circle2 sk-child"></div>
                        <div className="sk-circle3 sk-child"></div>
                        <div className="sk-circle4 sk-child"></div>
                        <div className="sk-circle5 sk-child"></div>
                        <div className="sk-circle6 sk-child"></div>
                        <div className="sk-circle7 sk-child"></div>
                        <div className="sk-circle8 sk-child"></div>
                        <div className="sk-circle9 sk-child"></div>
                        <div className="sk-circle10 sk-child"></div>
                        <div className="sk-circle11 sk-child"></div>
                        <div className="sk-circle12 sk-child"></div>
                      </div>
                      <h1>Processing!</h1>
                      <h3>Payment initiated, This might take few seconds. So, Please wait for moment!</h3>
                      {/* <p>We're happy to inform you that your payment is now in processing. It may take a little while to complete</p> */}
                    </div>
                  </div>
                </>
              ) :
              (
                <>
                  {txnStatusRes?.reqStatus === 0 && (waitingTime > maxWaitingTime) && <Pending {...props} txnStatusRes={txnStatusRes} />}
                  {txnStatusRes?.reqStatus === 1 && <Success {...props} txnStatusRes={txnStatusRes} />}
                  {txnStatusRes?.reqStatus === 2 && <Failed {...props} txnStatusRes={txnStatusRes} />}
                  {txnStatusRes?.reqStatus === 3 && <Processing {...props} txnStatusRes={txnStatusRes} />}
                </>
              )
            }
          </div>
        </div>
      </section>
    </>
  )
}
export default Withdrawal;