import './App.css';
import './assets/css/global.css'
import './assets/css/responsive.css'
import './assets/css/passbook.css'
import './assets/css/wallet.css'
import { AppRouter } from './routes/AppRouter';
import CacheBuster from 'react-cache-buster';
import packageFile from '../package.json';
import Loading from './components/loader';


function App() {
  const isProduction = process.env.NODE_ENV === 'production';
  const { version } = packageFile;
  return (
    <>
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >

        <AppRouter />

      </CacheBuster>

    </>

  );
}

export default App;
