import React from 'react';
import Logo from '../assets/images/softLoder.png';
import Number9 from '../assets/images/soft9.png';
import Number1 from '../assets/images/soft1.png';

function Loader() {
    return (
        <div className='loaderContainer mainLoder'>

            <div className="ml-loader">
                <img src={Logo} alt="" className='img-fluid' style={{ width: 60 }} />
                <div className="d-flex justify-content-center mainLoder mt-1">
                    <div className="pulse-container">
                        <div className=" pulse-bubble-1">
                            <img src={Number9} alt="" className='img-fluid ' style={{ width: 30, height: 30 }} />
                        </div>
                        <div className="pulse-bubble-2">
                            <img src={Number1} alt="" className='img-fluid ' style={{ width: 30, height: 30 }} />
                        </div>
                        <div className=" pulse-bubble-3">
                            <img src={Number1} alt="" className='img-fluid' style={{ width: 30, height: 30 }} />
                        </div>
                    </div>
                </div>
                {/* <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div> */}
                <p className='lodingTital'>Please wait 
                    <span className="loader__dot ps-1">.</span>
                    <span className="loader__dot">.</span>
                    <span className="loader__dot">.</span>
                </p>
            </div>

        </div>
    )
}
export default Loader;