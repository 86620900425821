import React, { useEffect, useState } from 'react';
import AuthService from "../services/auth.service";
import { useNavigate } from 'react-router-dom';
import Loader from '../components/loader';

interface User {
    "id": String,
    "name": String,
    "imageUrl": String
}

function Referral() {
    const [isPageloading, setIsPageLoading] = useState(true);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const [usersData, setUserData] = useState<User | any>([]);

    useEffect(() => {
        AuthService.referralList()
            .then(
                (response) => {

                    if (response.status === true) {
                        //setMessage(response.msg);
                        setUserData(response.refer_list);
                    } else {
                        if (response.code === 401) {
                            localStorage.removeItem("sg_token");
                            navigate('/', { state: { message: response?.msg } });
                        }
                        setMessage(response.msg);
                    }
                    setIsPageLoading(false);
                }
            ).catch((error) => {
                const message =
                    (error.response &&
                        error.response?.data?.msg);
                setMessage(message);
                if (error.response?.data?.code === 401) {
                    localStorage.removeItem("sg_token");
                    navigate('/', { state: { message: message } });
                }
            }).finally(() => {
                setIsPageLoading(false);
            });

        // eslint-disable-next-line
    }, []);


    return (
        <>
            {isPageloading && (<Loader />)}
            <div className="container">
                {!message &&
                    <div className='referral_main mt-3' style={{ marginBottom: 80 }}>
                        <div className='row'>
                            {usersData && usersData.map((user: any) => (
                                <div className="col-3 px-0 mb-1">
                                    <div className="usersCard">                                        
                                        <img src="./user123.png" alt="" className='img-fluid' />
                                        <p>{user.mobileNumber}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {message &&
                    <div className="row position-relative">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="profileForm">
                                <div className="row mt-3">
                                    <div className="col-12 alert-danger-custom">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-emoji-frown" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.5 3.5 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.5 4.5 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                                        </svg>
                                        <p>{message}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default Referral;