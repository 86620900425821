import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from '../components/loader';
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import AuthService from "../services/auth.service";

function ReferEarn() {
    const [isPageloading, setIsPageLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [referCode, setReferCode] = useState("");
    const [appUrl, setAppUrl] = useState("");
    const [videoLink, setVideoLink] = useState("");
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const navigate = useNavigate();

    const myFunction = async () => {
        const inputValue = document.getElementById("myInput") as HTMLInputElement;

        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(inputValue.value);
        } else {
            inputValue.select();
            inputValue.setSelectionRange(0, 99999);
            document.execCommand("copy");
        }

        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000)
    };

    useEffect(() => {
        AuthService.referralCode()
            .then(
                (response) => {

                    if (response.status === true) {
                        setMessage(response.msg);
                        setReferCode(response.referalCode);
                        setAppUrl(response.app_url);
                        setVideoLink(response.video_link);
                    } else {
                        if (response.code === 401) {
                            localStorage.removeItem("sg_token");
                            navigate('/', { state: { message: response?.msg } });
                        }
                        setMessage(response.msg);
                    }
                    setIsPageLoading(false);
                }
            ).catch((error) => {
                const message =
                    (error.response &&
                        error.response?.data?.msg);
                setMessage(message);
                if (error.response?.data?.code === 401) {
                    localStorage.removeItem("sg_token");
                    navigate('/', { state: { message: message } });
                }
            }).finally(() => {
                setIsPageLoading(false);
            });

        // eslint-disable-next-line
    }, []);


    return (
        <>
            {isPageloading && (<Loader />)}
            <section className="profileContainer" style={{marginBottom:75}}>
                <div className="container">
                    <div className="row position-relative">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div>
                                <div className="Invite">
                                    <h1 className="InviteHeading">Invite Your Friends & Earn</h1>

                                    <p className="InviteSubheading"><span style={{color:'#40ff00',fontWeight:'600'}}>3.5% </span>Commission lifetime on every fail game</p>
                                    <p className="InviteSubheading" style={{fontSize:12}}>Commission will be update daily on 6:40 AM</p>
                                    <img src="./Invite.png" alt="" className="img-fluid" />
                                    <p className="codeHeading">Referral Code</p>
                                    {referCode &&
                                        (<>
                                            <div className="copyBox" style={{ padding: 15 }}>
                                                <button onClick={myFunction}>
                                                    <input type="text" value={referCode} id="myInput" readOnly />
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={isCopied ? 'copeyCheckref' : ''}>
                                                        <path d="M11.5 0H5.5C4.44199 0 3.50001 0.970507 3.50001 2.00001L2.922 2.01351C1.8645 2.01351 1 2.9705 1 4V14C1 15.0295 1.942 16 3.00001 16H10.5C11.558 16 12.5 15.0295 12.5 14H13C14.058 14 15 13.0295 15 12V4.01201L11.5 0ZM10.5 15H3.00001C2.47501 15 2.00002 14.5095 2.00002 14V4C2.00002 3.49051 2.42752 3.0165 2.95252 3.0165L3.50001 3.00001V12C3.50001 13.0295 4.44199 14 5.5 14H11.5C11.5 14.5095 11.025 15 10.5 15ZM14 12C14 12.5095 13.525 13 13 13H5.5C4.975 13 4.5 12.5095 4.5 12V2.00001C4.5 1.49052 4.975 1.00002 5.5 1.00002H10.5C10.492 2.15152 10.5 3.01252 10.5 3.01252C10.5 4.05152 11.435 5.00002 12.5 5.00002H14V12ZM12.5 4C11.9675 4 11.5 3.03251 11.5 2.51351V1.0145L14 4H12.5ZM11.5 7.00902H7.00001C6.72401 7.00902 6.50001 7.23252 6.50001 7.50851C6.50001 7.78451 6.72401 8.00801 7.00001 8.00801H11.5C11.776 8.00801 12 7.78449 12 7.50851C12 7.23252 11.776 7.00902 11.5 7.00902ZM11.5 9.50651H7.00001C6.72401 9.50651 6.50001 9.73001 6.50001 10.006C6.50001 10.282 6.72401 10.5055 7.00001 10.5055H11.5C11.776 10.5055 12 10.282 12 10.006C12 9.73001 11.776 9.50651 11.5 9.50651Z" fill="white" />
                                                    </svg>
                                                </button>
                                            </div>
                                            < div className="text-center">
                                                {/* <button className="Invitebtn btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Share Via Whatsapp</button> */}
                                                <a className="Invitebtn btn btn-primary" style={{ textDecoration: 'none', padding: '10px 0' }} target="_blank" href={`https://api.whatsapp.com/send?text=Hey! Please download Soft911 App  from link:- ${appUrl} with referal code:-  ${referCode}`} data-action="share/whatsapp/share" rel="nofollow noreferrer">
                                                    Share Via Whatsapp
                                                </a>
                                            </div>
                                        </>)

                                    }
                                    {message &&
                                        <div className='col-12 text-center'>
                                            <span className='text-danger'>
                                                {message}
                                            </span>
                                        </div>
                                    }
                                     <p className="codeHeading my-2" style={{color:'#A200DB'}}>👉 How to refer a friend?</p>
                                    <ol className="inviteConditionsLi">
                                        <li>Copy the referral code and share it with your friend.</li>
                                        <li>Ask your friend to register on Soft911 App using the referral code.</li>
                                        {videoLink && 
                                            <li>For more details please watch video <a href={videoLink} target="_blank" style={{color:'#A200DB'}} rel="nofollow noreferrer">Click Here</a></li>
                                        }
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body shareModal">
                                            <h1>Share</h1>
                                            <p>Your invite can also claim your gift reward</p>
                                            <ul className="shareList">
                                                <li>
                                                    <WhatsappShareButton url='http://127.0.0.1:3000/register?code=1RGNB342'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="43" viewBox="0 0 44 43" fill="none">
                                                            <path d="M37.2603 0H6.067C2.83683 0 0.218262 2.61857 0.218262 5.84874V37.042C0.218262 40.2722 2.83683 42.8908 6.067 42.8908H37.2603C40.4904 42.8908 43.109 40.2722 43.109 37.042V5.84874C43.109 2.61857 40.4904 0 37.2603 0Z" fill="#29A71A" />
                                                            <path d="M31.1193 11.9897C28.8881 9.73626 25.925 8.35428 22.7646 8.09306C19.6042 7.83185 16.4545 8.7086 13.8835 10.5652C11.3126 12.4218 9.48979 15.1359 8.74381 18.2181C7.99783 21.3003 8.37782 24.5476 9.81524 27.3744L8.40423 34.2247C8.38959 34.2929 8.38917 34.3633 8.40301 34.4317C8.41685 34.5 8.44464 34.5648 8.48465 34.6219C8.54326 34.7086 8.62692 34.7753 8.72446 34.8132C8.822 34.8511 8.92878 34.8583 9.03053 34.8339L15.7444 33.2426C18.5632 34.6436 21.7876 34.9992 24.8439 34.246C27.9002 33.4928 30.5901 31.6797 32.4351 29.1294C34.2801 26.579 35.1605 23.4568 34.9195 20.3183C34.6786 17.1798 33.3319 14.2286 31.1193 11.9897ZM29.0259 28.6976C27.4821 30.2371 25.4942 31.2533 23.3423 31.603C21.1904 31.9528 18.983 31.6185 17.0311 30.6472L16.0953 30.1842L11.9793 31.159L11.9915 31.1078L12.8444 26.9649L12.3862 26.0608C11.3889 24.1022 11.0371 21.8781 11.3812 19.7072C11.7253 17.5364 12.7476 15.5301 14.3017 13.9759C16.2545 12.0237 18.9026 10.9271 21.6638 10.9271C24.425 10.9271 27.0732 12.0237 29.0259 13.9759C29.0425 13.9949 29.0605 14.0129 29.0795 14.0295C31.0081 15.9867 32.0847 18.6269 32.0746 21.3746C32.0646 24.1223 30.9687 26.7546 29.0259 28.6976Z" fill="white" />
                                                            <path d="M28.6604 25.6589C28.1559 26.4533 27.359 27.4257 26.3574 27.667C24.6028 28.091 21.9099 27.6816 18.5591 24.5574L18.5177 24.5208C15.5714 21.789 14.8061 19.5153 14.9914 17.7119C15.0937 16.6884 15.9467 15.7623 16.6656 15.158C16.7792 15.061 16.914 14.9919 17.0591 14.9563C17.2042 14.9207 17.3557 14.9195 17.5013 14.9529C17.647 14.9863 17.7828 15.0533 17.8979 15.1486C18.013 15.2439 18.1043 15.3647 18.1643 15.5016L19.2488 17.9385C19.3192 18.0966 19.3453 18.2708 19.3243 18.4425C19.3033 18.6142 19.2359 18.777 19.1293 18.9133L18.581 19.6249C18.4634 19.7719 18.3924 19.9506 18.3772 20.1383C18.362 20.3259 18.4033 20.5138 18.4957 20.6777C18.8028 21.2163 19.5388 22.0083 20.3551 22.7418C21.2714 23.5704 22.2877 24.3283 22.931 24.5866C23.1032 24.6569 23.2924 24.6741 23.4744 24.6359C23.6564 24.5977 23.8228 24.5058 23.9521 24.3722L24.5882 23.7312C24.7109 23.6102 24.8635 23.5239 25.0305 23.4811C25.1974 23.4383 25.3728 23.4405 25.5386 23.4875L28.1145 24.2186C28.2566 24.2622 28.3868 24.3377 28.4952 24.4394C28.6037 24.541 28.6874 24.6661 28.7401 24.8051C28.7928 24.9441 28.813 25.0932 28.7992 25.2412C28.7853 25.3892 28.7379 25.5321 28.6604 25.6589Z" fill="white" />
                                                        </svg>
                                                        <h6>Whatsapp</h6>
                                                    </WhatsappShareButton>
                                                </li>
                                                <li>
                                                    <FacebookShareButton url='http://127.0.0.1:3000/register?code=1RGNB342' >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="43" viewBox="0 0 44 43" fill="none">
                                                            <g clipPath="url(#clip0_144_1115)">
                                                                <path d="M37.8002 0H6.46907C3.27714 0 0.689453 2.58758 0.689453 5.77961V37.1109C0.689453 40.303 3.27703 42.8905 6.46907 42.8905H21.9214L21.9478 27.5638H17.9659C17.7173 27.5638 17.4789 27.4653 17.3028 27.2899C17.1267 27.1145 17.0273 26.8764 17.0264 26.6279L17.0073 21.6874C17.0068 21.5637 17.0308 21.4412 17.0778 21.3268C17.1248 21.2124 17.1939 21.1084 17.2812 21.0207C17.3685 20.9331 17.4723 20.8636 17.5865 20.8162C17.7007 20.7687 17.8232 20.7443 17.9469 20.7443H21.9215V15.9706C21.9215 10.4307 25.3049 7.41419 30.2469 7.41419H34.3021C34.5513 7.41419 34.7903 7.51318 34.9665 7.68939C35.1427 7.86559 35.2417 8.10458 35.2417 8.35377V12.5196C35.2417 12.7687 35.1427 13.0076 34.9666 13.1838C34.7906 13.36 34.5517 13.459 34.3026 13.4592L31.814 13.4603C29.1264 13.4603 28.606 14.7374 28.606 16.6116V20.7444H34.5115C35.0742 20.7444 35.5108 21.2357 35.4445 21.7946L34.8589 26.735C34.8318 26.9634 34.7219 27.174 34.5499 27.3268C34.378 27.4796 34.156 27.564 33.9259 27.564H28.6323L28.606 42.8908H37.8004C40.9923 42.8908 43.5799 40.3032 43.5799 37.1112V5.77961C43.5798 2.58758 40.9922 0 37.8002 0Z" fill="#475993" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_144_1115">
                                                                    <rect width="42.8908" height="42.8908" fill="white" transform="translate(0.688965)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        <h6>Facebook</h6>
                                                    </FacebookShareButton>
                                                </li>
                                                <li>
                                                    <TelegramShareButton url='http://127.0.0.1:3000/register?code=1RGNB342'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="44" height="44" x="0" y="0" viewBox="0 0 176 176" ><g><g data-name="Layer 2"><rect width="176" height="176" fill="#1c8adb" rx="24" opacity="1" data-original="#1c8adb" ></rect><path fill="#ffffff" d="m135.94 45.5-1.82.66-98.78 35.59a3.31 3.31 0 0 0 .29 6.4l25.57 7 4.77 14 4.77 14a4.54 4.54 0 0 0 7.32 1.63l13.21-12.48 25.94 17.59c3.17 2.16 7.69.56 8.5-3l17.18-75.91c.84-3.76-3.12-6.85-6.95-5.48zm-12.61 16.85L78.7 98.83l-2.1 1.72a2.27 2.27 0 0 0-.84 1.48l-.47 3.88-1.29 10.9a.5.5 0 0 1-1 .08L69.37 106l-3.75-11.15a2.26 2.26 0 0 1 1.08-2.67l46.44-26.62 8.74-5c1.27-.74 2.57.87 1.45 1.79z" opacity="1" data-original="#ffffff"></path></g></g></svg>
                                                        <h6>Telegram</h6>
                                                    </TelegramShareButton>
                                                </li>
                                                <li>
                                                    <TwitterShareButton url='http://127.0.0.1:3000/register?code=1RGNB342'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                                                            <path d="M40.5554 0H3.81412C1.91178 0 0.369629 1.54215 0.369629 3.44449V40.1858C0.369629 42.0881 1.91178 43.6303 3.81412 43.6303H40.5554C42.4577 43.6303 43.9999 42.0881 43.9999 40.1858V3.44449C43.9999 1.54215 42.4577 0 40.5554 0Z" fill="#03A9F4" />
                                                            <path d="M36.3155 13.0514C35.2526 13.5156 34.1282 13.824 32.9772 13.9671C34.1905 13.2476 35.0975 12.1077 35.5261 10.7637C34.3905 11.4378 33.1478 11.9125 31.852 12.1673C31.0579 11.3181 30.0267 10.7275 28.8924 10.4723C27.7581 10.2171 26.5733 10.3092 25.4921 10.7365C24.4108 11.1638 23.4832 11.9065 22.8298 12.8682C22.1764 13.8299 21.8275 14.9659 21.8285 16.1285C21.8244 16.5723 21.8697 17.0151 21.9634 17.4489C19.6584 17.3357 17.4032 16.7375 15.3451 15.6933C13.287 14.6492 11.4723 13.1826 10.0196 11.3894C9.27292 12.6651 9.04158 14.1777 9.3729 15.6182C9.70422 17.0587 10.5732 18.3183 11.8022 19.1396C10.8848 19.1148 9.98695 18.8697 9.18434 18.4248V18.488C9.18693 19.8251 9.64974 21.1205 10.495 22.1566C11.3403 23.1927 12.5164 23.9061 13.8258 24.1771C13.3303 24.3073 12.8197 24.371 12.3074 24.3666C11.9385 24.3734 11.5699 24.3407 11.208 24.269C11.5831 25.4189 12.3053 26.4244 13.2753 27.1471C14.2452 27.8698 15.4152 28.2742 16.6244 28.3048C14.5753 29.9064 12.0492 30.7764 9.44842 30.7762C8.98501 30.7801 8.52186 30.7533 8.06201 30.6958C10.7139 32.4056 13.805 33.309 16.9603 33.2964C27.6239 33.2964 33.4537 24.4642 33.4537 16.8088C33.4537 16.5533 33.4537 16.3065 33.4336 16.0596C34.5689 15.2402 35.5455 14.2208 36.3155 13.0514Z" fill="white" />
                                                        </svg>
                                                        <h6>Twitter</h6>
                                                    </TwitterShareButton>
                                                </li>
                                                <li style={{ display: 'flex', justifyContent: 'center', }}>
                                                    <EmailShareButton url='http://127.0.0.1:3000/register?code=1RGNB342'>
                                                        <div className="mail">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                                <path d="M29.9999 10.8L26.6666 12.6334L23.3333 15.8V26.6667H27.9999C29.1046 26.6667 29.9999 25.7714 29.9999 24.6667V10.8Z" fill="#4CAF50" />
                                                                <path d="M2 10.8L4.40933 11.94L8.66667 15.8V26.6667H4C2.89533 26.6667 2 25.7714 2 24.6667V10.8Z" fill="#1E88E5" />
                                                                <path d="M23.3333 7.46655L16 12.9666L8.66667 7.46655L8 11.3332L8.66667 15.7999L16 21.2999L23.3333 15.7999L24 11.3332L23.3333 7.46655Z" fill="#E53935" />
                                                                <path d="M2 8.19883V10.8002L8.66667 15.8002V7.46683L6.584 5.90616C6.088 5.53416 5.48533 5.3335 4.86533 5.3335C3.28267 5.3335 2 6.61616 2 8.19883Z" fill="#C62828" />
                                                                <path d="M29.9999 8.19883V10.8002L23.3333 15.8002V7.46683L25.4159 5.90616C25.9119 5.53416 26.5146 5.3335 27.1346 5.3335C28.7173 5.3335 29.9999 6.61616 29.9999 8.19883Z" fill="#FBC02D" />
                                                            </svg>
                                                        </div>
                                                        <h6>Gmail</h6>
                                                    </EmailShareButton>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}
export default ReferEarn;