import React, { useEffect, useState } from 'react';
import AuthService from "../services/auth.service";
import { useNavigate } from 'react-router-dom';
import Loader from '../components/loader';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import $ from 'jquery';
import Header from "../components/header";
import Footer from "../components/footer";

interface Results {
    id: number;
    name: string;
    number: string;
    result_time: string;
}
function Result() {
    const [isPageloading, setIsPageLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [resultList, setResultList] = useState<Results | any>([]);
    const navigate = useNavigate();
    const maxDate = dayjs();

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {       
        if(dateString){
            getResult(dateString);
        }
    };

    useEffect(() => {
        let todayDate = maxDate.format('YYYY-MM-DD');
        $('input').attr('readonly', 'true');
        getResult(todayDate);
        // eslint-disable-next-line
    }, []);

    function getResult(date: any) {
        setIsPageLoading(true);
        AuthService.result(date)
            .then(
                (response) => {
                    if (response.status === true) {
                        setResultList(response.result);
                    } else {
                        if (response.code === 401) {
                            localStorage.removeItem("sg_token");
                            navigate('/', { state: { message: response?.msg } });
                        }
                        setMessage(response.msg);
                    }

                }
            ).catch((error) => {
                const message =
                    (error.response &&
                        error.response?.data?.msg);
                setMessage(message);
                if (error.response?.data?.code === 401) {
                    localStorage.removeItem("sg_token");
                    navigate('/', { state: { message: message } });
                }
            }).finally(() => {
                setIsPageLoading(false);
            });

    }


    return (
        <>
            <Header />
            {isPageloading && (<Loader />)}
            <section className="gameMain" style={{ marginBottom: 80 }}>
                <div className="container">
                    <div className='row'>
                        {/* <div className="col-6">
                        <h1 className="headHeding text-capitalize">Result</h1>
                        </div> */}
                        <div className="col-12 text-center">
                            <div className='resultFilter'>
                                <DatePicker onChange={onChange} maxDate={maxDate} readOnly={true} defaultValue={maxDate} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {resultList && resultList.map((data: any, index: any) => (
                           
                            <div className="col-6" key={index}>
                                <div className="row resultCard justify-content-center">
                                    <div className="col-12 px-0">
                                        <h1 className="gameNameResult">{data.name}</h1>
                                    </div>
                                    <div className="resultBorder">
                                        <div className="row px-0">
                                            <div className="col-6 border-b">
                                                <p className="resultDateHeding">Time</p>
                                                <h6 className="resultDate">{data.result_time}</h6>
                                            </div>
                                            <div className="col-6 border-b d-flex justify-content-end">
                                                <div className="text-left">
                                                    <p className="resultNumberHeding">Number</p>
                                                    <h6 className="resultNumber">{data.number}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        ))}
                        {message && (
                            <div className='d-block text-danger text-center' role="alert">
                                {message}
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default Result;